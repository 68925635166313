<template>
	<div class="animated fadeIn p-3">
		<template v-if="isAdminOrCorporate">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							ref="searchComp"
							v-model="distributor"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor', tree:'unilevel'}"
							:init-value="String(distributor)"
							return-key="id"
							class="mb-3"
							q="label" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loading"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loading"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
			<hr>
		</template>
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<!--<b-button
					:disabled="loadingDownload"
					class="float-right"
					variant="success"
					@click="downloadReport">
					<i :class="!loadingDownload ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
				</b-button>-->
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th>
							#
						</th>
						<th>
							{{ translate('from_user_id') }}
						</th>
						<th>
							{{ translate('from_username') }}
						</th>
						<th>
							{{ translate('order_id') }}
						</th>
						<th
							class="text-center pointer"
							@click="sortByField('trip_points')">
							{{ translate('trip_points') }}
							<sort
								:import-data="sortData"
								field="total_trip_points" />
						</th>
						<th>
							{{ translate('date') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span
								v-if="!isAdminOrCorporate"
								class="badge badge-primary">
								{{ item.attributes.from_id }}
							</span>
							<span
								v-else
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.from_id.toString() })">{{ item.attributes.from_id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.from_username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.order_id }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.trip_points }}
						</td>
						<td>
							{{ $moment(item.attributes.date.date).format(dateFormat) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import {
	PersonalBusinessVolume as PBV, Grids, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import Sponsored from '@/views/Network/mixins/Sponsored';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	MDY_FORMAT as mdyFormat,
	custom,
	allTime, YMDHMS_FORMAT,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Sort from '@/components/SortArrow';
import Reports from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import WindowSizes from '@/mixins/WindowSizes';
import { PAGINATION } from '@/settings/RequestReply';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'UserTripPoints',
	messages: [PBV, Grids, Countries],
	components: {
		DataFilter,
		Sort,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes, Sponsored],
	data() {
		return {
			alerts: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			dateRanges,
			calendarConfig,
			mdyFormat,
			custom,
			allTime,
			endpoint,
			rangeStart: '',
			rangeEnd: '',
			sortData: {},
			tripPointsReport: new Reports(),
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (!vm.isAdminOrCorporate && !Number(vm.$cookie.get('is_jns'))) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
	computed: {
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		loading() {
			return !!this.tripPointsReport.data.loading;
		},
		distributorId() {
			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined' && !this.isStaff) {
				distributorId = this.$user.details().id;
			}
			return distributorId;
		},
		data() {
			try {
				const { data } = this.tripPointsReport.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		pagination() {
			try {
				return this.tripPointsReport.data.response.data.meta.pagination;
			} catch (e) {
				return {};
			}
		},
	},
	mounted() {
		if (this.distributorId) {
			this.tripPointsReport.getTripPointsHistory(this.distributorId).then(() => {
				const { start, end } = this.tripPointsReport.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(mdyFormat);
				this.rangeEnd = this.$moment(end.date).format(mdyFormat);
			});
		}
		this.datesConfig();
	},
	methods: {
		datesConfig() {
			this.rangeStart = '';
			this.rangeEnd = '';
			delete this.dateRanges.all_time;
			delete this.dateRanges.custom;
			this.dateRanges.all_time = this.allTime;
			this.dateRanges.custom = this.custom;
			const { query } = this.$route;
			const dateKeys = Object.keys(query);
			if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
				this.dateRange = query.dateRange;
			} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
				this.dateRange = this.dateRanges.all_time;
				query.dateRange = this.dateRanges.all_time;
			} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
				this.dateRange = this.dateRanges.custom;
			} else if (typeof query.dateRange === 'undefined') {
				this.dateRange = this.dateRanges.all_time;
			}
			return null;
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.tripPointsReport.getTripPointsReport(options);
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
