export default {
	methods: {
		getProductConfigKey(parentProduct) {
			return Object.keys(parentProduct.attributes.configurations)[0];
		},
		getProductConfigs(parentProduct) {
			return parentProduct.attributes.configurations[this.getProductConfigKey(parentProduct)];
		},
		getConfiguredProduct(parentProduct, configValue) {
			const data = {
				sku: this.getConfiguredProductSku(parentProduct, configValue),
				thumbnail: this.getConfiguredProductThumbnail(parentProduct, configValue),
				code_name: this.getConfiguredProductCodeName(parentProduct, configValue),
			};

			data.backorder_info = parentProduct.attributes.children[data.sku].backorder_info;

			return data;
		},
		getConfiguredProductSku(parentProduct, configValue) {
			const selectedConfigs = { [this.getProductConfigKey(parentProduct)]: configValue };
			const sku = [parentProduct.attributes.sku];
			if (selectedConfigs.color) {
				const colorInfo = parentProduct.attributes.configurations.color;
				const selectedColor = Object.keys(colorInfo).find((color) => colorInfo[color].code_name === selectedConfigs.color);
				sku.push(selectedColor);
			}
			if (selectedConfigs.size) {
				sku.push(selectedConfigs.size);
			}
			if (selectedConfigs.format) {
				sku.push(selectedConfigs.format);
			}
			if (selectedConfigs.event_type) {
				sku.push(selectedConfigs.event_type);
			}
			if (selectedConfigs.duration) {
				sku.push(selectedConfigs.duration);
			}
			if (selectedConfigs.presentations) {
				sku.push(selectedConfigs.presentations);
			}
			if (selectedConfigs.flavor) {
				sku.push(selectedConfigs.flavor);
			}
			if (selectedConfigs.material_type) {
				sku.push(selectedConfigs.material_type);
			}
			return sku.join('-');
		},
		getConfiguredProductThumbnail(parentProduct, configValue) {
			const configuredProductSku = this.getConfiguredProductSku(parentProduct, configValue);
			const { children } = parentProduct.attributes;

			const [, configuredProduct] = Object.entries(children).filter(([childSku]) => childSku === configuredProductSku)[0];

			return configuredProduct.thumbnail;
		},
		getConfiguredProductCodeName(parentProduct, configValue) {
			return `${configValue}_${this.getProductConfigKey(parentProduct)}`;
		},
		getOnlyConfigurationName(productName) {
			const name = productName.split(' - ');
			if (name.length > 1) {
				name.shift();
			}
			return name.join(' - ');
		},
	},
};
