import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';

export default {
	props: {
		initialCountryCode: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			addressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			countryData: new Country(),
			stateData: new Country(),
			alert: new this.$Alert(),
			addressVerificationCountries,
		};
	},
	computed: {
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasAddressData() {
			return !!this.addressData.length;
		},
		hasNormalAddressesData() {
			return this.addressData.some((item) => !item.attributes.is_gift_address);
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
	},
	// watch: {
	// 	initialCountryCode: {
	// 		immediate: true,
	// 		handler(newVal, old) {
	// 			console.log('se cambio country code', this.initialCountryCode, old, newVal);
	// 			this.refreshAddressBook();
	// 			this.refreshStates();
	// 		},
	// 	},
	// },
	methods: {
		refreshAddressBook() {
			const addressOptions = {
				country: this.initialCountryCode,
				include_gift_addresses: true,
			};
			if (typeof this.addressType !== 'undefined') {
				addressOptions.type = this.addressType;
			}
			return this.addressBook.getAddresses(this.$user.details().id, addressOptions);
		},
		refreshStates() {
			this.stateData.getStates(this.initialCountryCode);
		},
	},
};
