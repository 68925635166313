import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';

export default {
	data() {
		return {
			addressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			countryData: new Country(),
			stateData: new Country(),
			addressVerificationCountries,
		};
	},
	computed: {
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (this.countryToShow) {
					return response.filter((item) => item.attributes.code === this.countryToShow);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data.filter((item) => item.attributes.warehouse_id === null);
			} catch (error) {
				return [];
			}
		},
		hasAddressData() {
			return !!this.addressData.length;
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
	},
	watch: {
		userId: {
			handler(userId) {
				if (userId) {
					this.refreshAddressBook(userId);
				}
			},
			immediate: true,
		},
	},
	mounted() {
		this.countryData.getCountries();
		this.stateData.getStates(this.country);
	},
	methods: {
		refreshAddressBook(userId) {
			const options = {};
			if (typeof this.addressType !== 'undefined') {
				options.type = this.addressType;
			}
			return this.addressBook.getAddresses(userId, options);
		},
	},
};
