<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<flow-info-group class="my-3">
			<default-flow-info-section :title="`${translate('order_information')}`">
				<b-row>
					<b-col
						cols="12"
						md="4">
						<b-form-group class="m-3 mt-1">
							<label>{{ translate('select_delivery_type') }}</label>
							<b-form-radio
								v-for="(item) in DELIVERY_TYPES"
								:key="item"
								v-model="deliveryType"
								:value="item"
								name="delivery_type">
								{{ translate(item) }}
							</b-form-radio>
						</b-form-group>
					</b-col>
					<b-col
						v-if="deliveryType === PICKUP_ORDER"
						cols="12"
						md="8">
						<div class="d-flex align-items-center w-100 warehouse-item-select">
							<item-select
								id="pickup_warehouse"
								:container-class="`w-100 mx-3 ${!['xs', 'sm'].includes(windowWidth) ? 'mt-3' : ''}`"
								:title="translate('warehouse')"
								:placeholder="translate('select_warehouse')"
								required
								:options="warehouses"
								:selected-item.sync="selectedWarehouse" />
						</div>
						<p
							class="mx-3 pt-0 text-muted small">
							*{{ translate('warehouse_disclaimer') }}
						</p>
					</b-col>
				</b-row>
			</default-flow-info-section>
			<default-flow-info-section :title="`${translate('user_information')}`">
				<b-row>
					<b-col
						cols="12"
						md="4">
						<b-form-group class="m-3 mt-1">
							<label>{{ translate('select_if_associate_user') }}</label>
							<b-form-radio
								v-for="(item) in ORDER_USER"
								:key="item"
								v-model="associateUser"
								:value="item"
								name="associate_user">
								{{ translate(item) }}
							</b-form-radio>
						</b-form-group>
					</b-col>
					<b-col
						v-if="associateUser === ORDER_WITH_USER"
						cols="12"
						md="8">
						<div
							class="d-flex align-items-center mx-3 mb-3"
							:class="{ 'mt-3': !['xs', 'sm'].includes(windowWidth) }">
							<search
								v-model="selectedUser"
								:label-text="translate('autocomplete_label')"
								:endpoint="endpoint"
								:empty-result-message="translate('data_not_found')"
								:default-params="{role:'distributor,customer,affiliate',status:'active,inactive'}"
								:placeholder="'autocomplete_placeholder'"
								:custom-error="userError"
								class="flex-grow-1"
								q="label"
								required
								return-key="id"
								@blur="changeUser"
								@input="handleUserChange" />
						</div>
					</b-col>
				</b-row>
			</default-flow-info-section>
			<default-flow-info-section
				v-if="deliveryType === SHIPPING_ORDER"
				:title="`${translate('addresses_information')}`">
				<shipping-address
					v-if="associateUser === ORDER_WITHOUT_USER || (associateUser === ORDER_WITH_USER && validUser)"
					:user-id="associateUser === ORDER_WITHOUT_USER ? DEFAULT_USER_ID : selectedUser"
					:is-user-associated="associateUser !== ORDER_WITHOUT_USER"
					:country-to-show="userCountry"
					@change="updateShippingAddress" />
				<div v-else>
					<h6 class="text-center my-2">
						{{ translate('specify_user') }}
					</h6>
				</div>
			</default-flow-info-section>
			<default-flow-info-section :title="`${translate('products_information')}`">
				<div class="m-3">
					<item-select
						id="select_products"
						:title="translate('select_products')"
						:placeholder="translate('select_products')"
						reset-after
						:options="options"
						:selected-item.sync="selectedProduct">
						<products-table
							:data="selectedProducts"
							:loading="productsLoading"
							:products.sync="order.products"
							@remove="handleRemove" />
					</item-select>
				</div>
			</default-flow-info-section>
			<default-flow-info-section
				v-if="false"
				:title="`${translate('shipping_and_payment_methods')}`">
				<div class="row mx-1 my-3">
					<div class="col-xs-12 col-sm-12 col-md-6">
						<item-select
							id="shipping_method"
							:title="translate('shipping_method')"
							:placeholder="translate('select_method')"
							:options="shippingMethods"
							:selected-item.sync="selectedShippingMethod">
							<div
								v-if="Object.keys(shippingMethodInfo).length && shippingMethodInfo.name.length"
								class="mt-1 mb-0">
								<img
									v-if="shippingMethodInfo.image"
									:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
									class="my-auto border"
									style="width: 45px; height: 30px;"
									alt="shipping method">
								<p class="text-muted d-inline">
									<span
										v-if="shippingMethodInfo.name"
										class="ml-1">
										{{ translate(shippingMethodInfo.name) }}
									</span>
									<small
										v-if="deliveryDays.length"
										class="font-italic">
										<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
									</small>
								</p>
								<br>
								<small
									v-if="shippingNotice.length"
									class="font-italic"
									v-html="translate(shippingNotice)" />
							</div>
						</item-select>
					</div>
					<div
						v-if="['xs', 'sm'].includes(windowWidth)"
						class="col-xs-12 col-sm-12">
						<hr>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-6">
						<item-select
							id="payment_method"
							:title="translate('payment_method')"
							:placeholder="translate('select_method')"
							:options="paymentMethods"
							:selected-item.sync="selectedPaymentMethod">
							<h5 v-if="objectHasData(selectedPaymentMethod)">
								{{ selectedPaymentMethod.text }}
							</h5>
						</item-select>
					</div>
				</div>
			</default-flow-info-section>
			<default-flow-info-section :title="`${translate('other_options')}`">
				<div class="row mx-1 my-3">
					<div class="col-xs-12 col-sm-12 col-md-6">
						<item-select
							id="order_type"
							:title="translate('order_type')"
							:placeholder="translate('select_order_type')"
							:options="orderTypes"
							select-label=""
							deselect-label=""
							selected-label=""
							:selected-item.sync="selectedOrderType" />
					</div>
				</div>
			</default-flow-info-section>
			<default-flow-info-section
				v-if="associateUser === ORDER_WITH_USER"
				:title="`${translate('notify_user')}`">
				<div class="d-flex justify-content-between align-items-center m-3">
					<p
						class="m-0 text-wrap"
						style="width: 80%">
						{{ translate('notify_user_description') }}
					</p>
					<switch-toggle
						id="send_email"
						v-model="sendEmail"
						name="send_email"
						variant="success"
						no-margin
						remove-margin
						pill />
				</div>
			</default-flow-info-section>
		</flow-info-group>
		<div class="d-flex justify-content-end align-items-center my-3">
			<b-button
				class="mr-2"
				variant="secondary"
				@click="triggerRedirect">
				{{ translate('go_back') }}
			</b-button>
			<b-button
				:disabled="!hasData"
				variant="primary"
				@click="placeOrder">
				{{ translate('place_manual_order') }}
			</b-button>
		</div>
	</div>
</template>
<script>
import { SEARCH_USERS } from '@/config/endpoint';
import Products from '@/util/Products';
import {
	ManualOrder as manualOrderMessages,
	PaymentMethods,
	Products as productMessages,
	PurchaseShipping,
	Validations,
} from '@/translations';
import DefaultFlowInfoSection from '@/components/DefaultFlowInfoSection';
import ShippingAddress from '@/views/ManualOrder/ShippingAddress/ShippingAddress.vue';
import ProductsTable from '@/views/ManualOrder/ProductsTable';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import SwitchToggle from '@/components/Switch';
import ItemSelect from '@/components/ItemSelect';
import Order from '@/util/Order';
import User from '@/util/User';
import { SHIPPING_METHOD_CODES as shippingMethodCodes } from '@/settings/Shipping';
import {
	AVAILABLE_PAYMENT_METHODS as availablePaymentMethods,
	AVAILABLE_SHIPPING_METHODS as availableShippingMethods,
	BLACKLIST_NON_SELECTABLE as blacklistNonSelectable,
	DELIVERY_TYPES, PICKUP_ORDER, SHIPPING_ORDER,
	ORDER_USER, ORDER_WITH_USER, ORDER_WITHOUT_USER,
	DEFAULT_USER_ID, ORDER_TYPES,
} from '@/settings/ManualOrder';
import WindowSizes from '@/mixins/WindowSizes';
import Inventory from '@/util/Inventory';
import Addresses from '@/views/ManualOrder/ShippingAddress/Addresses';

export default {
	name: 'ManualOrder',
	components: {
		DefaultFlowInfoSection, ItemSelect, ProductsTable, FlowInfoGroup, SwitchToggle, ShippingAddress,
	},
	mixins: [WindowSizes, Addresses],
	messages: [productMessages, manualOrderMessages, Validations, PurchaseShipping, PaymentMethods],
	props: {
		goBack: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			endpoint: SEARCH_USERS.endpoint,
			inventoryWarehouses: new Inventory(),
			inventoryProducts: new Inventory(),
			products: new Products(),
			orders: new Order(),
			selectedUser: '',
			userError: false,
			selectedProduct: null,
			selectedProducts: [],
			allProducts: [],
			options: [],
			shippingMethodCodes,
			selectedShippingMethod: null,
			selectedPaymentMethod: null,
			availableShippingMethods,
			availablePaymentMethods,
			blacklistNonSelectable,
			sendEmail: false,
			order: {
				password: '',
				user_id: '',
				shipping: {
					shipping_address:
						{
							address_id: 0,
						},
				},
				payment:
					{
						billing: {
							address_id: 0,
						},
						payment_method: {
							name: '',
						},
					},
				products: {},
			},
			DELIVERY_TYPES,
			SHIPPING_ORDER,
			PICKUP_ORDER,
			ORDER_USER,
			ORDER_WITHOUT_USER,
			ORDER_WITH_USER,
			DEFAULT_USER_ID,
			deliveryType: SHIPPING_ORDER,
			associateUser: ORDER_WITHOUT_USER,
			warehouses: [],
			selectedWarehouse: null,
			validUser: false,
			userCountry: '',
			defaultUserAddresses: [],
			ORDER_TYPES,
			selectedOrderType: null,
		};
	},
	computed: {
		productsLoading() {
			return this.products.data.loading || this.inventoryProducts.data.loading;
		},
		hasData() {
			if (this.deliveryType === this.SHIPPING_ORDER) {
				return this.order.shipping.shipping_address.address_id > 0
					&& this.objectHasData(this.order.products)
					// && this.objectHasData(this.selectedPaymentMethod)
					// && this.objectHasData(this.selectedShippingMethod)
					&& this.objectHasData(this.selectedOrderType);
			}

			return this.selectedWarehouse
				&& (this.validUser || this.associateUser === this.ORDER_WITHOUT_USER)
				&& this.objectHasData(this.order.products)
				// && this.objectHasData(this.selectedPaymentMethod)
				// && this.objectHasData(this.selectedShippingMethod)
				&& this.objectHasData(this.selectedOrderType);
		},
		shippingMethodInfo() {
			try {
				if (!this.objectHasData(this.selectedShippingMethod)) {
					return {};
				}
				const shippingMethod = { ...this.shippingMethodCodes[this.selectedShippingMethod.value] };
				shippingMethod.name = this.selectedShippingMethod.value;
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		shippingMethods() {
			return this.availableShippingMethods.map((sm) => ({ value: sm, text: this.translate(sm) }));
		},
		paymentMethods() {
			return this.availablePaymentMethods.map((pm) => ({ value: pm, text: this.translate(pm) }));
		},
		orderTypes() {
			return this.ORDER_TYPES.map((type) => ({ value: type, text: this.translate(type) }));
		},
	},
	watch: {
		selectedUser() {
			this.userError = false;
		},
		selectedProduct(selected) {
			if (selected === null) return;
			this.selectedProducts.push(selected);
			this.options = this.allProducts.filter((product) => !this.selectedProducts.map((msProduct) => msProduct.id)
				.includes(product.id));
		},
		language() {
			this.options.forEach((item) => {
				item.text = item.attributes.name;
			});
		},
		selectedWarehouse(selected) {
			this.allProducts = [];
			this.options = [];
			this.selectedProducts = [];
			if (selected) {
				this.getWarehouseProducts(selected.id);
			}
			if (this.deliveryType === this.PICKUP_ORDER) {
				this.setWarehouseAddress();
			} else {
				this.updateShippingAddress(0);
			}
		},
		deliveryType(deliveryType) {
			this.selectedProducts = [];
			if (deliveryType === this.SHIPPING_ORDER) {
				this.getAllProducts();
			} else {
				this.allProducts = [];
				this.options = [];
				this.refreshAddressBook(this.DEFAULT_USER_ID).then((response) => {
					this.defaultUserAddresses = response;
				});
			}
			if (this.deliveryType === this.PICKUP_ORDER) {
				this.setWarehouseAddress();
			} else {
				this.updateShippingAddress(0);
			}
		},
		associateUser(associate) {
			if (associate === this.ORDER_WITHOUT_USER) {
				this.userCountry = '';
			}
			if (this.deliveryType === this.PICKUP_ORDER) {
				this.setWarehouseAddress();
			} else {
				this.updateShippingAddress(0);
			}
			this.selectedUser = '';
			this.validUser = false;
		},
	},
	mounted() {
		this.getWarehouses();
		this.getAllProducts();
	},
	methods: {
		setWarehouseAddress() {
			const warehouseId = this.selectedWarehouse?.id ?? 0;
			const warehouseAddress = this.defaultUserAddresses.find((address) => address.attributes.warehouse_id === Number(warehouseId))?.id ?? 0;
			this.updateShippingAddress(warehouseAddress);
		},
		changeUser() {
			User.userExists(this.selectedUser).then((response) => {
				const { user } = response.response;
				if (['distributor', 'pre_distributor', 'customer', 'affiliate'].includes(user.type)) {
					this.validUser = true;
					this.userCountry = user.country;
				} else {
					this.validUser = false;
					this.userError = this.translate('invalid_user');
				}
			}).catch((error) => {
				this.validUser = false;
				let message = '';
				Object.keys(error.errors).forEach((key) => {
					error.errors[key].forEach(() => {
						message += `${error.errors[key]}  `;
					});
				});
				this.userError = message;
			});
			return null;
		},
		getAllProducts() {
			this.products.getAllProducts({ all_countries: 1 }).then((response) => {
				this.allProducts = response
					.filter((item) => !this.blacklistNonSelectable.find((sku) => sku === item.attributes.sku))
					.map((item) => ({
						...item,
						value: item.attributes.sku,
						text: item.attributes.name,
					}));
				this.options = [...this.allProducts];
			});
		},
		handleRemove(removed) {
			this.selectedProducts.splice(this.selectedProducts.findIndex((product) => product.id === removed.id), 1);
			this.options = this.allProducts.filter((product) => removed.id === product.id || !this.selectedProducts.map((msProduct) => msProduct.id)
				.includes(product.id));
		},
		placeOrder() {
			const trans = {
				title: this.translate('create_order'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				this.order.password = password.value;
				this.order.user_id = this.associateUser === ORDER_WITH_USER ? this.numericOrDefault(this.selectedUser) : this.DEFAULT_USER_ID;
				this.order.payment.payment_method.name = 'free'; // this.selectedPaymentMethod.value;
				this.order.delivery_type = this.deliveryType;
				this.order.order_type = this.selectedOrderType.value;
				if (this.deliveryType === this.PICKUP_ORDER) {
					this.order.warehouse_id = this.selectedWarehouse.id;
				}
				if (this.sendEmail) {
					this.order.send_emails = true;
				}
				const loadingOptions = {
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
				};
				this.alert.loading(this.translate('processing_request'), this.translate('please_wait'), loadingOptions);
				this.orders.manualOrder(this.order).then(() => {
					this.alert.close();
					this.alert.toast('success', this.translate('manual_order_created'));
					this.$router.replace({ name: 'ManualOrdersLogs' });
				}).catch(() => {
					this.alert.close();
					let errorName = 'default_error_message';
					if (typeof this.orders.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
					} else if (typeof this.orders.errors.errors.user_id !== 'undefined') {
						errorName = 'invalid_user';
					} else if (typeof this.orders.errors.errors['payment.payment_method.name'] !== 'undefined') {
						errorName = 'invalid_payment_method';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		numericOrDefault(value) {
			const numericValue = Number(value);
			return Number.isNaN(numericValue) ? 0 : numericValue;
		},
		triggerRedirect() {
			if (this.goBack) {
				this.$router.go(-1);
			} else {
				this.$router.replace({ name: 'MainOrders' });
			}
		},
		handleUserChange(user) {
			this.validUser = false;
			if (this.deliveryType === this.PICKUP_ORDER) {
				this.setWarehouseAddress();
			} else {
				this.updateShippingAddress(0);
			}
			this.selectedUser = user;
		},
		objectHasData(object) {
			return Object.keys(object || {}).length > 0;
		},
		getWarehouses() {
			this.inventoryWarehouses.getWarehouses({ with_mapped_address: 1 }).then((response) => {
				this.warehouses = response
					.map((item) => ({
						...item,
						value: item.id,
						text: item.attributes.name,
					}));
			});
		},
		getWarehouseProducts(warehouseId) {
			// Status 1: In Stock
			this.inventoryProducts.getProducts({ warehouse_id: warehouseId, status: 1 }).then((response) => {
				this.allProducts = response
					.map((item) => ({
						...item,
						value: item.attributes.sku,
						text: this.translate(item.attributes.code_name),
					}));
				this.options = [...this.allProducts];
			});
		},
		updateShippingAddress(addressId) {
			const address = this.numericOrDefault(addressId);
			this.order.shipping.shipping_address.address_id = address;
			this.order.payment.billing.address_id = address;
		},
	},
};
</script>
<style scoped>
.warehouse-item-select::v-deep .form-group {
	margin-bottom: 0.25rem;
}
</style>
