<template>
	<div class="row">
		<div
			:class="stackedLayout ? 'col-12' : 'col-3'"
			class="d-flex">
			<div class="my-auto mx-auto">
				<img
					:src="thumbnail"
					class="img-fluid float-right">
			</div>
		</div>
		<div
			:class="stackedLayout ? 'col-12' : 'col-5'"
			class="d-flex p-0">
			<div
				:class="stackedLayout ? 'px-2 mb-2 mx-auto text-center' : 'my-auto'">
				<span class="font-weight-bolder">
					{{ translate(codeName) }}
					<template v-if="backorderInfo && backorderInfo.will_backorder">
						<i
							v-b-tooltip
							:title="translate('will_be_backordered')"
							class="fas fa-exclamation-triangle text-warning text-small" />
					</template>
				</span>
				<br>
				<div v-if="showBvs">
					{{ translate('bvs', { qty: bvs }) }}
				</div>
			</div>
		</div>
		<template v-if="stackedLayout">
			<div class="col-12 d-flex text-center">
				<quantity-select
					:init-qty="initQty"
					:select-limit="10"
					:max="99"
					include-zero
					container-class="align-self-center w-50 mx-auto px-4 mb-2"
					@change="$emit('change', $event)" />
			</div>
			<div class="col-12 d-flex">
				<div class="mx-auto mb-2 text-right">
					<span class="font-weight-bolder h5">{{ displayPrice }}</span><br>
					<p
						v-if="displayExchange"
						class="exchange-text small">
						{{ displayExchangePrice }}*
					</p>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="col-4">
				<div class="row align-items-center h-100">
					<div class="col d-flex justify-content-end mr-3">
						<div>
							<div class="mx-auto text-right mb-2">
								<span class="font-weight-bolder h5">{{ displayPrice }}</span><br>
								<p
									v-if="displayExchange"
									class="exchange-text small">
									{{ exchangePrice }}*
								</p>
							</div>
							<quantity-select
								:init-qty="initQty"
								:select-limit="10"
								:max="99"
								include-zero
								container-class="text-right w-100"
								sub-container-class="justify-content-end w-100"
								@change="$emit('change', $event)" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { PRODUCT_OVERRIDE_PRICE } from '@/settings/Products';
import { Products, Purchase } from '@/translations';

export default {
	name: 'SimpleProduct',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	props: {
		codeName: {
			type: String,
			required: true,
		},
		sku: {
			type: String,
			required: true,
		},
		thumbnail: {
			type: String,
			required: true,
		},
		showBvs: {
			type: Boolean,
			default: true,
		},
		bvs: {
			type: Number,
			required: true,
		},
		initQty: {
			type: Number,
			required: true,
		},
		price: {
			type: String,
			required: true,
		},
		exchange: {
			type: Boolean,
			default: false,
		},
		exchangePrice: {
			type: String,
			default: '',
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		backorderInfo: {
			type: Object,
			default: null,
		},
	},
	computed: {
		displayPrice() {
			return this.getDisplayPrice(this.price, this.sku);
		},
		displayExchange() {
			return !!PRODUCT_OVERRIDE_PRICE[this.sku]?.exchangePrice || this.exchange;
		},
		displayExchangePrice() {
			return this.getDisplayPrice(this.exchangePrice, this.sku, 'exchangePrice');
		},
	},
	methods: {
		getDisplayPrice(price, sku, priceKey = 'price') {
			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			return price;
		},
	},
};
</script>
