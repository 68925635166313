import {
	GET_REPORT_COMPANIES,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Company {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getReportCompanies(options = {}) {
		const { method, endpoint } = GET_REPORT_COMPANIES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Company;
