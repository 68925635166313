<template>
	<div>
		<b-row
			v-if="!profileLoading && profileHasData">
			<b-col>
				<b-card-body style="padding: unset;">
					<span
						class="btn btn-link float-right"
						@click="collapseAll">
						<i
							:class="allExpanded ? 'fa-angle-up' : 'fa-angle-down'"
							class="fas rotate-icon mr-1" />
						{{ allExpanded ? translate('collapse_all') : translate('expand_all') }}</span>
				</b-card-body>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.personal_info
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('profile_information') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="personal_info"
						v-model="personalExpanded"
						accordion="personal-info"
						role="tabpanel">
						<b-card-body>
							<template
								v-if="!profileLoading && profileHasData">
								<b-button
									v-if="$can('personal_information', 'suspend') && !['terminated'].includes(profileData['status']) && !noEditableStatuses.includes(profileData['status']) && canEditFields(profileData.is_locked)"
									class="float-right mb-2"
									variant="danger"
									@click="changeToSpecialStatus('terminated')">
									{{ translate('terminate') }}
								</b-button>
								<b-button
									v-if="$can('personal_information', 'suspend') && !['resigned'].includes(profileData['status']) && !noEditableStatuses.includes(profileData['status']) && canEditFields(profileData.is_locked)"
									class="float-right mb-2 mr-2"
									variant="warning"
									@click="changeToSpecialStatus('resigned')">
									{{ translate('resign') }}
								</b-button>
								<b-button
									v-if="$can('personal_information', 'suspend') && !['suspended'].includes(profileData['status']) && !noEditableStatuses.includes(profileData['status']) && canEditFields(profileData.is_locked)"
									class="float-right mb-2 mr-2"
									variant="secondary"
									@click="changeToSpecialStatus('suspended')">
									{{ translate('suspend') }}
								</b-button>
								<div class="table-responsive">
									<table class="table">
										<tbody>
											<editable-field
												v-for="item in Object.keys(profileData)"
												v-if="showField(item, profileData) && item !== 'username'"
												:key="item"
												:key-value="item"
												:field-information="checkFieldInformation(item, profileData, 'personal_information')"
												:field-name="fieldName(item)"
												:first-item="fieldName(Object.keys(profileData)[0])"
												:errors="personalInfoErrors[item]"
												:loading="profileInfoLoading"
												:show-duplicated-info="admin.includes($user.details().type)"
												:options="getOptions(item)"
												@clearField="clearPersonalError(item)"
												@editField="editProfile($event, item)" />
										</tbody>
									</table>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="profileLoading"
								:has-data="profileHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.account_info
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('account_information') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="account_info"
						v-model="accountExpanded"
						accordion="account-info"
						role="tabpanel">
						<b-card-body>
							<template
								v-if="!accountInformationLoading && accountInformationHasData">
								<b-button
									v-if="$can('account_information', 'update') && accountInformationData.enable_autoship"
									class="float-right mb-2"
									variant="danger"
									@click="editAccountInfo(false, 'enable_autoship')">
									{{ translate('suspend_autoship') }}
								</b-button>
								<div class="table-responsive">
									<table class="table">
										<tbody>
											<editable-field
												v-for="item in Object.keys(accountInformationData)"
												v-if="showField(item, accountInformationData)"
												:key="item"
												:key-value="item"
												:field-information="checkFieldInformation(item, accountInformationData, 'account_information')"
												:field-name="fieldName(item)"
												:first-item="fieldName(Object.keys(accountInformationData)[0])"
												:errors="accountErrors[item]"
												:loading="accountInformationLoading"
												:options="getOptions(item)"
												@clearField="clearAccountError(item)"
												@editField="editAccountInfo($event, item)" />
										</tbody>
									</table>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="accountInformationLoading"
								:has-data="accountInformationHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			v-if="!coapplicantsLoading && coapplicantsHasData"
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.co_applicants
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('co_applicants') + ((countCoapplicants > 1) ? ` (${countCoapplicants})` : '') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="co_applicants"
						v-model="coapplicantsExpanded"
						accordion="co-applicants"
						role="tabpanel">
						<b-card-body>
							<template>
								<div class="table-responsive">
									<div
										v-for="(data, key) in coapplicantsData"
										:key="key">
										<table class="table border border-top-0">
											<tbody>
												<editable-field
													v-for="item in Object.keys(data.attributes)"
													v-if="showField(item, data.attributes)"
													:key="item"
													:key-value="item"
													:field-information="{editable: false, value: data.attributes[item], showable: data.attributes[item]}"
													:field-name="fieldName(item)"
													:loading="coapplicantsLoading" />
											</tbody>
										</table>
									</div>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="coapplicantsLoading"
								:has-data="coapplicantsHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.system_credentials
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('system_credentials') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="system_credentials"
						v-model="credentialsExpanded"
						accordion="system-credentials"
						role="tabpanel">
						<b-card-body>
							<template
								v-if="!systemCredentialsLoading && systemCredentialsHasData">
								<div class="table-responsive">
									<table class="table">
										<tbody>
											<editable-field
												:key="'payee_username'"
												:key-value="'payee_username'"
												:field-information="checkFieldInformation('username', systemCredentialsData, 'system_credentials')"
												:field-name="translate('username')"
												:first-item="Object.keys(systemCredentialsData)[0]"
												:errors="systemCredentialsErrors['username']"
												:loading="systemCredentialsLoading"
												@clearField="clearSystemCredentialsError('payee_username')"
												@editField="editSystemCredentials($event, 'payee_username')" />
											<editable-field
												:key="'new_password'"
												:key-value="'new_password'"
												:field-information="{ showable: '********', value: '', editable: $can('system_credentials', 'update') }"
												:field-name="translate('password')"
												:errors="systemCredentialsErrors['new_password']"
												:loading="systemCredentialsLoading"
												@clearField="clearSystemCredentialsError('new_password')"
												@editField="editSystemCredentials($event, 'new_password')" />
											<editable-field
												:key="'tfa_enabled'"
												:key-value="'tfa_enabled'"
												:field-information="{ showable: !!systemCredentialsData['tfa_enabled'], value: !!systemCredentialsData['tfa_enabled'], editable: $can('system_credentials', 'update') && !!systemCredentialsData['tfa_enabled'] }"
												:field-name="translate('two_factor_auth')"
												:errors="systemCredentialsErrors['tfa_enabled']"
												:loading="systemCredentialsLoading"
												@clearField="clearSystemCredentialsError('tfa_enabled')"
												@editField="editSystemCredentials($event, 'tfa_enabled')" />
										</tbody>
									</table>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="systemCredentialsLoading"
								:has-data="systemCredentialsHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.addresses_information
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('addresses_information') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="addresses_information"
						v-model="addressesExpanded"
						accordion="addresses_information"
						role="tabpanel">
						<b-card-body>
							<addresses-table
								:user-id="$route.params.distributorId"
								add-view="AddDistributorAddress" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			v-if="$can('file', 'view')"
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.personal_files
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('personal_files') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="personal_files"
						v-model="personalFilesExpanded"
						accordion="personal_files"
						role="tabpanel">
						<b-card-body>
							<files-table
								:user-id="$route.params.distributorId"
								add-view="UploadDistributorsFile" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import EditableField from '@/components/EditableField';
import DistributorsInformation from '@/util/DistributorsInformation';
import ProfileInfo from '@/util/Profile';
import Coapplicants from '@/util/CoApplicants';
import {
	Profile, DistributorsInformation as messages, Ranks, Packages, NIN, Countries,
} from '@/translations';
import { MDY_FORMAT, YMD_FORMAT } from '@/settings/Dates';
import {
	EDITABLE_PERSONAL_FIELDS, EDITABLE_ACCOUNT_FIELDS, EDITABLE_SYSTEM_CREDENTIALS_FIELDS, DATE_KEYS as dateKeys, BOOLEAN_KEYS as booleanKeys, TRANSLATED_KEYS as translatedKeys, LABEL_TO_TRANSLATE as labels,
	BINARY_PLACEMENT_OPTIONS as binaryOptions, GENDERS as genders, INVISIBLE_FIELDS as invisibleFields, PASSWORD_COMPONENT as passwordComponent, PAYEE_USERNAME_COMPONENT as payeeUsernameComponent,
	RESTRICTED_ACCOUNT_FIELDS as restrictedAccountFields,
} from '@/settings/Profile';
import { PACKAGES as packages } from '@/settings/Products';
import { RANKS as ranks } from '@/settings/Ranks';
import {
	EDITABLE_STATUSES as editableStatuses,
	NO_EDITABLE_STATUSES as noEditableStatuses,
	NO_EDITABLE_STATUSES_NORMAL_PERMISSIONS as noEditableStatusNormalPermission,
	EDITABLE_STATUSES_WITH_SUSPEND_PERMISSION as editableStatusesSuspendPermission,
} from '@/settings/Statuses';
import { MARKETING_PROVIDER as marketingProvider } from '@/settings/General';
import WindowSizes from '@/mixins/WindowSizes';
import AddressesTable from '@/views/Addresses';
import FilesTable from '@/views/PersonalFiles';
import { admin } from '@/settings/Roles';
import Country from '@/util/Country';

export default {
	name: 'DistributorsInformation',
	messages: [messages, Profile, Ranks, Packages, NIN, Countries],
	components: { AddressesTable, FilesTable, EditableField },
	mixins: [WindowSizes],
	data() {
		return {
			profile: new DistributorsInformation(),
			accountInfo: new DistributorsInformation(),
			accountInformation: new DistributorsInformation(),
			profileInfo: new ProfileInfo(),
			systemCredentials: new ProfileInfo(),
			coapplicants: new Coapplicants(),
			countries: new Country(),
			dateFormat: MDY_FORMAT,
			YMD_FORMAT,
			alert: new this.$Alert(),
			booleanKeys,
			dateKeys,
			editableFields: [...EDITABLE_PERSONAL_FIELDS, ...EDITABLE_ACCOUNT_FIELDS, ...EDITABLE_SYSTEM_CREDENTIALS_FIELDS],
			translatedKeys,
			binaryOptions,
			genders,
			invisibleFields,
			packages,
			ranks,
			marketingProvider,
			restrictedAccountFields,
			allExpanded: true,
			editableStatuses,
			noEditableStatuses,
			noEditableStatusNormalPermission,
			editableStatusesSuspendPermission,
			passwordComponent,
			personalExpanded: true,
			accountExpanded: false,
			coapplicantsExpanded: false,
			credentialsExpanded: false,
			addressesExpanded: false,
			personalFilesExpanded: false,
			admin,
		};
	},
	computed: {
		profileLoading() {
			try {
				return this.profile.data.loading;
			} catch (error) {
				return false;
			}
		},
		profileInfoLoading() {
			try {
				return this.profileInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
		profileData() {
			try {
				return this.profile.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		profileHasData() {
			const response = Object.keys(this.profileData).length;
			return !!response;
		},
		accountInformationLoading() {
			try {
				return this.accountInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
		accountInformationData() {
			try {
				return this.accountInfo.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		accountInformationHasData() {
			const response = Object.keys(this.accountInformationData).length;
			return !!response;
		},
		coapplicantsLoading() {
			try {
				return this.coapplicants.data.loading;
			} catch (error) {
				return false;
			}
		},
		coapplicantsData() {
			try {
				return this.coapplicants.data.response.data.data;
			} catch (error) {
				return {};
			}
		},
		countCoapplicants() {
			try {
				const { total } = this.coapplicants.data.response.data.meta;
				return total;
			} catch (error) {
				return 0;
			}
		},
		coapplicantsHasData() {
			const response = Object.keys(this.coapplicantsData).length;
			return !!response;
		},
		selectCountries() {
			try {
				const countriesData = this.countries.data.response.data.data;
				return countriesData.map((countryData) => countryData.attributes.code.toLowerCase());
			} catch (e) {
				return [];
			}
		},
		systemCredentialsLoading() {
			try {
				return this.systemCredentials.data.loading;
			} catch (error) {
				return false;
			}
		},
		systemCredentialsData() {
			try {
				return this.systemCredentials.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		systemCredentialsHasData() {
			const response = Object.keys(this.systemCredentialsData).length;
			return !!response;
		},
		personalInfoErrors: {
			get() {
				try {
					return this.profileInfo.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.profileInfo.data.errors.errors = newErrors;
			},
		},
		accountErrors: {
			get() {
				try {
					return this.accountInformation.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.accountInformation.data.errors.errors = newErrors;
			},
		},
		systemCredentialsErrors: {
			get() {
				try {
					return this.systemCredentials.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.systemCredentials.data.errors.errors = newErrors;
			},
		},
	},
	mounted() {
		this.accountInfo.getInformation(this.$route.params.distributorId);
		this.profile.getProfile(this.$route.params.distributorId);
		this.systemCredentials.getSystemCredentials(this.$route.params.distributorId);
		this.coapplicants.getCoApplicants(this.$route.params.distributorId);
		this.countries.getReportCountries();
	},
	methods: {
		changeToSpecialStatus(status) {
			const trans = {
				title: this.translate('suspend_account'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};

				let changeStatus = null;
				switch (status) {
				case 'terminated':
					changeStatus = this.profileInfo.terminateAccount(this.$route.params.distributorId, payload);
					break;
				case 'suspended':
					changeStatus = this.profileInfo.suspendAccount(this.$route.params.distributorId, payload);
					break;
				case 'resigned':
					changeStatus = this.profileInfo.resignAccount(this.$route.params.distributorId, payload);
					break;
				default:
					break;
				}

				changeStatus.then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.profile.getProfile(this.$route.params.distributorId);
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasPersonalInfoError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
					this.profile.getProfile(this.$route.params.distributorId);
				});
			}).catch(() => {});
		},
		hasPersonalInfoError(field) {
			if (typeof this.personalInfoErrors[field] !== 'undefined') { return true; }
			return false;
		},
		hasAccountInfoError(field) {
			if (typeof this.accountErrors[field] !== 'undefined') { return true; }
			return false;
		},
		hasSystemCredentialsErrors(field) {
			if (typeof this.systemCredentialsErrors[field] !== 'undefined') { return true; }
			return false;
		},
		canEditFields(isLocked) {
			if (isLocked) {
				return this.$can('personal_information', 'lock');
			}
			return true;
		},
		editProfile(fieldValue, id) {
			let formattedValue = fieldValue;
			if (dateKeys.includes(id)) {
				formattedValue = this.$moment(fieldValue).format(YMD_FORMAT);
			}
			const trans = {
				title: this.translate('edit_profile'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};
				payload[id] = formattedValue;
				this.profileInfo.updatePersonalInfo(this.$route.params.distributorId, payload).then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.profile.getProfile(this.$route.params.distributorId);
					this.personalExpanded = true;
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasPersonalInfoError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		editAccountInfo(fieldValue, id) {
			const trans = {
				title: this.translate('edit_account'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};
			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};
				payload[id] = fieldValue;
				if (dateKeys.includes(id) && fieldValue !== null) {
					payload[id] = this.$moment(fieldValue).format(YMD_FORMAT);
				}
				this.accountInformation.updateAccountInfo(this.$route.params.distributorId, payload).then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.accountInfo.getInformation(this.$route.params.distributorId);
					this.accountExpanded = true;
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasAccountInfoError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		editSystemCredentials(fieldValue, id) {
			const trans = {
				title: this.translate('edit_system_credentials'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};

				payload[id] = fieldValue;
				if (passwordComponent.includes(id)) {
					payload[id] = fieldValue.password;
					payload[`${id}_confirmation`] = fieldValue.passwordConfirmation;
				}

				if (payeeUsernameComponent.includes(id)) {
					payload.username = fieldValue.username;
					payload[`change_${id}`] = fieldValue.changePayeeUsername;
				}

				this.systemCredentials.updateSystemCredentials(this.$route.params.distributorId, payload).then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.systemCredentials.getSystemCredentials(this.$route.params.distributorId);
					this.credentialsExpanded = true;
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasSystemCredentialsErrors('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		checkFieldInformation(item, data, section) {
			const fieldInformation = {};
			fieldInformation.value = data[item];
			fieldInformation.showable = data[item];
			fieldInformation.editable = this.editableFields.includes(item) && this.$can(section, 'update');
			if (item === 'status') {
				// Permission to change status is the same as permission to suspend users.
				fieldInformation.editable = !noEditableStatusNormalPermission.includes(data[item]) || (this.$can(section, 'suspend') && editableStatusesSuspendPermission.includes(data[item]));
			}
			if (item === 'email') {
				fieldInformation.showable = `${data[item]} (${this.translate('marketing_email_status', { provider: marketingProvider })}: ${ this.translate(data.email_status).toLowerCase() })`;
			}
			if (item === 'password') {
				fieldInformation.value = '';
			}
			if (item === 'national_identification_number') {
				fieldInformation.value = data.national_identification_number_value;
				fieldInformation.editable = this.editableFields.includes(item) && this.$can(section, 'update_nin');
			}
			if (item === 'country') {
				fieldInformation.value = data.country.toLowerCase();
				fieldInformation.editable = this.editableFields.includes(item) && this.$can('users', 'change_country');
			}

			if (this.dateKeys.includes(item)) {
				let dateValue = this.translate('null_date_label');
				fieldInformation.editable = false;
				fieldInformation.editable = this.editableFields.includes(item) && this.$can(section, 'update');
				if (fieldInformation.value !== null) {
					dateValue = this.$moment(data[item].date).format(this.dateFormat);
					fieldInformation.value = this.$moment(data[item].date).toDate();
				}
				fieldInformation.showable = dateValue;
			} else if (this.translatedKeys.includes(item)) {
				fieldInformation.showable = '';
				if (data[item]) {
					fieldInformation.showable = this.translate(data[item].toLowerCase());
				}
			} else if (this.booleanKeys.includes(item)) {
				fieldInformation.showable = !!data[item];
				fieldInformation.value = !!data[item];
			}

			if (this.restrictedAccountFields.includes(item)) {
				fieldInformation.editable = this.editableFields.includes(item) && this.$can(section, 'update_distributor_data');
			}
			if (data.is_locked) {
				fieldInformation.editable = fieldInformation.editable && this.$can('personal_information', 'lock');
			}
			if (item === 'is_locked') {
				fieldInformation.editable = this.$can('personal_information', 'lock');
			}
			return fieldInformation;
		},
		clearPersonalError(field) {
			if (field) {
				delete this.personalInfoErrors[field];
				this.personalInfoErrors = { ...this.personalInfoErrors };
			}
		},
		clearAccountError(field) {
			if (field) {
				delete this.accountErrors[field];
				this.accountErrors = { ...this.accountErrors };
			}
		},
		clearSystemCredentialsError(field) {
			if (field) {
				delete this.systemCredentialsErrors[field];
				this.systemCredentialsErrors = { ...this.accountErrors };
			}
		},
		showField(item, data) {
			return (data[item] !== null || this.editableFields.includes(item)) && !this.invisibleFields.includes(item);
		},
		fieldName(key) {
			if (Object.keys(labels).includes(key)) {
				const newKey = labels[key];
				if (newKey === 'nin') {
					return this.translate(`nin_${this.profileData.country.toLowerCase()}`);
				}
			}
			return this.translate(key);
		},
		getOptions(item) {
			if (item === 'binary_placement_preference') return this.binaryOptions;
			if (item === 'gender') return this.genders;
			if (item === 'status') return this.editableStatuses.includes(this.profileData.status) ? this.editableStatuses : [...[this.profileData.status], ...this.editableStatuses];
			if (item === 'product') return this.packages;
			if (item === 'rank' || item === 'permanent_rank') return this.ranks;
			if (item === 'country') return this.selectCountries;
			return [];
		},
		collapseAll() {
			this.allExpanded = !this.allExpanded;
			if (this.allExpanded) {
				this.personalExpanded = true;
				this.accountExpanded = true;
				this.coapplicantsExpanded = true;
				this.credentialsExpanded = true;
				this.addressesExpanded = true;
				this.personalFilesExpanded = true;
			} else if (!this.allExpanded) {
				this.personalExpanded = false;
				this.accountExpanded = false;
				this.coapplicantsExpanded = false;
				this.credentialsExpanded = false;
				this.addressesExpanded = false;
				this.personalFilesExpanded = false;
			}
		},
	},
};
</script>

<style scoped>
.when-opened, .when-closed {
	float: right;
}

.collapsed > h5 > .when-opened,
:not(.collapsed) > h5 > .when-closed {
  display: none;
}
</style>
