const commission = 'commission';
const deposit = 'deposit';
const migrated = 'migrated';
const migratedIPayout = 'migrated_i_payout';
const migratedCommission = 'migrated_commission';
export const merchantName = process.env.VUE_APP_IPAYOUT_MERCHANT_NAME;
export const eWalletRoute = process.env.VUE_APP_IPAYOUT_EWALLET_ROUTE;
export const HIDE_WITHDRAWAL_TO_COUNTRIES = [];

export const WALLET_TYPES = {
	commission,
	deposit,
	migrated,
	migrated_i_payout: migratedIPayout,
	migrated_commission: migratedCommission,
};

export const TRANSFER_TYPES = ['restricted', 'not_restricted'];
export const RESTRICTED_CURRENCY = 'V-USD';

export const VALID_TAB_FILTERS = {
	WalletsTransactions: ['user_id', 'username', 'created_at'],
	WalletsPoints: ['user_id', 'username', 'created_at', 'from_user.id', 'from_user.username'],
	WalletsPointsSummaries: [],
	WalletsBalances: ['user_id', 'username', 'status', 'restricted_balance', 'points'],
	WalletsSettings: [],
};

export default {};
