<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<!-- Choose next autoship date (only customers and affiliates)  -->
		<b-row
			v-if="customerRoles.includes($user.details().type)"
			class="my-3">
			<b-col
				:class="switchClass"
				class="d-flex align-items-center ">
				<b>
					<p
						style="display:inline;"
						class="ml-3 mb-0">
						{{ translate('change_autoship_date') }}
					</p>
				</b>
			</b-col>
			<b-col
				md="6"
				sm="10">
				<b-input-group class="mt-3 px-2">
					<v-date-picker
						id="nextAutoshipDate"
						v-model="nextAutoshipDate"
						:input-props="{ class: 'form-control' }"
						:formats="calendarConfig"
						:style="{ width: ['xs', 'sm'].includes(windowWidth) ? '100%' : '59%' }"
						name="nextAutoshipDate"
						class="mr-1"
						show-caps
						@input="setAutoshipDateClearError('next_autoship_date')" />
					<b-button
						:style="{ width: ['xs', 'sm'].includes(windowWidth) ? '30%' : '20%' }"
						variant="secondary">
						{{ translate('cancel') }}
					</b-button>
					<b-button
						:style="{ width: ['xs', 'sm'].includes(windowWidth) ? '30%' : '18%' }"
						variant="success"
						class="ml-1"
						@click="saveDate">
						{{ translate('save') }}
					</b-button>
				</b-input-group>
				<template v-if="setAutoshipDateHasError('next_autoship_date')">
					<ul class="pl-3 mb-0">
						<li
							v-for="error in setAutoshipDateErrors['next_autoship_date']"
							:key="error"
							class="custom-invalid-feedback animated fadeIn"
							v-text="error" />
					</ul>
				</template>
			</b-col>
		</b-row>
		<!-- Change periodicity -->
		<b-row class="my-3">
			<b-col
				:class="switchClass"
				class="d-flex align-items-center">
				<b>
					<p
						style="display:inline;"
						class="ml-3 mb-0">
						{{ translate('autoship_periodicity') }}
					</p>
				</b>
			</b-col>
			<b-col
				md="6"
				sm="10">
				<div class="mx-2">
					<select
						id="autoship_periodicity"
						v-model="autoshipPeriodicity"
						name="autoship_periodicity"
						class="form-control mt-1"
						@change="saveNewConfiguration">
						<option
							v-for="configuration in availablePeriodicity"
							:key="configuration.code_name"
							:value="configuration.value">
							{{ translate(configuration.code_name) }}
						</option>
					</select>
				</div>
			</b-col>
			<b-col cols="12">
				<div class="px-3 mt-3">
					<p class="text-left mt-3 p-text">
						{{ translate(newGenConfigurationShortInfo) }}
					</p>
				</div>
			</b-col>
			<b-col
				v-if="false"
				cols="12">
				<button
					class="btn btn-primary mt-3 mr-3 float-right"
					@click="$emit('updateAutoshipPeriodicity', autoshipPeriodicity)">
					{{ translate('update_autoship_periodicity') }}
				</button>
			</b-col>
		</b-row>
		<hr>
		<!-- Use Wallet funds -->
		<b-row
			v-if="customer !== $user.details().type"
			class="my-3">
			<b-col :class="switchClass">
				<switch-toggle
					:checked="useWalletFunds"
					:disabled="!enableCurrentProducts"
					no-margin
					variant="success"
					pill
					class="ml-3"
					style="vertical-align: top"
					@change="$emit('useWalletFunds')" />
				<b><p
					style="display:inline;"
					class="ml-2 mb-0">{{ translate('use_wallet_funds') }}</p></b>
			</b-col>
			<b-col :class="textClass">
				<div class="px-3">
					<p class="text-left mt-3 p-text">
						{{ translate(`use_wallet_funds_description`) }}
					</p>
				</div>
			</b-col>
		</b-row>
		<b-row
			v-if="showForceAutoship"
			class="my-3">
			<b-col :class="switchClass">
				<switch-toggle
					:checked="forceAutoshipChecked"
					:disabled="!enableCurrentProducts"
					variant="success"
					no-margin
					pill
					class="ml-3"
					style="vertical-align: top"
					@change="$emit('forceAutoship')" />
				<b><p
					style="display:inline;"
					class="mb-0 ml-2">{{ translate('force_autoship') }}</p></b>
			</b-col>
			<b-col :class="textClass">
				<div class="px-3">
					<p class="text-left mt-3 p-text">
						{{ translate(`force_autoship_text_${forceAutoshipChecked}`) }}
					</p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Switch from '@/components/Switch';
import { customer, customerRoles } from '@/settings/Roles';
import {
	AutoshipInformation as Messages, AutoshipPeriodicity,
} from '@/translations';
import { NEW_GEN_CONFIGURATIONS } from '@/settings/Autoship';
import AutoshipInformation from '@/util/AutoshipInformation';
import {
	MDY_FORMAT, V_CALENDAR_CONFIG as calendarConfig, YMD_FORMAT, YMDHMS_FORMAT,
} from '@/settings/Dates';

export default {
	name: 'AdvancedSettings',
	messages: [Messages, AutoshipPeriodicity],
	components: {
		// AutoshipProduct,
		'switch-toggle': Switch,
	},
	mixins: [WindowSizes],
	props: {
		forceAutoshipChecked: {
			type: Boolean,
			default: false,
		},
		enableCurrentProducts: {
			type: Boolean,
			default: false,
		},
		showForceAutoship: {
			type: Boolean,
			default: false,
		},
		useWalletFunds: {
			type: Boolean,
			default: false,
		},
		// Autoship periodicity
		initAutoshipPeriodicity: {
			type: String,
			default: null,
		},
		boxes: {
			type: Number,
			default: 0,
		},
		equivalencyOfBoxes: {
			type: Object,
			default: () => ({}),
		},
		periodicityDates: {
			type: Object,
			default: () => ({}),
		},
		// Autoship date
		initAutoshipDate: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			customer,
			customerRoles,
			// Periodicity
			autoshipConfigurations: NEW_GEN_CONFIGURATIONS,
			autoshipPeriodicity: null,
			setAutoshipDate: new AutoshipInformation(),
			newAutoshipDateFormat: '',
			dateFormat: MDY_FORMAT,
			// Autoship date
			calendarConfig,
			nextAutoshipDate: '',
		};
	},
	computed: {
		setAutoshipDateLoading() {
			return this.setAutoshipDate.loading;
		},
		switchClass() {
			return {
				'col-3': !['xs', 'sm'].includes(this.windowWidth),
				'col-12': ['xs', 'sm'].includes(this.windowWidth),
			};
		},
		textClass() {
			return {
				'col-9': !['xs', 'sm'].includes(this.windowWidth),
				'col-12 mt-2': ['xs', 'sm'].includes(this.windowWidth),
			};
		},
		newGenConfigurationShortInfo() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			if (customerRoles.includes(this.$user.details().type)) {
				return this.translate(`customer_${selectedPeriodicity.code_name}_description`);
			}
			return this.translate(`${selectedPeriodicity.code_name}_description_short`);
		},
		availablePeriodicity() {
			if (customerRoles.includes(this.$user.details().type)) {
				return this.autoshipConfigurations.filter((item) => item.code_name !== 'max');
			}
			const { weeksPerBox } = this.equivalencyOfBoxes;
			return this.autoshipConfigurations
				.filter((item) => (item.weeks ?? 0) <= this.boxes * weeksPerBox);
		},
		// Autoship date
		setAutoshipDateErrors: {
			get() {
				try {
					return this.setAutoshipDate.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.setAutoshipDate.errors.errors = newErrors;
			},
		},
	},
	watch: {
		initAutoshipPeriodicity() {
			this.autoshipPeriodicity = this.initAutoshipPeriodicity;
		},
		autoshipPeriodicity() {
			this.nextDatePreview();
		},
		initAutoshipDate() {
			this.nextAutoshipDate = new Date(this.$moment(this.initAutoshipDate).format(YMDHMS_FORMAT));
		},
	},
	mounted() {
		this.autoshipPeriodicity = this.initAutoshipPeriodicity;
		this.nextAutoshipDate = new Date(this.$moment(this.initAutoshipDate).format(YMDHMS_FORMAT));
	},
	methods: {
		getPeriodicityData(periodicityValue) { // Info about selected periodicity (# weeks, code_name)
			try {
				return this.autoshipConfigurations.find((element) => element.value === periodicityValue);
			} catch (e) {
				return [];
			}
		},
		saveNewConfiguration() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			const trans = {
				title: this.translate('change_autoship_configuration'),
				text: this.translate('change_autoship_configuration_text', { autoship_frequency: this.translate(selectedPeriodicity.code_name), autoship_date: this.newAutoshipDateFormat }),
			};
			if (customerRoles.includes(this.$user.details().type) && this.autoshipPeriodicity !== 'monthly' && !this.isJns()) {
				trans.text += this.translate('possible_loyalty_loss');
			}
			const options = {
				confirmButtonText: this.translate('confirm_autoship_configuration'),
				cancelButtonText: this.translate('do_not_change_autoship_date'),
			};
			this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				this.loadingAutoshipConfiguration = true;
				this.setAutoshipDate.updateAutoshipPeriodicity(this.$user.details().id, { autoship_periodicity: this.autoshipPeriodicity }).then((response) => {
					this.alert.toast('success', response.response.message);
					this.$emit('updateAutoshipPeriodicity');
				});
			}).catch(() => {
				this.autoshipPeriodicity = this.initAutoshipPeriodicity;
			});
		},
		nextDatePreview() {
			try {
				if (customerRoles.includes(this.$user.details().type)) {
					return;
				}
				const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
				const newAutoshipDate = this.periodicityDates[selectedPeriodicity.code_name].date.date;
				this.newAutoshipDateFormat = this.$moment(newAutoshipDate).format(this.dateFormat);
			} catch (e) {
				this.newAutoshipDateFormat = '';
			}
		},
		setAutoshipDateHasError(field) {
			if (typeof this.setAutoshipDateErrors[field] !== 'undefined') { return true; }
			return false;
		},
		setAutoshipDateClearError(field) {
			if (field) {
				delete this.setAutoshipDateErrors[field];
				this.setAutoshipDateErrors = { ...this.setAutoshipDateErrors };
			}
		},
		saveDate() {
			this.setAutoshipDate.setAutoshipDate(this.$user.details().id, { next_autoship_date: this.$moment(this.nextAutoshipDate).format(YMD_FORMAT) }).then((response) => {
				this.alert.toast('success', response.response.message);
				this.$emit('updateAutoshipPeriodicity');
			});
		},
	},
};
</script>
