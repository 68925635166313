export const GIFT_ORDERS_DESCRIPTION_LIST = {
	// points: sub_points
	description_1: 0,
	description_2: 3,
	description_3: 0,
	description_4: 0,
	description_5: 0,
};

export const DESCRIPTION_LIST = {
	default: {
		description_1: 0,
		description_2: 0,
		description_3: 0,
		description_4: 0,
		description_5: 0,
	},
	jns: {
		description_1_jns: 0,
		description_2: 0,
		description_3: 0,
		description_4: 0,
		description_5: 0,
	},
};

export default {};
