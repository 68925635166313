<template>
	<b-card-group>
		<b-card>
			<b-row>
				<b-col>
					<h4 :class="['xs', 'sm'].includes(windowWidth)? '': 'mb-4'">
						{{ translate('backup_credit_cards') }}
					</h4>
				</b-col>
				<b-col
					md="5"
					sm="12"
					xs="12">
					<b-button
						v-b-modal.backup-cards-modal
						variant="primary"
						:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : 'float-right'">
						{{ translate('add_backup_card') }}
					</b-button>
				</b-col>
			</b-row>
			<div class="row">
				<div class="col col-12">
					<b-alert
						show
						variant="info">
						{{ translate('backup_credit_card_info') }}
					</b-alert>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-hover table-striped">
					<thead>
						<tr>
							<th> {{ translate('credit_card') }} </th>
							<th>{{ translate('name_on_card') }}</th>
							<th
								class="align-middle"
								width="10%">
								{{ translate('options') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading">
						<tr
							v-for="(item, index) in backupCards"
							:key="index">
							<td>
								<img
									:key="index"
									:src="require(`@/assets/images/common/cc/card/${cardLogo(item.attributes.card_type)}.png`)"
									class="text-right mr-2">
								<p class="d-inline">
									{{ translate('last_digits') }} <b>{{ item.attributes.last_digits ? item.attributes.last_digits : 'XXXX' }} </b>
								</p>
							</td>
							<td>
								{{ item.attributes.cardholder }}
							</td>
							<td class="text-right">
								<b-button
									v-if="index > 0"
									v-b-tooltip.hover.left
									class="icon-button"
									variant="secondary"
									:title="translate('up_credit_card')"
									@click="sortCard(item.id, 'up')">
									<i
										class="fa fa-sort-up" />
								</b-button>
								<b-button
									v-if="index < backupCards.length - 1"
									v-b-tooltip.hover.left
									class="icon-button ml-2"
									variant="secondary"
									:title="translate('down_credit_card')"
									@click="sortCard(item.id, 'down')">
									<i
										class="fa fa-sort-down" />
								</b-button>
								<b-button
									v-b-tooltip.hover.left
									class="icon-button ml-5"
									variant="danger"
									:title="translate('remove_backup_card')"
									@click="removeBackup(item.id)">
									<i
										class="fa fa-trash" />
								</b-button>
							</td>
						</tr>
					</tbody>
				</table>
				<is-loading
					:loading="loading"
					:loading-label="translate('loading')"
					:has-data="backupCards.length > 0"
					:no-data-label="translate('no_credit_card')"
					:class="loading ? 'mx-auto' : 'w-100'" />
			</div>
		</b-card>
		<b-modal
			id="backup-cards-modal"
			hide-footer
			header-class="mx-auto w-100 bg-dark text-white"
			size="lg"
			:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
			centered>
			<template v-slot:modal-header>
				<h5 class="modal-title text-white">
					{{ translate('backup_credit_cards') }}
				</h5>
				<button
					type="button"
					aria-label="Close"
					class="close text-white"
					@click="$bvModal.hide('backup-cards-modal')">
					×
				</button>
			</template>
			<select-backup-cards-grid
				:data="additonalCards"
				:loading="loading"
				@saveBackupCards="saveBackupCards" />
		</b-modal>
	</b-card-group>
</template>
<script>

import {
	Profile, Validations, Users, Alerts,
} from '@/translations';
import CreditCard from '@/util/CreditCard';
import IsLoading from '@/components/Loading';
import WindowSizes from '@/mixins/WindowSizes';
import CreditCardMixin from '@/mixins/CreditCard';
import SelectBackupCardsGrid from './components/SelectBackupCardsGrid';

export default {
	name: 'BackupCreditCard',
	messages: [Profile, Validations, Users, Alerts],
	components: { IsLoading, SelectBackupCardsGrid },
	mixins: [WindowSizes, CreditCardMixin],
	data() {
		return {
			alert: new this.$Alert(),
			creditCard: new CreditCard(),
			creditCardDefault: new CreditCard(),
			backupCards: [],
			additonalCards: [],
		};
	},
	computed: {
		loading() {
			return !!this.creditCard.data.loading;
		},
		creditCardData() {
			try {
				const { data } = this.creditCard.data.response.data;
				if (data === undefined) {
					return [];
				}
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.creditCard.getCreditCards(this.$user.details().id).then(() => {
				this.transformData();
			});
		},
		transformData() {
			this.backupCards = [];
			this.additonalCards = [];
			this.creditCardData.forEach((item) => {
				if (!item.attributes.is_default && item.attributes.backup_card) {
					this.backupCards.push(item);
				} else if (!item.attributes.is_default && !item.attributes.backup_card) {
					this.additonalCards.push(item);
				}
			});
		},
		removeBackup(creditCardID) {
			this.creditCardDefault.backupCreditCards(this.$user.details().id, { card_ids: creditCardID }).then(() => {
				this.getData();
				this.alert.toast('success', this.translate('credit_card_backup_removed'));
			}).catch(() => {
				this.alert.toast('error', this.translate('something_went_wrong'));
			});
		},
		sortCard(creditCardID, movement) {
			this.creditCardDefault.sortCreditCard(this.$user.details().id, creditCardID, { movement }).then(() => {
				this.getData();
				this.alert.toast('success', this.translate('credit_card_updated'));
			}).catch(() => {
				this.alert.toast('error', this.translate('something_went_wrong'));
			});
		},
		saveBackupCards(cardIds) {
			this.creditCardDefault.backupCreditCards(this.$user.details().id, { card_ids: cardIds.join(), is_backup: true }).then(() => {
				this.$bvModal.hide('backup-cards-modal');
				this.getData();
				this.alert.toast('success', this.translate('credit_card_updated'));
			}).catch(() => {
				this.alert.toast('error', this.translate('something_went_wrong'));
			});
		},
	},
};
</script>
