<template>
	<div>
		<div
			v-if="cartOptions.is_pack"
			class="h4 text-muted mt-3">
			<button
				type="button"
				class="btn btn-link text-muted"
				style="font-size: large;"
				@click="$router.push({ name: 'PurchasePack' })">
				<i class="fa fa-angle-left" />
			</button>
			{{ translate('go_back_to_packs') }}
		</div>
		<div class="row">
			<div class="col d-flex justify-content-center">
				<div
					:class="!cartOptions.is_pack ? 'mt-3' : ''"
					class="h2">
					{{ translate('purchase_confirmation') }}
				</div>
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<div
			:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
			class="row mb-5">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								v-if="!hideAddresses"
								:compact="isCompact('PurchaseShipping')"
								:init-edit="isEditing('PurchaseShipping')"
								:title="`${translate('shipping_information')}`"
								:hide-controls="isEditing('PurchaseShipping') || loadingInitialInformation"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing('PurchaseShipping')"
								@toggled="editStep('PurchaseShipping')">
								<shipping-overview
									v-if="!isEditing('PurchaseShipping')"
									:loading-override="loadingInitialInformation"
									:shipping-method-import="shippingMethod"
									:show-shipping-alert="showShippingMethodAlert"
									:override-shipping-method="!loadingInitialInformation && !systemLoading"
									@noData="editStep('PurchaseShipping')"
									@toggleGiftAddress="changeGiftAddress($event, true)" />
								<shipping-edit
									v-if="isEditing('PurchaseShipping')"
									:cart-id="cartId"
									:has-nfr="hasNfr"
									:show-gift-option="allowGiftCart"
									:initial-is-gift-address="sendAsGift"
									@cancel="changeGiftAddress(!sendAsGift, false)"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@saved="handleSavedStep('PurchaseShipping')" />
							</flow-info-section>
							<flow-info-section
								v-if="!hidePayment"
								:compact="isCompact('PurchasePayment')"
								:init-edit="isEditing('PurchasePayment')"
								:hide-controls="isEditing('PurchasePayment') || loadingInitialInformation"
								:title="`${translate('payment_method')}`"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing('PurchasePayment')"
								@toggled="editStep('PurchasePayment')">
								<payment-overview
									v-if="!isEditing('PurchasePayment')"
									:loading-override="loadingInitialInformation"
									:can-split-with-wallet="canSplitWithWallet"
									:cart-total="cartNumericTotal"
									:payment-method-conditions="paymentMethodConditions"
									@walletSplit="enableWalletSplitAndEdit"
									@changePaymentMethodSelected="changePaymentMethodSelected"
									@noData="editStep('PurchasePayment')" />
								<payment-edit
									v-if="isEditing('PurchasePayment')"
									ref="paymentEdit"
									:cart-id="cartId"
									:can-split-with-wallet="canSplitWithWallet"
									:wallet-split.sync="walletSplit"
									:cart-total="cartNumericTotal"
									:system-loading="systemLoading"
									:can-split-payment="canSplitPayment"
									:cart-numeric-total="cartNumericTotal"
									@splitPayment="isSplitPayment = $event"
									@cancel="handleCancelledStep('PurchasePayment')"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@saved="handleSavedStep('PurchasePayment')"
									@changePaymentMethodSelected="changePaymentMethodSelected" />
							</flow-info-section>
							<flow-info-section
								v-if="cartHasTickets"
								:compact="false"
								init-edit
								hide-controls
								:title="translate('event_attendees')"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="true">
								<div
									v-if="!systemLoading">
									<!-- <div
										class="col-12 mt-3">
										<p class="my-0 font-weight-bolder text-danger">
											{{ translate('ticket_not_refundable_disclaimer') }}
										</p>
									</div> -->
									<event-ticket
										v-for="(item, key) in tickets"
										:key="key"
										:ticket-code-name="ticketCodeName(item.code_name)"
										:names="eventAttendees[ticketCodeName(item.code_name)]"
										:quantity="item.qty"
										:ticket-count="item.tickets_count"
										:total-tickets="item.total_tickets"
										:companion-types="item.companion_types"
										:bed-types="item.bed_types"
										:bed-types-visibility="item.room_types_visibility"
										:check-in-dates="item.check_in_dates"
										:reservation-nights="item.reservation_nights"
										@dataChanged="saveTickets(ticketCodeName(item.code_name), $event)" />
								</div>
								<div
									v-else
									class="col-12 fade-in text-center d-flex justify-content-center align-items-center mt-3 mb-3">
									<div class="col-12">
										<div class="h2">
											<i class="fa fa-fw fa-spinner fa-pulse" />
											<h4 class="mt-3">
												{{ translate('loading') }}
											</h4>
										</div>
									</div>
								</div>
							</flow-info-section>
						</flow-info-group>
					</div>
					<div class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('review_your_cart_items') }}</h5>
							</div>
						</div>
						<div
							v-if="showMessages && !cartOptions.is_pack"
							class="row mt-1">
							<div class="col">
								<possible-discounts
									v-if="!systemLoading"
									:possible-discount-messages="possibleDiscountMessages"
									:free-products-info="freeProductsInfo"
									:promotions="promotions" />
							</div>
						</div>
						<div class="row mt-1">
							<div
								class="col">
								<cart-items
									:loading="systemLoading || loadingInitialInformation"
									:cart-items="cartProducts"
									:qty-import="qty"
									:editing-disabled="!!cartOptions.is_pack"
									hide-loading
									:is-pack="!!cartOptions.is_pack"
									:package-option="cartOptions.package_option"
									:package-qty="cartOptions.package_qty"
									@update-quantity="updateItemQty"
									@remove-product="removeItem"
									@use-points="usePoints"
									@remove-points="removePoints"
									@update-split-wallet="updateSplitWallet" />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading || loadingInitialInformation"
							:has-data="cartProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:cart-id="cartId"
					:package-code-name="cartOptions.package_code_name || ''"
					:is-pack="!!cartOptions.is_pack"
					:package-option="cartOptions.package_option"
					:package-qty="cartOptions.package_qty"
					:free-products-info="freeProductsInfo"
					:cart-loading="systemLoading || loadingInitialInformation"
					:cart-totals="cartTotals"
					:cart-points="cartPoints"
					:cart-small-text-totals="cartSmallTextTotals"
					:cart-items="cartProducts"
					:cart-total="cartTotal"
					:has-tickets="cartHasTickets"
					:tickets-info="eventAttendees"
					:total-volume="totalVolume"
					:split-payment-info="splitPaymentInfo"
					:split-payment-loading="splitPaymentLoading"
					:clicked-payment-method="clickedPayment"
					:cart-coupon="cartCoupon"
					:coupon-errors="couponErrors"
					:coupon-loading="couponLoading"
					:remove-coupon-loading="removeCouponLoading"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:terms-and-policies="termsAndPolicies"
					:show-credits="showCredits && !cartOptions.is_pack"
					:credits-used="creditsUsed"
					:apply-free-bottle="applyFreeBottle"
					:free-bottle-message="freeBottleMessage"
					:editing-other-steps="isEditingAnyStep"
					:skip-steps="cartHasTickets && hideAddresses"
					:upgrade-packages="upgradePackages"
					:upgrade-volume="upgradeVolume"
					:has-upgrade-packages="hasUpgradePackages"
					:discount-detail="discountDetail"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					:show-backorder-agreement="hasBackorderProducts"
					@couponSubmit="applyCoupon"
					@couponRemove="removeCoupon"
					@couponChange="handleCouponChange"
					@use-points="usePoints(pickupAtOffice())"
					@remove-points="removePoints(pickupAtOffice())"
					@update-split-wallet="updateSplitWallet"
					@cartValidationError="redirectToStore"
					@invalidRequest="handleInvalidRequest"
					@serverError="redirectToStore"
					@purchaseClick="handleOrderClick"
					@purchaseErrors="handlePurchaseErrors"
					@cartReplaced="handleCartReplaced"
					@splitPayment="splitPayment"
					@reloadCart="getCartTotals" />
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
import EventTicket from '@/components/EventTicket';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import CartData from '@/mixins/CartData';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { affiliate, customer, distributor } from '@/settings/Roles';
import { COUNTRIES_SPLIT_WITH_WALLET_BLACKLIST, PRODUCTS_NOT_ALLOWED_WITH_WALLET } from '@/settings/Purchase';
import {
	Cart, Grids, Profile, Purchase as PurchaseMessages, Validations, Events,
} from '@/translations';
import Purchase from '@/util/Purchase';
import PossibleDiscounts from '@/components/PossibleDiscounts';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentOverview from './components/Payment/Overview';
import PaymentEdit from './components/Payment/Edit';
import ShippingEdit from './components/Shipping/Edit';
import ShippingOverview from './components/Shipping/Overview';
import Steps from './mixins/Steps';
import store from './store';
import registerStore from '@/views/Register/store';

export default {
	name: 'PurchaseConfirmation',
	messages: [Cart, Grids, Profile, PurchaseMessages, Validations, Events],
	components: {
		PossibleDiscounts,
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentOverview,
		PaymentEdit,
		ShippingOverview,
		ShippingEdit,
		EventTicket,
	},
	mixins: [CartData, DocumentTitle, Steps, WindowSizes],
	data() {
		return {
			purchaseInfo: new Purchase(),
			paymentMethodConditions: {},
			distributor,
			canLeave: false,
			editing: {
				PurchaseShipping: false,
				PurchasePayment: false,
				PurchaseConfirmation: false,
			},
			purchaseErrors: [],
			clickedPayment: '',
			walletSplit: false,
			sendAsGift: false,
			productsNotAllowedWithWallet: PRODUCTS_NOT_ALLOWED_WITH_WALLET,
			countriesNotSplitWithWallet: COUNTRIES_SPLIT_WITH_WALLET_BLACKLIST,
			isSplitPayment: false,
		};
	},
	computed: {
		canSplitWithWallet() {
			return this.$user.auth() && [distributor, affiliate].includes(this.$user.details().type)
				&& this.cartProducts.filter((item) => this.productsNotAllowedWithWallet.includes(item.sku)).length === 0
				&& !this.countriesNotSplitWithWallet.includes(this.$user.details().country.iso_code_2);
		},
		loadingInitialInformation() {
			return this.purchaseInfo.data.loading;
		},
		isEditingAnyStep() {
			if (this.hideAddresses) {
				return false;
			}

			return Object.keys(this.editing)
				.filter((stepName) => stepName !== 'PurchaseConfirmation')
				.some((stepName) => this.editing[stepName]);
		},
		hasPurchaseErrors() {
			return !!this.purchaseErrors.length;
		},
		showCredits() {
			return [distributor, affiliate, customer].includes(this.$user.details().type) && this.hasCredits;
		},
		paymentMethodSelected() {
			const { payment } = { ...store.getters.getStepInformation('PurchasePayment') };
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name || this.clickedPayment;
		},
	},
	watch: {
		cartProducts(newVal) {
			if (!newVal.length) {
				this.alert.toast('error', this.translate('empty_cart'), { timer: 6000 });
				setTimeout(() => {
					this.redirectToStore();
				}, 6000);
			}
		},
		skipPaymentMinValidation(newVal) {
			this.skipMinValidation = newVal;
		},
		// clickedPayment(newVal) {
		// 	if (typeof this.paymentMethodConditions[newVal] !== 'undefined' && !this.paymentMethodConditions[newVal]?.isAvailable) {
		// 		this.editStep('PurchasePayment');
		// 	}
		// },
		cartNumericTotal(amountToPay) {
			if (typeof this.paymentMethodConditions !== 'undefined') {
				const min = this.paymentMethodConditions.min || null;
				const max = this.paymentMethodConditions.max || null;
				const subtotalMin = min !== null ? amountToPay >= min : true;
				const subtotalMax = max !== null ? amountToPay <= max : true;
				if (!subtotalMin || !subtotalMax) {
					this.editStep('PurchasePayment');
				}
			}
		},
		walletSplit(newVal) {
			if (newVal) {
				this.$refs.paymentEdit.updateSplitPaymentValue(false);
			}
		},
	},
	async mounted() {
		this.setCartFlow('purchase');
		await store.dispatch('removeInfo');
		await this.getStartingInfo();
		await this.resetPaymentMethod();
		if (this.$user.details().type === this.distributor) {
			this.getUpgradePackagesInfo();
		}
	},
	methods: {
		async getStartingInfo() {
			const response = await this.purchaseInfo.getPurchaseInformation();
			if (this.$route.params.storedCartId) {
				response.response.cart_id = this.$route.params.storedCartId;
			}
			if (response.response.is_pack) {
				this.cartOptions = { is_pack: 1, package_code_name: response.response.package_code_name };
			}
			if (this.$route.params.package_option) {
				this.cartOptions.package_option = this.$route.params.package_option;
			}
			if (this.$route.params.package_qty) {
				this.cartOptions.package_qty = this.$route.params.package_qty;
			}
			this.cartOptions.pickup_at_office = response.response.pickup_at_office;
			await store.dispatch('saveInitialInformation', response.response);
			return this.setCartCookie(response.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
		},
		completedSteps() {
			return store.getters.getCompletedSteps();
		},
		stepIsCompleted(step) {
			return this.completedSteps().includes(step);
		},
		isEditing(step) {
			return this.editing[step];
		},
		editStep(step) {
			this.editing[step] = true;
		},
		setStepAsNotEditing(step) {
			this.editing[step] = false;
		},
		isCompact(step) {
			return !this.isEditing(step) && this.stepIsCompleted(step);
		},
		handleCancelledStep(step) {
			this.setStepAsNotEditing(step);
		},
		handleSavedStep(step) {
			this.getCartTotals();
			this.setStepAsNotEditing(step);
		},
		changePaymentMethodSelected(selected, conditions) {
			if (selected !== 0) this.clickedPayment = selected;
			if (conditions) {
				this.paymentMethodConditions = conditions;
			}
		},
		handleInvalidRequest() {
			// this.createNewCart();
			// this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
			// setTimeout(() => {
			this.resetStepsAndRedirect();
			// }, 6000);
		},
		handlePurchaseErrors(purchaseErrors) {
			this.purchaseErrors = purchaseErrors;
		},
		handleOrderClick() {
			this.purchaseErrors = [];
		},
		handleCartReplaced(cartId, reloadCart = true) {
			this.setCartCookie(cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` }, reloadCart);
		},
		resetStepsAndRedirect() {
			store.dispatch('removeInfo').then(() => {
				this.redirectToStore();
			}).catch(() => {});
		},
		redirectToStore() {
			this.canLeave = true;
			this.$router.replace({ name: 'Store' });
		},
		resetPaymentMethod() {
			const paymentInformation = store.getters.getStepInformation('PurchasePayment');
			const data = {
				PurchasePayment: {
					payment: {
						billing: {
							address_id: 0,
						},
					},
				},
			};
			if (paymentInformation.payment) {
				data.PurchasePayment.payment.billing.address_id = paymentInformation.payment.billing.address_id;
			}
			return store.dispatch('saveInfo', data)
				.then(() => store.dispatch('saveStepAsIncomplete', 'PurchasePayment'))
				.then(() => this.editStep('PurchasePayment'));
		},
		ticketCodeName(codeName) {
			if (codeName.endsWith('_mp')) {
				return codeName.slice(0, -3);
			}
			return codeName;
		},
		enableWalletSplitAndEdit() {
			this.walletSplit = true;
			this.editStep('PurchasePayment');
		},
		updateSplitWallet() {
			if (this.walletSplit || this.isSplitPayment) {
				this.editStep('PurchasePayment');
			}
		},
		changeGiftAddress(newValue, edit = false) {
			this.sendAsGift = newValue;
			if (edit) {
				this.editStep('PurchaseShipping');
			} else {
				this.handleCancelledStep('PurchaseShipping');
			}
		},
		updateItemQty(newQty, product) {
			const shippingInfo = store.getters.getStepInformation('PurchaseShipping');
			// eslint-disable-next-line camelcase
			const pickupAtOffice = shippingInfo?.shipping?.pickup_at_office ?? this.cartOptions.pickup_at_office ?? null;
			this.updateQuantity(newQty, product, false, pickupAtOffice);
		},
		removeItem(product) {
			const shippingInfo = store.getters.getStepInformation('PurchaseShipping');
			// eslint-disable-next-line camelcase
			const pickupAtOffice = shippingInfo?.shipping?.pickup_at_office ?? this.cartOptions.pickup_at_office ?? null;
			this.removeProduct(product, pickupAtOffice);
		},
		pickupAtOffice() {
			const shippingInfo = store.getters.getStepInformation('PurchaseShipping');
			// eslint-disable-next-line camelcase
			return shippingInfo?.shipping?.pickup_at_office ?? this.cartOptions.pickup_at_office ?? null;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('Purchase')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			if (this.canLeave) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		}
	},
	beforeRouteEnter(to, from, next) {
		// This is to fix the error when the user buys a member pack and then goes to register a new user.
		// This is because the PurchasePack page is saving a user's cart id in the register store (Vuex) when we need a guest cart id.
		// So, we need to remove the info from the register store if we are coming from the PurchasePack page.
		if (from.name === 'PurchasePack') {
			next(async () => {
				await registerStore.dispatch('removeInfo');
			});
		} else {
			next();
		}
	},
};
</script>
