<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="autoshipModal"
		no-close-on-esc
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		scrollable
		@shown="updateProductsSelection">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ title }}
			</h5>
		</template>
		<template #modal-footer>
			<div class="row w-100">
				<div class="col-12 w-100 d-flex justify-content-end">
					<div
						v-for="(item, index) in discounts"
						:key="index"
						:style="{
							width: `${item.width}%`,
							fontSize: currentProgress >= item.min ? '20px' : ''
						}"
						:class="{
							'text-muted': currentProgress < item.min,
							'text-discount': currentProgress >= item.min,
						}"
						class="p-0 d-flex flex-column align-items-end font-weight-bold"
						style="align-self: flex-end;">
						${{ item.discount }}
						<i
							class="fas fa-caret-up fa-lg text-lime" />
					</div>
				</div>
				<div
					v-if="fullyCustomizable"
					class="col-12">
					<b-progress
						:max="packVolume"
						precision="0.01"
						:animated="true"
						:variant="progressVariant"
						height="30px"
						striped
						class="mt-auto">
						<b-progress-bar
							:value="currentVolumeProgress"
							:class="progressClass">
							<strong style="width: 100%; position: absolute; font-size: 1.7em;">
								<span class="text-dark bg-light h-100 rounded px-3">
									{{ currentVolumeProgress + ' / ' + packVolume + ' ' + translate('bv') }}
								</span>
							</strong>
						</b-progress-bar>
					</b-progress>
					<h6
						v-if="upgradeText"
						class="text-discount text-center mt-1">
						{{ upgradeText }}
					</h6>
				</div>
				<div
					v-else-if="fullyAmountCustomizable || minimumAmountCustomizable"
					class="col-12">
					<b-progress
						:max="packPrice"
						precision="0.01"
						:animated="true"
						height="30px"
						striped
						class="mt-auto">
						<b-progress-bar
							:value="currentAmountProgress"
							:class="progressAmountClass">
							<strong style="width: 100%; position: absolute; font-size: 1.7em;">
								<span class="text-dark bg-light h-100 rounded px-3">
									{{ calculatedAmountProgressFormatted }}  /  {{ formattedPackPrice }}
								</span>
							</strong>
						</b-progress-bar>
					</b-progress>
				</div>
				<div
					v-if="fullyCustomizable"
					class="col-12 text-right mt-1">
					<!--<h6>{{ translate('subtotal') }} {{ currentProgress | currency('usd', translate) }}</h6>-->
					<h3>{{ translate('total') }} {{ showAdditionalSign ? '+' : '' }} {{ currentProgress - discount | currency('usd', translate) }}</h3>
				</div>
				<div
					v-if="fullyAmountCustomizable"
					class="col-12 text-right mt-1">
					<!--<h6>{{ translate('subtotal') }} {{ currentProgress | currency('usd', translate) }}</h6>-->
					<h3>{{ showAdditionalSign ? '+' : '' }} {{ currentVolumeProgress + ' ' + translate('bv') }}</h3>
				</div>
				<div class="col col-12 no-gutters justify-content-end text-right">
					<button
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-secondary btn-lg mt-3 mr-2"
						@click="cancel()">
						{{ translate('cancel') }}
					</button>
					<button
						:disabled="disableConfirm || isCalculationLoading || loadingSave"
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-primary btn-lg mt-3"
						@click="confirm()">
						<span
							v-if="loadingSave"
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true" />
						{{ translate('confirm_autoship') }}
					</button>
				</div>
			</div>
		</template>
		<b-alert
			:show="showBackorderInfo"
			variant="warning">
			<div v-html="translate('backorder_info')" />
			<template v-if="hasSelectedBackorderProducts">
				{{ translate('estimated_shipping_date', { date: '' }) }}
			</template>
		</b-alert>
		<!-- This is the list shown when there are no NFR products in the market -->
		<product-list
			v-if="nfrConfigurableProducts.length === 0 && nfrSimpleProducts.length === 0"
			:show-backorder-info="showBackorderInfo"
			:description="description"
			:configurable-products="configurableProducts"
			:simple-products="simpleProducts"
			:selected-products.sync="selectedProducts"
			:loading="loading" />
		<!-- When there are NFR products in the market, this section is shown instead. -->
		<template v-else>
			<b-alert
				show
				variant="info">
				{{ translate('register_product_group_info') }}
			</b-alert>
			<b-tabs @input="handleTabChange">
				<b-tab>
					<template v-slot:title>
						<a class="list-group-item btn text-left text-gray-dark">
							{{ translate('products') }}
						</a>
					</template>
					<product-list
						:show-backorder-info="showBackorderInfo"
						:description="description"
						:configurable-products="configurableProducts"
						:simple-products="simpleProducts"
						:selected-products.sync="selectedProducts"
						:loading="loading" />
				</b-tab>
				<b-tab>
					<template v-slot:title>
						<a class="list-group-item btn text-left text-gray-dark">
							{{ translate('nfr_products') }}
						</a>
					</template>
					<product-list
						:show-backorder-info="showBackorderInfo"
						:description="description"
						:configurable-products="nfrConfigurableProducts"
						:simple-products="nfrSimpleProducts"
						:selected-products.sync="selectedProducts"
						:loading="loading" />
				</b-tab>
			</b-tabs>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!products.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</b-modal>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase, Promotions } from '@/translations';
import ProductList from './ProductList.vue';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';
import { currency } from '@/config/Filters';
import GeneralInformation from '@/util/GeneralInformation';

export default {
	name: 'AdditionalProductsModal',
	messages: [Products, Purchase, Promotions],
	components: {
		IsLoading,
		ProductList,
	},
	filters: {
		currency,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		autoshipProducts: {
			type: Object,
			default: () => ({}),
		},
		loadingProducts: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		discounts: {
			type: Array,
			default: () => [],
		},
		showAdditionalSign: {
			type: Boolean,
			default: false,
		},
		packVolume: {
			type: Number,
			default: 0,
		},
		fullyCustomizable: {
			type: Boolean,
			default: false,
		},
		fullyAmountCustomizable: {
			type: Boolean,
			default: false,
		},
		minimumAmountCustomizable: {
			type: Boolean,
			default: false,
		},
		selectedPack: {
			type: String,
			default: '',
		},
		isRegister: {
			type: Boolean,
			default: false,
		},
		sponsorId: {
			type: Number,
			default: 0,
		},
		loadingSave: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedProducts: {},
			packageCalculation: new GeneralInformation(),
		};
	},
	computed: {
		showBackorderInfo() {
			// eslint-disable-next-line camelcase
			return this.simpleProducts.some((item) => item.attributes.backorder_info?.will_backorder ?? false)
				|| this.configurableProducts.some((item) => {
					const children = !Array.isArray(item.attributes.children) ? Object.values(item.attributes.children) : item.attributes.children;
					// eslint-disable-next-line camelcase
					return children.some((child) => child.backorder_info?.will_backorder ?? false);
				});
		},
		hasSelectedBackorderProducts() {
			// TODO: Posponed
			return false;
		},
		currentProgress() {
			const amount = this.packPrice;
			let extraProductsAmount = 0;
			const productsWithDiscount = [];
			let productWithTenDiscount = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productPrice = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productPrice = product.attributes.price_amount;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productPrice = product.attributes.children[sku].price_amount;
						}
					});
				});

				if (productPrice > 0 && productsWithDiscount.some((prefix) => key.startsWith(prefix))) {
					productWithTenDiscount += value.value;
				}
				extraProductsAmount += value.value * productPrice;
			});
			extraProductsAmount -= 10 * productWithTenDiscount;
			return amount + extraProductsAmount;
		},
		currentVolumeProgress() {
			if (this.fullyCustomizable || this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return this.calculatedVolumeProgress;
			}

			let productsVolume = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productVolume = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productVolume = product.attributes.bvs;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productVolume = product.attributes.children[sku].bvs;
						}
					});
				});

				productsVolume += value.value * productVolume;
			});
			return productsVolume;
		},
		currentAmountProgress() {
			if (this.fullyCustomizable || this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return this.calculatedAmountProgress.toFixed(2);
			}

			let productsAmount = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productAmount = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productAmount = product.attributes.price_amount;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productAmount = product.attributes.children[sku].price_amount;
						}
					});
				});

				productsAmount += value.value * productAmount;
			});
			return productsAmount.toFixed(2);
		},
		progressVariant() {
			return this.currentVolumeProgress >= this.packVolume ? 'secondary' : 'primary';
		},
		progressClass() {
			return this.currentVolumeProgress >= this.packVolume ? 'success-background' : '';
		},
		progressAmountClass() {
			// eslint-disable-next-line no-nested-ternary
			return this.exceedsAllowedAmount ? 'error-background' : (this.currentAmountProgress >= this.packPrice ? 'success-background' : '');
		},
		maxDiscount() {
			try {
				if (this.discounts.length > 0) {
					return this.discounts[this.discounts.length - 1].min;
				}
			} catch (error) {
				return 0;
			}

			return 0;
		},
		discount() {
			return [...this.discounts].reverse().find((item) => this.currentProgress >= item.min)?.discount ?? 0;
		},
		loading() {
			return this.loadingProducts || !Object.keys(this.selectedProducts).length || !this.products.length;
		},
		simpleProducts() {
			return this.products.filter((product) => !product.attributes.has_configurations)
				.filter((product) => !product.attributes.is_nfr);
		},
		configurableProducts() {
			return this.products.filter((product) => product.attributes.has_configurations)
				.filter((product) => !product.attributes.is_nfr);
		},
		nfrSimpleProducts() {
			return this.products.filter((product) => !product.attributes.has_configurations)
				.filter((product) => product.attributes.is_nfr);
		},
		nfrConfigurableProducts() {
			return this.products.filter((product) => product.attributes.has_configurations)
				.filter((product) => product.attributes.is_nfr);
		},
		disableConfirm() {
			if (this.fullyCustomizable) {
				return this.currentVolumeProgress < this.packVolume;
			}
			if (this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return !this.isAllowedAmount || this.exceedsAllowedAmount;
			}
			const selectedQty = Object.values(this.selectedProducts).reduce((accum, product) => accum + product.value, 0);
			const minQty = AUTOSHIP_MIN_QTY[this.country];
			return selectedQty < minQty;
		},
		calculatedAmountProgress() {
			try {
				return this.packageCalculation.data.response.data.response.product.total;
			} catch (error) {
				return 0;
			}
		},
		calculatedAmountProgressFormatted() {
			try {
				return this.packageCalculation.data.response.data.response.product.total_formatted;
			} catch (error) {
				return 0;
			}
		},
		packPrice() {
			try {
				return this.packageCalculation.data.response.data.response.product.pack_price;
			} catch (error) {
				return 0;
			}
		},
		formattedPackPrice() {
			try {
				return this.packageCalculation.data.response.data.response.product.pack_price_formatted;
			} catch (error) {
				return 0;
			}
		},
		calculatedVolumeProgress() {
			try {
				return this.packageCalculation.data.response.data.response.product.bvs;
			} catch (error) {
				return 0;
			}
		},
		upgradeText() {
			try {
				return this.packageCalculation.data.response.data.response.product.upgraded_message;
			} catch (error) {
				return null;
			}
		},
		isAllowedAmount() {
			try {
				return this.packageCalculation.data.response.data.response.product.is_allowed_amount;
			} catch (error) {
				return false;
			}
		},
		exceedsAllowedAmount() {
			try {
				return this.packageCalculation.data.response.data.response.product.exceeds_allowed_amount;
			} catch (error) {
				return false;
			}
		},
		isCalculationLoading() {
			return !!this.packageCalculation.data.loading;
		},
	},
	watch: {
		open: {
			handler(newVal) {
				if (this.$refs.autoshipModal) {
					if (newVal) {
						this.$refs.autoshipModal.show();
					} else {
						this.$refs.autoshipModal.hide();
					}
				}
			},
			immediate: true,
		},
		autoshipProducts: {
			handler() {
				this.selectedProducts = window.structuredClone(this.autoshipProducts);
			},
			deep: true,
			immediate: true,
		},
		selectedProducts: {
			handler() {
				this.updateProductsSelection();
			},
			deep: true,
		},
	},
	methods: {
		confirm() {
			const extraProducts = Object.entries(this.selectedProducts).reduce((accumulator, [key, value]) => {
				if (value.value > 0) {
					accumulator[key] = value.value;
				}
				return accumulator;
			}, {});
			// eslint-disable-next-line camelcase
			this.$emit('confirm', [extraProducts, this.packageCalculation?.data?.response?.data?.response?.product?.package_code_name]);
		},
		updateProductsSelection() {
			if (this.fullyCustomizable || this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				const extraProducts = Object.entries(this.selectedProducts).reduce((accumulator, [key, value]) => {
					if (value.value > 0) {
						accumulator[key] = value.value;
					}
					return accumulator;
				}, {});
				this.packageCalculation.packsCalculation({
					flow: this.isRegister ? 'register' : 'purchase',
					country: this.getRegisterCountry(),
					package_code_name: this.selectedPack,
					products: extraProducts,
					sponsor: this.sponsorId,
				});
			}
		},
		cancel() {
			this.$emit('cancel');
		},
		handleTabChange() {
			Object.keys(this.selectedProducts).forEach((key) => {
				this.$set(this.selectedProducts[key], 'value', 0);
			});
		},
	},
};
</script>
<style>
	/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
	.modal-lg-custom {
		max-width: 880px !important;
		width: 880px !important;
	}
	.modal-footer {
		justify-content: center !important;
	}
	.success-background {
		background-color: #7ebc5a !important;
	}
	.error-background {
		background-color: #AA3333 !important;
	}
</style>
