<template>
	<div class="animated fadeIn">
		<div
			v-if="!loading && false"
			class="row mb-2 d-flex text-right">
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					:big-label="totals.orders"
					:small-label="translate('orders_total')" />
			</div>
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					:big-label="totals.volume"
					:small-label="translate('total_volume')" />
			</div>
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					:big-label="totals.totals"
					:small-label="translate('total_orders')" />
			</div>
		</div>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 text-left">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('id')">-->
							{{ translate('order_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('division') }}
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('users.username')">-->
							{{ translate('username') }}
							<!--<sort field="users.username" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('users.name')">-->
							{{ translate('name') }}
							<!--<sort field="users.name" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('users.type')">-->
							{{ translate('user_type') }}
							<!--<sort field="users.type" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('mobile_number')">-->
							{{ translate('mobile_number') }}
							<!--<sort field="mobile_number" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('sponsor_id')">-->
							{{ translate('sponsor_id') }}
							<!--<sort field="sponsor_id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('sponsor_username')">-->
							{{ translate('sponsor_username') }}
							<!--<sort field="sponsor_username" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('country')">-->
							{{ translate('country') }}
							<!--<sort field="country" />-->
						</th>
						<th
							class="border-top-0 text-right">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('total_amount')">-->
							{{ translate('total') }}
							<!--<sort field="total_amount" />-->
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('verification_reason') }}
						</th>
						<th class="border-top-0 text-center">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('created_at')">-->
							{{ translate('date_time') }}
							<!--<<sort field="created_at" />-->
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle text-left">
							{{ item.id }}
							<i
								v-if="item.attributes.may_be_forwarded_to_vietnam"
								class="fas fa-info-circle text-primary ml-1"
								v-b-tooltip.hover.right
								:title="translate('may_be_forwarded_to_vietnam')" />
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.company) }}
						</td>
						<td class="align-middle">
							<span
								v-if="item.attributes.type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.full_name }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.type) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.mobile_number }}
						</td>
						<td class="align-middle">
							<template v-if="item.attributes.sponsor_info.id">
								<span
									v-if="item.attributes.sponsor_info.type === distributor"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.sponsor_info.id.toString() })">
									{{ item.attributes.sponsor_info.id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.sponsor_info.id }}
								</span>
							</template>
						</td>
						<td class="align-middle">
							{{ item.attributes.sponsor_info.username }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country ? item.attributes.country.toUpperCase() : 'default'}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
						<td class="align-middle">
							{{ item.attributes.verification_reason ? translate(item.attributes.verification_reason) : '' }}
						</td>
						<td class="align-middle text-right">
							{{ $moment(item.attributes.date.date).format(dateFormat) }}
						</td>
						<td class="align-middle text-center">
							<icon-button
								:tooltip="translate('view_details')"
								icon-class="fa fa-eye"
								button-class="bg-primary-alt"
								@click="viewDetails(item.id)" />
							<template v-if="$can('orders', 'verify')">
								<icon-button
									:tooltip="translate('approve')"
									variant="success"
									icon-class="fa fa-check"
									button-class="bg-success-alt"
									@click="approveOrder(item.id)" />
								<icon-button
									:tooltip="translate('reject')"
									variant="danger"
									icon-class="fa fa-times"
									@click="rejectOrder(item.id)" />
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import IconButton from '@/components/IconButton';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import OrderVerification from '@/mixins/OrderVerification';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import { PAGINATION } from '@/settings/RequestReply';
import { admin, distributor } from '@/settings/Roles';
import { ORDER_STATUSES } from '@/settings/Statuses';
import {
	Countries, Grids, Network, Tooltip, Users,
} from '@/translations';
import Report from '@/util/Report';
import IconCardWidget from '@/components/IconCardWidget';

export default {
	name: 'OrderVerificationsList',
	messages: [Countries, Grids, Network, Tooltip, Users],
	components: { IconButton, IconCardWidget },
	mixins: [FiltersParams, DashboardRedirect, OrderVerification],
	data() {
		return {
			orderVerifications: new Report(),
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			admin,
			distributor,
			paymentReviewStatus: ORDER_STATUSES.paymentReview,
		};
	},
	computed: {
		loading() {
			return !!this.orderVerifications.data.loading;
		},
		errors() {
			return this.orderVerifications.data.errors;
		},
		pagination() {
			return this.orderVerifications.data.pagination;
		},
		data() {
			try {
				const { data } = this.orderVerifications.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		totals() {
			try {
				const totals = this.orderVerifications.data.response.data.meta;
				return { totals: totals.totals, orders: totals.orders_qty.toString(), volume: totals.orders_volume.toString() };
			} catch (error) {
				return { totals: '0', orders: '0', volume: '0' };
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		idsComputed() {
			const ids = [];
			this.ids.forEach((item, index) => {
				if (item) {
					ids.push(index);
				}
			});
			return ids;
		},
	},
	mounted() {
		this.orderVerifications.getOrderVerifications('billing');
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.orderVerifications.getOrderVerifications('billing', options);
		},
		viewDetails(orderId) {
			this.$router.push({ name: 'OrdersDetails', params: { order_id: orderId } });
		},
		approveOrder(orderId) {
			this.approveOrderVerification(orderId, { onFinally: () => { this.orderVerifications.getOrderVerifications('billing'); } });
		},
		rejectOrder(orderId) {
			this.rejectOrderVerification(orderId, { onFinally: () => { this.orderVerifications.getOrderVerifications('billing'); } });
		},
	},
};
</script>
