export const COUNTRY_ADDRESS_FORM_TYPES = {
	address_form_type_2: ['MX'],
	address_form_type_3: ['CN', 'HK', 'MO'],
};

export const ALLOW_CHANGE_BILLING_COUNTRY = ['CN', 'KH'];

export const ADDITIONAL_ADDRESS_AGREEMENT_COUNTRIES = ['CN'];

export default {};
