export default {};
export const MAX_QTY = 99;
export const MIN_QTY = 1;
export const INITIAL_QTY = 0;
export const POSITIVE_COLOR = 'green';
export const POSITIVE_VALUES = ['discount'];
export const MAIN_PRODUCT_SKU = 'BR-1002';
export const JNS_MAIN_PRODUCT_SKU = {
	default: 'RES-PKT',
	HR: 'FIN-SCH',
	BR: 'FIN-SCH',
	CO: 'FIN-SCH',
	HN: 'FIN-SCH',
	IL: 'LMN-SRM',
	KE: 'FIN-SCH',
	MQ: 'FIN-SCH',
	MC: 'FIN-SCH',
	RS: 'PM-CPL',
	TR: 'MND-PKT',
	UY: 'FIN-SCH',
	PY: 'FIN-SCH',
	BS: 'FIN-SCH',
};
export const HIDDEN_PRESENTATION_SKUS = ['JTN'];
