<template>
	<div class="row storeproduct justify-content-around">
		<div
			v-for="(product, key) in products"
			:key="key"
			:class="productClass"
			class="mb-5 col col-sm-6 product d-flex flex-column mw-100">
			<div
				style="position: relative; display: inline-block; overflow: hidden;"
				role="button"
				@click="$router.push({ name: getRedirectName('ProductDetails'), params: { productName: product.decoded_name } })">
				<img
					:src="product.image"
					class="img-fluid mx-auto pointer"
					:alt="translate(product.code_name) + ' Image'">
				<img
					v-if="product.isHotSale"
					:src="`${S3_PATH}/assets/products/${themeName}/badges/hot_sale_badge.png`"
					style="position: absolute; top: 10px; right: 10px; height: 25px;"
					class="img-fluid mx-auto pointer"
					alt="Hot Sale Badge">
				<div class="product-img-overlay" />
			</div>
			<div
				:class="product.has_configurations && ['xs'].includes(windowWidth) ? 'mb-3': 'mb-4'"
				:style="product.has_configurations && ['xs'].includes(windowWidth) ? '': 'height: 40px;'">
				<h3
					:class="['sm', 'md'].includes(windowWidth) ? 'h5' : 'h4'"
					class="text-dark font-weight-bold h4 mb-0">
					{{ translate(product.code_name) }}
				</h3>
				<p v-if="product.detailsLink">
					<a
						class="text-blue font-weight-light"
						target="_blank"
						:href="product.detailsLink">
						{{ translate('learn_more') }}
					</a>
				</p>
			</div>
			<div class="flex-1">
				<p class="text-primary h2">
					{{ product.price }}
				</p>
				<p
					v-if="product.exchange"
					class="h6 exchange-text">
					{{ product.exchangePrice }}*
				</p>
				<p
					class="text-muted">
					{{ !HIDDEN_PRESENTATION_SKUS.includes(product.sku) ? translate(`${product.code_name}_presentation`) : '' }}
				</p>
				<a
					:class="{ disabled: loading, 'mx-1': ['sm'].includes(windowWidth) }"
					style="font-weight: 600;"
					role="button"
					class="btn btn-primary p-2 pointer custom-btn custom-primary-btn branded-button"
					:href="$router.resolve({ name: getRedirectName('ProductDetails'), params: { productName: product.decoded_name } }).href">
					{{ translate('see_more') }}
				</a>
				<!-- <template>
					<number-input-spinner
						v-model="product.quantity"
						:mouse-down-speed="500"
						:min="MIN_QTY"
						:max="MAX_QTY"
						:button-class="'vnis__button bg-primary-alt'"
						:input-class="'vnis__input vnis-input-custom-width'"
						:integer-only="true"
						class="mx-auto mb-4 vnis-custom-dimensions vnis-smaller d-flex justify-content-center" />
					<div
						:class="{ disabled: loading, 'mx-1': ['sm'].includes(windowWidth) }"
						style="font-weight: 600;"
						class="btn btn-primary p-2 pointer custom-btn custom-primary-btn"
						@click="clicked = product.sku;handleAddToCart({ [product.sku]: product.quantity }, getProductOptions(product));">
						<template v-if="loading && clicked === product.sku">
							<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('add_to_cart') }}
						</template>
					</div>
				</template> -->
			</div>
		</div>
	</div>
</template>

<script>

// import NumberInputSpinner from 'vue-number-input-spinner';
import { Store, Products } from '@/translations';
import { MIN_QTY, MAX_QTY, HIDDEN_PRESENTATION_SKUS } from '@/settings/Cart';
import WindowSizes from '@/mixins/WindowSizes';
import AgencySite from '@/mixins/AgencySite';
import { S3_PATH } from '@/settings/Images';

// NumberInputSpinner.methods.whileMouseDown = function whileMouseDown(callback) {
// 	if (this.timer === null) {
// 		this.timer = setInterval(() => {
// 			callback();
// 		}, this.mouseDownSpeed);
// 	}
// };
//
// NumberInputSpinner.watch.value = function changeValue(val) {
// 	this.numericValue = val;
// };

export default {
	name: 'StoreProduct',
	messages: [Products, Store],
	components: { /* NumberInputSpinner */ },
	mixins: [WindowSizes, AgencySite],
	props: {
		products: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: () => false,
		},
	},
	data() {
		return {
			MIN_QTY,
			MAX_QTY,
			clicked: 0,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
			HIDDEN_PRESENTATION_SKUS,
		};
	},
	computed: {
		hasByom() {
			return this.isSkuInProducts('BY');
		},
		hasZlem() {
			return this.isSkuInProducts('ZL');
		},
		productClass() {
			const paddingClass = ['sm'].includes(this.windowWidth) ? 'px-1' : '';
			const columnSize = 'col-lg-4';
			// if (this.hasByom && this.hasZlem) {
			// 	return 'col-md-4';
			// }

			return `${paddingClass} ${columnSize}`;
		},
	},
	methods: {
		isSkuInProducts(sku) {
			return this.products.map((item) => item.sku).includes(sku);
		},
		getProductOptions(product) {
			const options = { image: product.thumbnail };
			return options;
		},
		handleAddToCart(product, options) {
			if (!this.loading) {
				this.$emit('add_product', product, options);
			}
		},
	},
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.storeproduct .custom-btn  html body div {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 600 !important;
}

p h5 {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 400 !important;
}

.bottom_b {
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 0px;
}

.flex-1 {
	flex: 1;
}

.text-blue {
	color: #13637f !important;
}

.product-img-overlay {
	cursor: pointer;
	position: absolute;
	border-radius: 5px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: bold;
	opacity: 0;
	transition: opacity 0.3s ease;
}

.product-img-overlay:hover {
	opacity: 0.08;
}
</style>
