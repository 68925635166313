<template>
	<div />
</template>
<script>
import { admin } from '@/settings/Roles';
import { Dashboard as DashboardMessages } from '@/translations';
import Dashboard from '@/util/Dashboard';

export default {
	messages: [DashboardMessages],
	data() {
		return {
			admin,
			alert: new this.$Alert(),
			showVClubPopup: new Dashboard(),
			hideVClubPopup: new Dashboard(),
			themeName: process.env.VUE_APP_THEME,
		};
	},
	mounted() {
		const isValidUserType = this.$user.auth() && ['distributor', 'customer', 'affiliate'].includes(this.$user.details().type);
		if (!isValidUserType) {
			this.$emit('done');
		}
	},
};
</script>
<style>
/* These styles are not scoped due to sweet-alert-2 not having access to the vue-generated data- hash */
.v-club-popup-input {
	margin-top: 1.875em;
	margin-bottom: 0;
}
.v-club-popup-input span {
	font-size: 0.75em;
}
.v-club-popup-actions {
	margin-top: 0.75em;
}
</style>
