<template>
	<div class="animated fadeIn">
		<template v-if="!loading && hasData && $can('orders', 'refund')">
			<b-row v-if="step !== 'confirm'">
				<b-col
					cols="12"
					md="6">
					<h5 class="font-weight-bold mb-0">
						{{ translate('order_id') }}: {{ data.attributes.order_info.order_id }}
					</h5>
				</b-col>
				<b-col
					cols="12"
					md="6">
					<h5
						class="font-weight-bold mb-0"
						:class="['sm','xs'].includes(windowWidth) ? '' : 'float-right'">
						{{ translate('invoice_no') }} {{ data.attributes.order_info.invoice_no }}
					</h5>
				</b-col>
			</b-row>
			<hr v-if="step !== 'confirm'">
			<b-row>
				<b-col>
					<h5
						v-if="step === 'initial'"
						class="font-weight-bold">
						{{ translate('select_refund_type') }}:
					</h5>
				</b-col>
			</b-row>
			<div class="card border-0">
				<b-card-body class="p-0">
					<template v-if="step === 'initial'">
						<b-row>
							<b-col
								cols="12"
								md="3">
								<b-form-group>
									<b-form-radio
										v-for="(item) in availableRefundOptions"
										:key="item"
										v-model="form.refund_type"
										:value="item"
										name="refund_options">
										{{ translate(item) }}
									</b-form-radio>
								</b-form-group>
							</b-col>
							<b-col
								v-if="form.refund_type"
								class="col-12 col-md-8 border-left h-100 animated fadeIn mb-2">
								{{ translate(`${form.refund_type}_info`) }}
							</b-col>
						</b-row>
					</template>

					<template v-if="step === 'overview'">
						<h5>
							{{ translate('items_to_refund') }}:
						</h5>
						<div
							v-for="[orderId, products] in Object.entries(sections)"
							:key="orderId">
							<h6 v-if="data.attributes.sub_order_status[orderId]">
								{{ translate('refund_sub_order_title', { status: translate(data.attributes.sub_order_status[orderId]), orderId }) }}
							</h6>
							<div v-if="data.attributes.sub_order_status[orderId] === 'backorder'">
								<label for="refundBackorder">
									{{ translate('refund_backorder') }}
									<i
										v-b-tooltip.hover.right
										:title="translate('refund_backorder_description')"
										class="fas fa-question-circle text-muted ml-1" />
								</label>
								<switch-toggle
									id="refundBackorder"
									:checked="refundBackorder"
									variant="success"
									class="d-block mt-2"
									no-margin
									pill
									@change="handleRefundBackorder($event, orderId, products)" />
							</div>
							<div class="table-responsive">
								<table class="table mb-0 table-bordered table-hover table-striped">
									<thead>
										<tr class="bg-gray">
											<th class="text-center text-nowrap width-150">
												{{ translate('image') }}
											</th>
											<th class="text-center text-nowrap">
												{{ translate('product') }}
											</th>
											<th class="text-center text-nowrap">
												{{ translate('sku') }}
											</th>
											<th
												class="text-center text-nowrap"
												:class="['sm','xs'].includes(windowWidth) ? '' : 'max-width-75'">
												{{ translate('qty_available') }}
											</th>
											<th
												class="text-center text-nowrap"
												:class="['sm','xs'].includes(windowWidth) ? '' : 'max-width-75'">
												{{ translate('qty_to_refund') }}
											</th>
										</tr>
									</thead>
									<tbody class="overview-table">
										<tr
											v-for="(item, index) in products"
											:key="`${index}_products`">
											<td class="align-middle text-center">
												<p class="mb-0">
													<img
														:src="item.image"
														class="img-fluid w-25">
												</p>
											</td>
											<td class="align-middle text-center">
												<p class="mb-0">
													{{ item.item }}
												</p>
											</td>
											<td class="align-middle text-center">
												<p class="mb-0">
													{{ item.sku }}
												</p>
											</td>
											<td class="align-middle text-center">
												<p class="mb-0">
													{{ item.qty_available }}
												</p>
											</td>
											<td class="align-middle text-center">
												<input
													min="0"
													:disabled="item.qty_available === 0 || data.attributes.sub_order_status[orderId] === 'backorder'"
													:max="item.qty_available"
													:value="refundItems[orderId][item.sku] || refundItems[orderId][item.sku] === 0 ? refundItems[orderId][item.sku] : item.qty_available"
													type="number"
													class=" mx-auto text-center form-control"
													:class="['sm','xs'].includes(windowWidth) ? 'w-50' : 'w-25'"
													@change="updateQty(orderId, item.sku, parseInt($event.target.value, 10))">
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</template>

					<template v-if="['confirm_reason', 'confirm'].includes(step)">
						<b-row>
							<b-col
								cols="12"
								md="7"
								class="mb-2">
								<b-card
									border-variant="gray"
									header-bg-variant="gray"
									header-text-variant="white">
									<template #header>
										<h5 class="text-white text-weight-bold mb-0">
											{{ translate('refund_details') }}
										</h5>
									</template>
									<b-card-text>
										<div class="row">
											<div class="col-6">
												<h5 class="font-weight-bold mb-0">
													{{ translate(form.refund_type) }}
												</h5>
											</div>
										</div>
										<hr>
										<b-row>
											<b-col
												cols="12"
												md="6">
												<h6 class="font-weight-bold mb-0">
													{{ translate('order_id') }}: {{ data.attributes.order_info.order_id }}
												</h6>
											</b-col>
											<b-col
												cols="12"
												md="6">
												<h6
													class="font-weight-bold mb-0"
													:class="['sm','xs'].includes(windowWidth) ? '' : 'float-right'">
													{{ translate('invoice_no') }} {{ data.attributes.order_info.invoice_no }}
												</h6>
											</b-col>
										</b-row>
										<hr>
										<div class="row my-2">
											<div class="col-12 col-md-3">
												<span class="font-weight-bold">{{ translate('refund_description') }}:</span>
											</div>
											<div class="col-12 col-md-9 text-justify">
												{{ translate(`${form.refund_type}_info`) }}
											</div>
										</div>
										<hr v-if="form.refund_type !== 'chargeback'">
										<div class="row my-2">
											<div class="col-12 col-md-3">
												<span class="font-weight-bold">{{ translate('reason') }}: </span>
												<span class="text-danger">*</span>
											</div>
											<div class="col-12 col-md-9 text-justify">
												<span v-if="isLastStep">
													{{ refundReason ? translate(refundReasons[refundReason - 2].attributes.code_name) : '' }}
												</span>
												<select
													v-else
													id="selectReason"
													v-model="refundReason"
													name="reason"
													class="form-control">
													<option :value="undefined">
														{{ translate('select_reason') }}
													</option>
													<option
														v-for="reason in refundReasons"
														:key="reason.attributes.code_name"
														:value="reason.id">
														{{ translate(reason.attributes.code_name) }}
													</option>
												</select>
											</div>
										</div>
										<div class="row my-2">
											<div class="col-12 col-md-3">
												<span class="font-weight-bold">{{ translate('suspend_user') }}: </span>
												<span class="text-danger">*</span>
											</div>
											<div class="col-12 col-md-9 text-justify">
												<span v-if="isLastStep">
													{{ translate(suspendUser ? 'yes' : 'no_word') }}
												</span>
												<select
													v-else
													v-model="suspendUser"
													name="suspend_user"
													class="form-control">
													<option :value="undefined">
														{{ translate('no_word') }}
													</option>
													<option value="1">
														{{ translate('yes') }}
													</option>
												</select>
											</div>
										</div>
										<div class="row my-2">
											<div class="col-12 col-md-3">
												<span class="font-weight-bold m-0">{{ translate('notes') }}: </span>
											</div>
											<div class="col-12 col-md-9 text-justify pt-1">
												<span v-if="isLastStep">
													{{ notes }}
												</span>
												<b-form-textarea
													v-else
													id="notes"
													v-model="notes"
													no-resize
													class="swal2-textarea m-0"
													:placeholder="translate('notes')"
													style="display: flex" />
											</div>
										</div>
										<hr v-if="form.refund_type !== 'chargeback'">
										<div
											v-if="form.refund_type !== 'chargeback'"
											class="row">
											<div class="col-12 mb-3">
												<span class="font-weight-bold">{{ translate('items_to_refund') }}: </span>
											</div>
											<div
												v-for="[orderId, products] in Object.entries(sections)"
												:key="orderId"
												class="col-12">
												<h6 v-if="data.attributes.sub_order_status[orderId]">
													{{ translate(data.attributes.sub_order_status[orderId]) }}
												</h6>
												<div class="table-responsive">
													<table class="table mb-0 table-bordered table-hover table-striped">
														<thead>
															<tr class="bg-dark">
																<th class="text-center">
																	{{ translate('product') }}
																</th>
																<th class="text-center">
																	{{ translate('sku') }}
																</th>
																<th class="text-center">
																	{{ translate('qty_available') }}
																</th>
																<th class="text-center">
																	{{ translate('qty_to_refund') }}
																</th>
															</tr>
														</thead>
														<tbody>
															<tr
																v-for="(item, index) in products"
																:key="`${index}_products`">
																<td class="align-middle text-center">
																	<p class="mb-0">
																		{{ item.item }}
																	</p>
																</td>
																<td class="align-middle text-center">
																	<p class="mb-0">
																		{{ item.sku }}
																	</p>
																</td>
																<td class="align-middle text-center">
																	<p class="mb-0">
																		{{ item.qty_available }}
																	</p>
																</td>
																<td class="align-middle text-center">
																	<p
																		class="mb-0">
																		{{ itemsQtyToReturn(orderId, item) }}
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</b-card-text>
								</b-card>
							</b-col>
							<b-col
								cols="12"
								md="5"
								class="d-flex align-items-center mb-2 justify-content-center">
								<b-row>
									<b-col
										cols="12"
										class="text-center">
										<h1 class="text-primary display-3">
											<i class="fas fa-exclamation-triangle" />
										</h1>
										<h2 class="font-weight-bold text-primary mb-2">
											{{ translate('refund_attention') }}
										</h2>
										<h4 class="font-weight-bold m-0">
											{{ translate('confirm_refund') }}:
										</h4>
									</b-col>
									<b-col
										cols="12"
										class="text-center">
										<is-loading
											v-if="refundAmountLoading"
											:loading-label="translate('loading')"
											:loading="refundAmountLoading" />
										<div v-else>
											<h4 v-if="form.refund_type !== 'custom'">
												{{ refundAmount }}
											</h4>
											<div
												v-else
												class="align-items-center my-2">
												<input
													:value="form.refundAmount.toString().length ? form.refundAmount.toString() : refundAmountValue"
													type="number"
													class="text-center form-control p-0 h-auto font-h4 w-50 d-inline"
													:class="hasRefundError('refund_amount') ? 'is-invalid' : ''"
													@change="updateCustomQty(parseFloat($event.target.value, 10))">
												<h4 class="d-inline mx-2">
													{{ currency }}
												</h4>
												<br>
												<template v-if="hasRefundError('refund_amount')">
													<span
														v-for="error in refundErrors['refund_amount']"
														:key="error"
														class="custom-invalid-feedback animated fadeIn"
														v-text="error" />
												</template>
											</div>
										</div>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col>
								<div
									class="alert alert-warning my-3 text-justify"
									role="alert">
									<span class="font-weight-bold">{{ translate('confirm_refund_important') }}</span>: {{ translate('confirm_refund_info') }}
								</div>
							</b-col>
						</b-row>
					</template>
					<b-button
						v-if="form.refund_type"
						class="float-right"
						variant="primary"
						:disabled="'confirm' === step && (refundReason === undefined)"
						@click="nextStep()">
						{{ translate( isLastStep ? 'process_refund' : 'next_step') }}
					</b-button>
					<b-button
						v-if="step !== 'initial'"
						class="float-right mr-2"
						variant="secondary"
						@click="previousStep()">
						{{ translate('previous_step') }}
					</b-button>
				</b-card-body>
			</div>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	OrdersDetails, Products, PurchasePayment, PurchaseShipping, PushNotifications, Validations, Grids,
} from '@/translations';
import Order from '@/util/Order';
import { YMD_FORMAT } from '@/settings/Dates';
import {
	TYPES, RESTOCKING_FEE_STEPS, COMPLETE_STEPS, CUSTOM_STEPS, CHARGEBACK_STEPS,
} from '@/settings/OrderRefund';
import WindowSizes from '@/mixins/WindowSizes';
import SwitchToggle from '@/components/Switch/index.vue';

export default {
	name: 'NewRefund',
	components: { SwitchToggle },
	messages: [OrdersDetails, Products, PurchasePayment, PurchaseShipping, PushNotifications, Validations, Grids],
	mixins: [WindowSizes],
	data() {
		return {
			dateFormat: YMD_FORMAT,
			form: {},
			refundItems: {},
			orders: new Order(),
			orderRefunds: new Order(),
			orderRefundAmount: new Order(),
			orderReasons: new Order(),
			alert: new this.$Alert(),
			refundOptions: TYPES,
			step: 'initial',
			isLastStep: false,
			restocking_feeSteps: RESTOCKING_FEE_STEPS,
			completeSteps: COMPLETE_STEPS,
			customSteps: CUSTOM_STEPS,
			chargebackSteps: CHARGEBACK_STEPS,
			finalProducts: {},
			refundReason: undefined,
			suspendUser: undefined,
			notes: '',
			refundBackorder: true,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		sections() {
			try {
				const { products } = this.data.attributes;
				return products.reduce((tables, product) => {
					if (typeof tables[product.sub_order_id] === 'undefined') {
						tables[product.sub_order_id] = [];
						this.$set(this.refundItems, product.sub_order_id, {});
					}
					tables[product.sub_order_id].push(product);
					return tables;
				}, {});
			} catch (e) {
				return [];
			}
		},
		hasData() {
			const response = Object.keys(this.data).length;
			return !!response;
		},
		refundAmountLoading() {
			try {
				return this.orderRefundAmount.data.loading;
			} catch (error) {
				return '';
			}
		},
		refundAmount() {
			try {
				return this.orderRefundAmount.data.response.data.response.refundable_amount;
			} catch (error) {
				return '';
			}
		},
		refundAmountValue() {
			try {
				let qty = this.orderRefundAmount.data.response.data.response.refund_amount_value;
				if (qty.includes(',')) qty = qty.split(',').join('');
				return parseFloat(qty, 10);
			} catch (error) {
				return '';
			}
		},
		currency() {
			try {
				return this.orderRefundAmount.data.response.data.response.currency.code;
			} catch (error) {
				return '';
			}
		},
		availableRefundOptions() {
			const partiallyRefunded = this.data.attributes.partially_refunded;
			if (partiallyRefunded) {
				return this.refundOptions.filter((item) => !['complete', 'chargeback'].includes(item));
			}
			return this.refundOptions;
		},
		refundAmountErrors() {
			try {
				return this.orderRefundAmount.data.errors.errors;
			} catch (error) {
				return '';
			}
		},
		refundReasons() {
			try {
				return this.orderReasons.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasRefundAmountError() {
			return typeof this.refundAmountErrors.items !== 'undefined';
		},
		refundErrors() {
			try {
				return this.orderRefunds.data.errors.errors;
			} catch (error) {
				return '';
			}
		},
	},
	watch: {
		'form.refund_type': function cleanRefundItems() {
			this.refundItems = {};
			this.finalProducts = {};
			this.form.refundAmount = '';
		},
	},
	mounted() {
		this.orders.getDetails(this.$route.params.order_id).then(() => this.orderReasons.refundReason());
	},
	methods: {
		hasRefundError(field) {
			return typeof this.refundErrors[field] !== 'undefined';
		},
		updateQty(orderId, sku, qty) {
			this.refundItems[orderId][sku] = qty;
			this.clearRefundAmountErrors();
		},
		updateCustomQty(value) {
			this.form.refundAmount = value;
			this.clearRefundErrors();
		},
		itemsQtyToReturn(orderId, item) {
			if (item.qty_available === 0) return item.qty_available;
			return this.refundItems[orderId][item.sku] || this.refundItems[orderId][item.sku] === 0 ? this.refundItems[orderId][item.sku] : item.qty_available;
		},
		clearRefundAmountErrors() {
			if (this.hasRefundAmountError) {
				delete this.refundAmountErrors.items;
				this.orderRefundAmount.data.errors.errors = { ...this.refundAmountErrors };
			}
		},
		clearRefundErrors() {
			if (this.hasRefundError('refund_amount') || this.hasRefundError('password')) {
				Object.keys(this.refundErrors).forEach((error) => {
					delete this.refundErrors[error];
				});
				this.orderRefunds.data.errors.errors = { ...this.refundErrors };
			}
		},
		nextStep() {
			this.clearRefundAmountErrors();
			this.clearRefundErrors();
			const steps = this[`${this.form.refund_type}Steps`];
			let index = steps.indexOf(this.step);
			if (index === -1) {
				index = 0;
			} else {
				index += 1;
			}
			const setNextStep = () => {
				if (index >= steps.lastIndex - 1) this.isLastStep = true;
				this.step = steps[index];
			};
			if (this.isLastStep) {
				this.submit();
				return;
			}
			const isCompleteOrChargeback = ['complete', 'chargeback'].includes(this.form.refund_type);
			if (this.step === 'overview' || (this.step === 'initial' && isCompleteOrChargeback)) {
				const sections = {};
				this.data.attributes.products.forEach((item) => {
					const { sku, sub_order_id: orderId } = item;
					if (typeof sections[orderId] === 'undefined') {
						sections[orderId] = {};
					}
					// By default, the value is the qty available
					sections[orderId][sku] = item.qty_available;
					// If the user entered a value, use it
					if (this.refundItems[orderId] && typeof this.refundItems[orderId][sku] !== 'undefined') {
						sections[orderId][sku] = this.refundItems[orderId][sku];
					}
					// If the qty is 0, remove the sku from the sections
					if (sections[orderId][sku] === 0) {
						delete sections[orderId][sku];
					}
				});
				this.finalProducts = { ...sections };
				const payload = {
					refund_type: this.form.refund_type,
					items: sections,
				};
				this.orderRefundAmount.getRefundAmount(this.$route.params.order_id, payload).then(() => {
					if (this.hasRefundAmountError) this.clearRefundAmountErrors();
					setNextStep();
				}).catch(() => {
					if (this.hasRefundAmountError) this.alert.toast('error', this.refundAmountErrors.items[0], { timer: 6000 });
					else this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				});
				return;
			}
			setNextStep();
		},
		previousStep() {
			this.clearRefundAmountErrors();
			this.clearRefundErrors();
			if (this.form.refund_type === 'custom') {
				this.form.refundAmount = '';
			}
			const steps = this[`${this.form.refund_type}Steps`];
			const index = steps.indexOf(this.step);
			this.isLastStep = false;
			if (index !== 0) {
				this.step = steps[index - 1];
				return;
			}
			this.step = 'initial';
		},
		submit() {
			const trans = {
				title: this.translate('refund_order'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			const loadingOptions = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					refund_type: this.form.refund_type,
					password: password.value,
					items: this.finalProducts,
					reason_id: this.refundReason,
					suspend_user: this.suspendUser ? 1 : 0,
					notes: this.notes,
				};
				if (this.form.refund_type === 'custom') {
					payload.refund_amount = this.form.refundAmount.toString().length ? this.form.refundAmount.toString() : this.refundAmountValue;
					payload.refund_shipping_amount = '0';
				}
				this.alert.loading(this.translate('processing_refund'), this.translate('refund_wait'), loadingOptions);
				this.orderRefunds.refund(this.$route.params.order_id, payload).then(() => {
					if (payload.refund_type === 'custom') {
						this.alert.toast('success', this.translate('refund_success', { amount: `${payload.refund_amount} ${this.currency}` }));
					} else this.alert.toast('success', this.translate('refund_success', { amount: this.refundAmount }));
					this.$router.push({ name: 'OrdersDetails', params: { order_id: this.data.attributes.order_info.order_id } });
				}).catch(() => {
					this.alert.close();
					if (this.hasRefundError('password')) this.alert.toast('error', this.refundErrors.password[0], { timer: 6000 });
					else if (!this.hasRefundError('refund_amount')) this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				});
			}).catch(() => {});
		},
		handleRefundBackorder(refund, orderId, products) {
			this.refundBackorder = refund;
			this.refundItems[orderId] = products.reduce((table, item) => {
				table[item.sku] = refund ? item.qty_available : 0;
				return table;
			}, {});
		},
	},
};
</script>
<style scoped>
	.overview-table td {
		padding: 0.25rem !important;
	}
	.font-h4{
		font-size: 1.3125rem;
	}
	.max-width-75{
		max-width: 75px;
	}
	.width-150{
		width: 150px;
	}
</style>
