<!-- eslint-disable vue/no-v-html -->
<template>
	<cart-totals
		:class="{ 'outline': !editingOtherSteps }"
		:loading="cartLoading"
		:totals="cartTotals"
		:small-text-totals="cartSmallTextTotals"
		:cart-items="cartItems"
		:total="cartTotal"
		:total-volume="totalVolume"
		:disable-action="!disclaimer || editingOtherSteps || !cartItems.length || (showBackorderAgreement && !backorderAgreement)"
		:hide-action="hideAction"
		action-classes="btn-lg"
		no-data-label="choose_country_and_products"
		no-data-icon="fas fa-gift"
		no-gutters
		:action-text="selectableFreeProducts.length > 0 ? translate('choose_your_free_products') : ''"
		@action="handleFreeProductsPromotion">
		<free-shipping-progress
			v-if="hasFreeShippingInfo"
			class="mb-3"
			:status="freeShippingInfo.status"
			:current-amount="freeShippingInfo.current_amount"
			:remaining-amount="freeShippingInfo.remaining_amount"
			:free-shipping-at="freeShippingInfo.free_shipping_at" />
		<div
			v-if="applyFreeBottle"
			class="row mt-2">
			<div class="col d-flex justify-content-center">
				<div class="alert alert-green w-100 pl-1">
					<ul class="fa-ul mb-0">
						<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate(freeBottleMessage)" /></li>
					</ul>
				</div>
			</div>
		</div>
		<div
			v-if="!hideDisclaimer"
			class="row">
			<div class="col">
				<disclaimer
					:checked="disclaimer"
					:terms="termsAndPolicies"
					@change="disclaimer = $event" />
			</div>
		</div>
		<div
			v-if="showBackorderAgreement"
			class="row">
			<div class="col">
				<backorder-agreement
					:checked="backorderAgreement"
					@change="backorderAgreement = $event" />
			</div>
		</div>
		<free-products-modal
			:sections-import="selectableFreeProducts"
			:selectable-products.sync="selectableProducts"
			:ok-title="translate('confirm')"
			:cancel-title="translate('go_back')"
			@close="showFreeProductsModal = false"
			@confirm="goSuccess" />
	</cart-totals>
</template>
<script>
import CartTotals from '@/components/Cart/CartTotals';
import { CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, INTERNAL_SERVER_ERROR,
} from '@/settings/Errors';
import { PRE_CONFIRMATION_NOTE_COUNTRIES } from '@/settings/Country';
import { distributor, customer, affiliate } from '@/settings/Roles';
import {
	Purchase as PurchaseMessages,
} from '@/translations';
// import EventBus from '@/util/eventBus';
import Disclaimer from './Disclaimer';
import CommonMix from '../../mixins/Common';
import FreeProductsModal from '@/components/FreeProductsModal';
import EventBus from '@/util/eventBus';
import { CC_PAYMENT_METHOD_BY_COUNTRY } from '@/settings/Purchase';
import FreeShippingProgress from '@/components/FreeShippingProgress/index.vue';
import BackorderAgreement from '@/components/BackorderAgreement/BackorderAgreement.vue';

export default {
	name: 'OrderSummary',
	messages: [PurchaseMessages],
	components: {
		BackorderAgreement,
		FreeShippingProgress,
		CartTotals,
		Disclaimer,
		FreeProductsModal,
	},
	mixins: [CommonMix],
	props: {
		cartId: {
			type: [String, Number],
			default: '',
		},
		freeProductsInfo: {
			type: Array,
			default: () => [],
		},
		cartLoading: {
			type: Boolean,
			default: false,
		},
		cartItems: {
			type: Array,
			default: () => [],
		},
		cartTotals: {
			type: Object,
			default: () => ({}),
		},
		cartSmallTextTotals: {
			type: Array,
			default: () => [],
		},
		freeShippingInfo: {
			type: Object,
			default: () => ({}),
		},
		cartTotal: {
			type: String,
			default: '',
		},
		termsAndPolicies: {
			type: Object,
			default: () => ({}),
		},
		totalVolume: {
			type: String,
			default: '',
		},
		applyFreeBottle: {
			type: Boolean,
			default: false,
		},
		freeBottleMessage: {
			type: String,
			default: '',
		},
		editingOtherSteps: {
			type: Boolean,
			default: false,
		},
		hideAction: {
			type: Boolean,
			default: false,
		},
		hideDisclaimer: {
			type: Boolean,
			default: false,
		},
		paymentMethodName: {
			type: String,
			default: '',
		},
		shippingAddressId: {
			type: Number,
			default: 0,
		},
		walletType: {
			type: String,
			default: '',
		},
		walletPassword: {
			type: String,
			default: '',
		},
		creditCardId: {
			type: Number,
			default: 0,
		},
		billingAddressId: {
			type: Number,
			default: 0,
		},
		cartCountryCode: {
			type: String,
			default: '',
		},
		showBackorderAgreement: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			distributor,
			customer,
			affiliate,
			disclaimer: false,
			idempotencyId: '',
			paymentMethod: {
				name: '',
			},
			showFreeProductsModal: false,
			selectableProducts: {},
			backorderAgreement: false,
		};
	},
	computed: {
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		selectableFreeProducts() {
			try {
				return this.freeProductsInfo.map((info) => info.selectable_products);
			} catch (e) {
				return [];
			}
		},
		hasFreeShippingInfo() {
			if (typeof this.freeShippingInfo !== 'undefined' && this.freeShippingInfo !== null) {
				return Object.keys(this.freeShippingInfo).length > 0;
			}
			return false;
		},
	},
	watch: {
		paymentMethodName(name) {
			this.paymentMethod.name = name;
		},
		showFreeProductsModal(show) {
			EventBus.$emit(show ? 'showFreeProductsModal' : 'hideFreeProductsModal');
		},
	},
	mounted() {
		this.getStartingInfo();
	},
	methods: {
		getStartingInfo() {
			this.idempotencyId = this.createUuid();
		},
		handleFreeProductsPromotion() {
			if (this.selectableFreeProducts.length > 0) {
				this.showFreeProductsModal = true;
			} else {
				this.selectableProducts = {};
				this.goSuccess();
			}
		},
		goSuccess() {
			if (PRE_CONFIRMATION_NOTE_COUNTRIES.includes(this.cartCountryCode)) {
				const alertConfig = {
					icon: 'info',
					title: this.translate('note_title'),
					text: this.translate(`${this.cartCountryCode.toLowerCase()}_note_text`),
					showCancelButton: true,
					confirmButtonText: this.translate('confirm_note_i_agree'),
					reverseButtons: true,
				};
				this.alert.preConfirm(alertConfig, () => {
					this.goSuccessValidated();
				}, () => {});
			} else {
				this.goSuccessValidated();
			}
		},
		goSuccessValidated() {
			this.$emit('purchaseClick');

			const payload = {
				cart_id: this.cartId,
				country: this.cartCountryCode,
				shipping: {
					shipping_address: {
						address_id: this.shippingAddressId,
					},
				},
				payment: {
					payment_method: {
						name: this.paymentMethodName,
						idempotency_id: this.idempotencyId,
					},
				},
				selectable_products: this.selectableProducts,
			};

			if (CREDIT_CARD_FORM_METHODS.includes(this.paymentMethodName)) {
				payload.payment.payment_method.card_id = this.creditCardId;
			} else {
				payload.payment.billing = { address_id: this.billingAddressId };
			}

			if (['cashondelivery'].includes(this.paymentMethodName)) {
				payload.payment.payment_method.wallet_type = this.walletType;
				payload.payment.payment_method.wallet_password = this.walletPassword;
			}

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			try {
				const validation = { ...payload, step: 'all' };
				this.placeGiftOrder(validation).then((response) => {
					// Reload user data in case this purchase reactivated their account
					this.$user.init();

					// Store country to be used in purchase success page
					localStorage.setItem('gift_country', this.cartCountryCode);

					const {
						redirect_url: redirectUrl,
						verification_required: verificationRequired,
						crypto_wallet: cryptoWallet,
					} = response.response;
					// Redirect to instructed page
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}
					// Pass payment method to success page in case we need to display an alert for it
					const routeOptions = {
						name: 'GiftOrderSuccess',
						query: { payment_method: payload.payment.payment_method.name ?? '' },
					};
					// Show verbal verification alert
					if (verificationRequired) {
						routeOptions.query.verification_required = true;
					}
					// Go to crypto payment details
					if (cryptoWallet) {
						routeOptions.query = { wallet_address: cryptoWallet };
					}
					// Go to new route
					this.$router.replace(routeOptions);
					// Close 'Processing request...' alert
					this.alert.close();
				}).catch((error) => {
					this.idempotencyId = this.createUuid();
					if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
						this.$emit('invalidRequest', error);
					}
					if (UNPROCESSABLE.includes(error.status)) {
						let isCatchableError = false;
						const {
							cart_id: cartId,
							payex,
							'shipping.shipping_address.address_id': shippingAddressId,
							'payment.billing.address_id': billingAddressId,
							'payment.payment_method.name': paymentMethodName,
							'payment.payment_method.wallet_password': walletPassword,
							'payment.payment_method.wallet_type': walletType,
							'payment.payment_method.card_id': cardId,
							selectable_products: selectableProducts,
						} = error.errors;
						if (typeof cartId !== 'undefined') {
							isCatchableError = true;
							if (typeof cartId === 'string' || typeof cartId === 'number') {
								this.$emit('cartReplaced', cartId, typeof payex === 'undefined');
							} else {
								let response = '';
								cartId.forEach((item) => { response += `${item} \n`; });
								this.alert.toast('error', response, { timer: 6000 });
								setTimeout(() => {
									this.$emit('cartValidationError');
								}, 6000);
							}
						}
						if (typeof payex !== 'undefined') {
							isCatchableError = true;
							let response = '';
							payex.forEach((item) => { response += `${item} <br>`; });
							this.alert.error(this.translate('something_went_wrong'), response, true);
						}
						if (typeof paymentMethodName !== 'undefined') {
							isCatchableError = true;
							let response = '';
							paymentMethodName.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
						}
						if (typeof selectableProducts !== 'undefined') {
							isCatchableError = true;
							let response = '';
							selectableProducts.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
							setTimeout(() => {
								this.$emit('reloadCart');
							}, 6000);
						}
						const errors = [];
						if (typeof shippingAddressId !== 'undefined') {
							shippingAddressId.forEach((item) => { errors.push(item); });
						}
						if (typeof billingAddressId !== 'undefined') {
							billingAddressId.forEach((item) => { errors.push(item); });
						}
						if (typeof walletPassword !== 'undefined') {
							walletPassword.forEach((item) => { errors.push(item); });
						}
						if (typeof walletType !== 'undefined') {
							walletType.forEach((item) => { errors.push(item); });
						}
						if (typeof cardId !== 'undefined') {
							cardId.forEach((item) => { errors.push(item); });
						}
						this.$emit('purchaseErrors', errors);

						if (error.length) {
							isCatchableError = true;
							this.alert.close();
						}
						if (isCatchableError === false) {
							this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
						}
					} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
						setTimeout(() => { this.$emit('serverError'); }, 2500);
					}
				});
			} catch (error) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn(error);
				}
			}
			return null;
		},
		selectedPaymentMethod() {
			if (this.paymentMethod.name === '') {
				return this.initialInformation.credit_card?.card ? this.creditCardPaymentMethod : '';
			}
			return this.paymentMethod.name;
		},
	},
};
</script>
<style scoped>
.outline {
	outline: 0;
	border-radius: 0.3rem;
	-webkit-box-shadow: 0 0 0 0.2rem #eb772f40;
	box-shadow: 0 0 0 0.2rem #eb772f40; /* eb772f40 */
}
</style>
