<template>
	<!-- :valid-user-types="validUserTypes" -->
	<!-- :valid-device-types="validDeviceTypes" -->
	<highlight-video-data
		:form-import="form"
		:valid-countries="validCountries"
		:categories="categories"
		:highlight-video-import="data"
		:loading="loading"
		:errors-import="errors"
		@requestData="requestUpdateHighlightVideo" />
</template>
<script>
// import {
// USER_TYPES as validUserTypes,
// DEVICE_TYPES as validDeviceTypes,
// } from '@/settings/HighlightVideos';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import { HighlightVideos as messages } from '@/translations';
import HighlightVideo from '@/util/HighlightVideo';
import HighlightVideoData from './components/HighlightVideoData';

export default {
	name: 'UpdateHighlightVideo',
	messages,
	components: {
		HighlightVideoData,
	},
	data() {
		return {
			highlightVideo: new HighlightVideo(),
			alert: new this.$Alert(),
			loading: false,
			form: {
				title: '',
				url: '',
				video_id: '',
				mobile_url: '',
				category_id: '',
				image: '',
				countries: '',
				start_date: '',
				end_date: '',
				shareable: true,
				extra_video_ids: {},
				extra_mobile_urls: {},
				// user_types: '',
				// language: '',
				// device_type: '',
			},
			// validDeviceTypes,
			// validUserTypes,
			validCountries,
			categories: [],
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.highlightVideo.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.highlightVideo.data.errors.errors = newErrors;
			},
		},
		response() {
			try {
				const { data } = this.highlightVideo.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		data: {
			get() {
				try {
					return this.response.data.attributes;
				} catch (error) {
					return {};
				}
			},
			set(payload) {
				this.response.data.attributes = { ...this.response.data.attributes, ...payload };
			},
		},
		routeId() {
			return this.$route.params.highlightVideoId;
		},
	},
	watch: {
		routeId: () => {
			this.highlightVideo.getHighlightVideo(this.routeId);
		},
	},
	created() {
		this.highlightVideo.getHighlightVideo(this.routeId);
	},
	mounted() {
		this.getCategories();
	},
	methods: {
		getCategories() {
			this.loading = true;
			this.highlightVideo.getCategories().then((data) => {
				this.categories = data;
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
		requestUpdateHighlightVideo(payload) {
			this.loading = true;
			this.highlightVideo.updateHighlightVideo(this.routeId, payload).then(() => {
				this.alert.toast('success', this.translate('highlight_video_updated'));
				const query = { categoryId: payload.get('category_id'), language: payload.get('language_code') };
				this.$router.push({ name: 'HighlightVideos', query });
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
	},
};
</script>
