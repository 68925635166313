<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="align-self-end">
		<li class="h-auto border-0 px-0 d-flex pb-3">
			<div class="form-check">
				<input
					id="backorder-agreement"
					v-model="value"
					name="backorder-agreement"
					class="form-check-input"
					type="checkbox"
					:value="value">
				<label
					class="form-check-label"
					for="backorder-agreement"
					v-html="translate('backorder_agreement')" />
			</div>
		</li>
	</div>
</template>
<script>
import { Purchase } from '@/translations';

export default {
	name: 'BackorderAgreement',
	messages: [Purchase],
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			path: '',
			value: false,
		};
	},
	watch: {
		value(newVal) {
			this.$emit('change', newVal);
		},
	},
	created() {
		this.value = this.checked;
	},
};
</script>
