<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<!-- Username -->
					<div
						v-if="admin.includes($user.details().type)"
						class="col-md-4 col-sm-4 col-12">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,customer,affiliate'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<!-- OrderId -->
					<div
						class="col-12"
						:class="admin.includes($user.details().type) ? 'col-md-4 col-sm-4' : 'col-md-6 col-sm-6 '">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
					<!-- Invoice ID -->
					<div
						class="col-12"
						:class="admin.includes($user.details().type) ? 'col-md-4 col-sm-4' : 'col-md-6 col-sm-6 '">
						<div
							class="form-group">
							<label
								for="invoiceId"
								class="label">{{ translate('invoice_id') }}</label>
							<input
								id="invoiceId"
								v-model="filters.invoiceId"
								:placeholder="translate('type_invoice')"
								type="text"
								name="invoiceId"
								class="form-control">
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs
					:key="tabsKey"
					v-model="tabIndex">
					<!-- Move the `key` attribute to the `b-tab` element -->
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<button
								:class="['btn text-left list-group-item', tabIndex === index ? 'nav-link active' : 'text-gray-dark']"
								@click="tabIndex = index">
								{{ translate(tabInfo.translate_key) }}
							</button>
						</template>
						<div
							v-if="tabIndex === index"
							class="pt-2">
							<!-- Render tab content here -->
							<router-view :key="routerViewKey" />
						</div>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>

		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 text-center">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('order_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('invoice_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_type')">-->
							{{ translate('user_type') }}
							<!--<sort field="user_type" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('username')">-->
							{{ translate('username') }}
							<!--<sort field="username" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('full_name')">-->
							{{ translate('ship_to') }}
							<!--<sort field="full_name" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('full_name')">-->
							{{ translate('new_tracking_number') }}
							<!--<sort field="full_name" />-->
						</th>
						<th
							class="border-top-0 text-right">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('total_amount')">-->
							{{ translate('total') }}
							<!--<sort field="total_amount" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('status')">-->
							{{ translate('order_status') }}
							<!--<sort field="status" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('date')">-->
							{{ translate('order_date') }}
							<!--<sort field="date" />-->
						</th>
						<th
							class="border-top-0">
							{{ translate('order_paid_date') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle text-center">
							{{ item.id }}
						</td>
						<td class="align-middle">
							{{ item.attributes.invoice_id }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							<span
								v-if="item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ translate(item.attributes.user_type) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							class="align-middle">
							{{ item.attributes.ship_to }}
						</td>
						<td
							class="align-middle text-left">
							<template
								v-if="item.attributes.tracking.length">
								<span
									:id="`${item.id}-tracking-numbers`"
									class="pointer text-primary">
									{{ translate('view_tracking_numbers') }}
									<i class="fa fa-info-circle" />
								</span>
								<b-popover
									:target="`${item.id}-tracking-numbers`"
									triggers="hover"
									custom-class="order-trackings-popover"
									boundary-padding="0"
									:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'left'">
									<div class="p-1 pb-2">
										<template>
											<div class="pbv-popover">
												<div
													class="table-responsive mb-0">
													<div style="max-height: 285px; overflow-y: auto;">
														<table class="table text-nowrap">
															<thead>
																<tr>
																	<th class="border-top-0">
																		#
																	</th>
																	<th class="border-top-0">
																		{{ translate('shipped_date') }}
																	</th>
																	<th class="border-top-0">
																		{{ translate('tracking_number') }}
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	v-for="(track, trackingIndex) in item.attributes.tracking"
																	:key="track.tracking_number">
																	<td class="align-middle">
																		{{ trackingIndex + 1 }}
																	</td>
																	<td class="align-middle">
																		{{ track.ship_date }}
																	</td>
																	<td class="align-middle">
																		<template>
																			<template
																				v-if="track.tracking_url">
																				<a
																					:href="track.tracking_url"
																					target="_blank">
																					{{ track.tracking_number }}
																				</a>
																			</template>
																			<template v-else-if="track.tracking_number">
																				{{ track.tracking_number }}
																			</template>
																			<template v-else>
																				{{ track.tracking_message }}
																			</template>
																		</template>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</template>
									</div>
								</b-popover>
							</template>
						</td>
						<!--<td
							class="align-middle text-left">
							<template
								v-if="item.attributes.old_tracking.length == 1">
								<span
									v-for="(track) in item.attributes.old_tracking"
									:key="track.tracking_number">
									<template
										v-if="track.tracking_url">
										<a
											:href="track.tracking_url"
											target="_blank">
											{{ track.tracking_number }}
										</a>
									</template>
									<template v-else-if="track.tracking_number">
										{{ track.tracking_number }}
									</template>
								</span>
							</template>
							<template
								v-else-if="item.attributes.old_tracking.length > 1">
								<span
									:id="`${item.id}-old-tracking-numbers`"
									class="pointer text-primary">
									{{ translate('view_tracking_numbers') }}
									<i class="fa fa-info-circle" />
								</span>
								<b-popover
									:target="`${item.id}-old-tracking-numbers`"
									triggers="hover"
									custom-class="order-trackings-popover"
									boundary-padding="0"
									:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'left'">
									<div class="p-1 pb-2">
										<template>
											<div class="pbv-popover">
												<div
													class="table-responsive mb-0">
													<table class="table text-nowrap">
														<div style="max-height: 285px; overflow-y: auto;">
															<thead>
																<tr>
																	<th class="border-top-0">
																		#
																	</th>
																	<th class="border-top-0">
																		{{ translate('tracking_number') }}
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	v-for="(track, trackingIndex) in item.attributes.old_tracking"
																	:key="track.tracking_number">
																	<td class="align-middle">
																		{{ trackingIndex + 1 }}
																	</td>
																	<td class="align-middle">
																		<template>
																			<template
																				v-if="track.tracking_url">
																				<a
																					:href="track.tracking_url"
																					target="_blank">
																					{{ track.tracking_number }}
																				</a>
																			</template>
																			<template v-else-if="track.tracking_number">
																				{{ track.tracking_number }}
																			</template>
																			<template v-else>
																				{{ track.tracking_message }}
																			</template>
																		</template>
																	</td>
																</tr>
															</tbody>
														</div>
													</table>
												</div>
											</div>
										</template>
									</div>
								</b-popover>
							</template>
						</td>-->
						<td class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.date.date ? $moment(item.attributes.date.date).format(dateFormat) : $moment(item.attributes.date).format(dateFormat) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.paid_at ? $moment(item.attributes.paid_at.date).format(dateFormat) : '' }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Grids, Tooltip, TrackingStatus, Backorders, Users,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import DataFilter from '@/components/DataFilter';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import Order from '@/util/Order';
import WindowSizes from '@/mixins/WindowSizes';
import { admin, distributor } from '@/settings/Roles';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Backorders',
	messages: [Grids, Users, Tooltip, TrackingStatus, Backorders],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes],
	data() {
		return {
			orders: new Order(),
			dateFormat: YMDHMS_FORMAT,
			tabIndex: 0,
			tabsKey: 0,
			routerViewKey: '',
			tabs: [
				{
					translate_key: 'all_backorders_tab',
					status: '',
				},
			],
			filters: {},
			admin,
			distributor,
			endpoint,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		errors() {
			return this.orders.data.errors;
		},
		pagination() {
			return this.orders.data.pagination;
		},
		data() {
			try {
				return this.orders.data.response.data.data || [];
			} catch (error) {
				return [];
			}
		},
		hasData() {
			return this.data.length > 0;
		},
	},
	watch: {
		// Watch for tabIndex changes
		tabIndex(newIndex) {
			const { status } = this.tabs[newIndex];
			let statusOption = {};

			// If status is not empty, update the query with the status
			if (status !== this.$route.query.status) {
				if (status) {
					statusOption = { status };
					this.updateQuery(statusOption); // Update the route query with the status
				}
			}

			// Always fetch data for the new tab
			this.getDataFiltered(null, statusOption);
		},
	},
	created() {
		this.showFilters = false;
		this.dontOpenFiltersFor.push('status');
	},
	mounted() {
		const queryStatus = String(this.$route.query.status || '');
		const matchingIndex = this.tabs.findIndex((tab) => tab.status === queryStatus);
		this.tabIndex = matchingIndex !== -1 ? matchingIndex : 0;
		this.tabsKey += 1; // Force re-render if necessary

		const { status } = this.tabs[this.tabIndex];
		this.getDataFiltered(null, { status });
	},
	methods: {
		// Fetch filtered data
		getDataFiltered(page, status) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			if (typeof status === 'object') {
				this.filters = { ...this.filters, ...status };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			Object.keys(options).forEach((item) => {
				if (options[item] === '') {
					delete options[item];
				}
			});

			this.updateQuery(options);
			this.getFiltersfromUrl();
			this.orders.getBackorders();
		},

		// Update the query string in the route
		updateQuery(params) {
			this.$router.push({
				query: {
					...params,
				},
			}).catch(() => {});
		},
	},
};
</script>

<style>
.order-trackings-popover {
	max-width: 600px;
}
.order-trackings-popover > .popover-body {
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.order-trackings-popover {
		max-width: 80%;
	}
}
</style>
