import { render, staticRenderFns } from "./ProductConfiguration.vue?vue&type=template&id=6102af4b&"
import script from "./ProductConfiguration.vue?vue&type=script&lang=js&"
export * from "./ProductConfiguration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports