<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 text-left pointer"
							@click="sortByField('order_id')">
							{{ translate('order_id') }}
							<sort field="order_id" />
						</th>
						<th
							class="border-top-0 text-left pointer"
							@click="sortByField('related_order_id')">
							{{ translate('related_order_id') }}
							<sort field="related_order_id" />
						</th>
						<th
							class="border-top-0 text-left pointer">
							<!-- @click="sortByField('increment_id')"> -->
							{{ translate('invoice_id') }}
							<!-- <sort field="increment_id" /> -->
						</th>
						<th class="border-top-0 text-center">
							{{ translate('division') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('country') }}
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('payment_method') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('sku') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('name') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('price') }} ({{ translate('base_currency') }})
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('price') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('quantity') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('subtotal') }} ({{ translate('base_currency') }})
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('subtotal') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('discount') }} ({{ translate('base_currency') }})
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('discount') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax') }} ({{ translate('base_currency') }})
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_collected') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax_collected') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('tax_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_tax_collected') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_tax_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('fulfillment_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('commission_paid') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('rev_id') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('sales_net_of_tax') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('sales_net_of_tax_and_shipping') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping_rev_per_unit') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total_shipping_revenue') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('cogs_id') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('cost_per_unit') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total_cogs') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="text-left">
							{{ item.id }}
						</td>
						<td class="text-left">
							{{ item.attributes.related_order_id }}
						</td>
						<td class="text-left">
							{{ item.attributes.invoice_id }}
						</td>
						<td class="text-left">
							{{ translate(item.attributes.company) }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country_code.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country_code.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country_code.toLowerCase())">
						</td>
						<td>
							{{ translate(`sales_${item.attributes.payment_method}`) }}
							<span v-if="item.attributes.payment_method === 'free'"> - {{ translate(item.attributes.order_type) }}</span>
						</td>
						<td>
							{{ item.attributes.sku }}
						</td>
						<td>
							{{ translate(item.attributes.code_name) }} {{ item.attributes.is_free ? `(${translate('free')})` : '' }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_price }}
						</td>
						<td class="text-right">
							{{ item.attributes.price }}
						</td>
						<td class="text-center">
							{{ item.attributes.quantity }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_subtotal }}
						</td>
						<td class="text-right">
							{{ item.attributes.subtotal }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_discount }}
						</td>
						<td class="text-right">
							{{ item.attributes.discount }}
						</td>
						<td class="text-right">
							{{ item.attributes.base_tax }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_collected }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax_collected }}
						</td>
						<td class="text-right">
							{{ item.attributes.tax_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_tax_collected }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_tax_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.fulfillment_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.commission_paid }}
						</td>
						<td class="text-right">
							{{ item.attributes.total }}
						</td>
						<td>
							{{ item.attributes.rev_name }}
						</td>
						<td class="text-right">
							{{ item.attributes.sales_net_of_tax }}
						</td>
						<td class="text-right">
							{{ item.attributes.sales_net_of_tax_and_shipping }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping_rev_per_unit }}
						</td>
						<td class="text-right">
							{{ item.attributes.total_shipping_revenue }}
						</td>
						<td>
							{{ item.attributes.cogs_id }}
						</td>
						<td class="text-right">
							{{ item.attributes.cost_per_unit }}
						</td>
						<td class="text-right">
							{{ item.attributes.total_cogs }}
						</td>
					</tr>
					<tr class="text-right">
						<td
							colspan="10">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.price }}</b>
						</td>
						<td class="text-center">
							<b>{{ totals.quantity }}</b>
						</td>
						<td />
						<td class="text-right">
							<b>{{ totals.subtotal }}</b>
						</td>
						<td />
						<td class="text-right">
							<b>{{ totals.discount }}</b>
						</td>
						<td />
						<td class="text-right">
							<b>{{ totals.tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_collected }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax_collected }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_tax_collected }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping_tax_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.fulfillment_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.commission_paid }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
						<td>
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.sales_net_of_tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.sales_net_of_tax_and_shipping }}</b>
						</td>
						<td class="text-right">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.total_shipping_revenue }}</b>
						</td>
						<td colspan="2">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.total_cogs }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	MDY_FORMAT as mdyFormat, YMDHMS_FORMAT,
} from '@/settings/Dates';
import {
	Report, Grids, SalesPaymentMethods, Countries, OrdersDetails, Products,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';
import { DEFAULT_COUNTRY_CURRENCY } from '@/settings/Country';

export default {
	name: 'OrdersProductInformation',
	messages: [Report, Grids, SalesPaymentMethods, Countries, OrdersDetails, Products],
	mixins: [FiltersParams],
	data() {
		return {
			sales: new Sales(),
			dateFormat: YMDHMS_FORMAT,
			defaultCurrency: DEFAULT_COUNTRY_CURRENCY,
		};
	},
	computed: {
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		pagination() {
			return this.sales.data.pagination;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';
		this.sales.getSalesOrdersProductInformation().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.$router.push({ name: this.$route.name, query: options });
		},
	},
};
</script>
