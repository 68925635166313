<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('payment_method') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate('select_payment_method') }}
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-8">
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
									class="btn-group-lg payment-method"
									role="group">
									<b-button
										v-for="item in paymentMethods"
										:key="item.id"
										:variant="paymentMethodSelected === item.attributes.method_code_name ? 'primary' : 'light'"
										@click="paymentMethodSelected = item.attributes.method_code_name">
										{{ translate(item.attributes.method_code_name) }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate('billing_information') }}
								</div>
							</div>
						</div>
						<div class="row m-3">
							<div
								v-if="['purchaseorder', 'purchaseorderintl'].includes(paymentMethodSelected)"
								class="col-12">
								<b-alert show>
									{{ translate('billing_disclaimer') }}
								</b-alert>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate( addAddress ? 'add_new_address' : 'select_your_address') }}
								</div>
							</div>
						</div>
						<div class="row m-3">
							<div class="col-12 address-list">
								<address-radio-button
									:address-data="addressData"
									:address-loading="addressLoading"
									:form-import="form"
									:hide-new-address="addAddress"
									:display-add-new-address="false"
									:borderless="true"
									@add-address="addressFormState" />
								<div
									v-if="!addAddress && !addressLoading"
									class="row">
									<div class="col-12">
										<hr class="mt-0">
									</div>
								</div>
								<div
									v-if="addAddress && !addressLoading"
									class="pt-4">
									<address-country-form
										address="billing"
										:market-country-code="$user.details().country.iso_code_2"
										:user-country="true"
										:countries="countries"
										:states="states"
										:form-import="form"
										:errors-import="addressErrors.errors"
										:prefix-errors="''"
										:display-cancel-button="false"
										:display-submit-button="false"
										:display-goback-button="false"
										:display-override-toggle="showOverrideValidation"
										:label-cancel-button="translate('see_address_list')" />
								</div>
							</div>
						</div>
						<div
							v-if="!addressLoading"
							class="row m-3">
							<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
								<button
									v-if="addAddress && hasAddressData"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
									class="btn btn-link b-4 float-left"
									@click="addressFormState">
									{{ translate('see_address_list') }}
								</button>
								<button
									v-else-if="!addAddress"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
									class="btn btn-link b-4 float-left"
									@click="addressFormState">
									{{ translate('use_another_address') }}
								</button>
							</div>
							<div
								v-if="addAddress"
								class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
								<button
									:disabled="(!hasAddressData && !addAddress)"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
									class="btn btn-outline-primary b-4 float-right"
									@click="newAddress(form)">
									{{ translate('save_address_button') }}
								</button>
							</div>
						</div>
						<div class="row m-3">
							<div
								v-if="(typeof validationErrors[`${steps[$route.name].validation}.billing.address_id`] !== 'undefined')"
								id="address"
								class="col-12">
								<b-alert
									variant="danger"
									show>
									<div
										v-for="error in validationErrors[`${steps[$route.name].validation}.billing.address_id`]"
										:key="error">
										<span
											v-text="error" />
										<br>
									</div>
								</b-alert>
							</div>
						</div>
					</li>
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<li
							v-show="isCardPaymentMethod"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('cc_information') }}
									</div>
								</div>
							</div>
							<div class="row mx-2">
								<div
									:class="['xs'].includes(windowWidth) ? 'p-0' : ''"
									class="col-12">
									<template v-for="(item, index) in displayedAllowedCC">
										<img
											:key="index"
											:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
											align="right"
											class="text-right mr-2 resize-img">
									</template>
								</div>
							</div>
							<div class="row m-3">
								<div class="col">
									<div class="form-group">
										<form class="w-100">
											<div class="form-group">
												<label for="cc-v2-name">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
												<span
													id="cc-v2-name"
													:class="lacoreV2CreditCardHasErrors('name') ? 'is-invalid' : ''"
													class="form-field form-control" />
												<template v-if="lacoreV2CreditCardHasErrors('name')">
													<p
														v-for="error in lacoreV2CreditCardErrors.name.errorMessages"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger mb-0"
														v-text="error" />
												</template>
											</div>
											<div class="form-group">
												<label for="cc-v2-number">{{ translate('card_number') }} <span class="text-danger">*</span></label>
												<span
													id="cc-v2-number"
													:class="lacoreV2CreditCardHasErrors('number') ? 'is-invalid' : ''"
													class="form-field form-control" />
												<template v-if="lacoreV2CreditCardHasErrors('number')">
													<p
														v-for="error in lacoreV2CreditCardErrors.number.errorMessages"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger mb-0"
														v-text="error" />
												</template>
											</div>
											<div class="row">
												<div class="form-group col-12 col-md-4">
													<label for="cc-v2-expiration-month">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
													<span
														id="cc-v2-expiration-month"
														:class="lacoreV2CreditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="lacoreV2CreditCardHasErrors('expiration_month')">
														<p
															v-for="error in lacoreV2CreditCardErrors.expiration_month.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
												<div class="form-group col-12 col-md-4">
													<label for="cc-v2-expiration-year">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
													<span
														id="cc-v2-expiration-year"
														:class="lacoreV2CreditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="lacoreV2CreditCardHasErrors('expiration_year')">
														<p
															v-for="error in lacoreV2CreditCardErrors.expiration_year.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
												<div class="form-group col-12 col-md-4">
													<label for="cc-v2-cvc">{{ translate('cvv') }} <span class="text-danger">*</span></label>
													<span
														id="cc-v2-cvc"
														:class="lacoreV2CreditCardHasErrors('security_code') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="lacoreV2CreditCardHasErrors('security_code')">
														<p
															v-for="error in lacoreV2CreditCardErrors.security_code.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</li>
						<!-- Cash paymets disabled in Renewal flow as of Nov. 2021, since cash payments require a minimum of $400 USD -->
						<!-- <li
							v-show="paymentMethodSelected === 'checkmo'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('cash_payment') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12">
									<b-alert
										show
										variant="warning"
										class="h5">
										<b>{{ translate('important_note').toUpperCase() }}</b>: {{ translate('international_wires_only') }}
									</b-alert>
									<b-alert
										show
										variant="info"
										class="text-normal"
										v-html="translate('cash_payments_details', { displayedLink: companyEmail, supportLink: companyEmail })" />
									<b-alert
										show
										variant="warning">
										{{ translate('cash_payments_disclaimer') }}
									</b-alert>
								</div>
							</div>
						</li> -->
						<li
							v-if="paymentMethodSelected === 'cashondelivery'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('wallet_information') }}
									</div>
								</div>
							</div>
							<div class="row mx-3">
								<div class="col">
									<div
										v-if="!loadingWalletBalance && balance.hasOwnProperty(wallet.type)"
										class="h4 text-right mb-0">
										{{ translate('current_balance') }}: {{ balance[wallet.type] }}
									</div>
								</div>
							</div>
							<div
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div class="col-12">
									<input-text
										:id="`${steps[$route.name].validation}.wallet_password`"
										:name="`${steps[$route.name].validation}.wallet_password`"
										:required="true"
										:label="translate('wallet_password')"
										:setter-value="wallet.password"
										:errors="validationErrors[`${steps[$route.name].validation}.payment_method.wallet_password`]"
										type="password"
										@dataChanged="wallet.password = $event" />
								</div>
							</div>
							<div
								v-if="typeof validationErrors[`${steps[$route.name].validation}.payment_method.wallet_type`] !== 'undefined'"
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div
									:id="`${steps[$route.name].validation}.payment_method.wallet_type`"
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12">
									<b-alert
										variant="danger"
										show>
										<div
											v-for="error in validationErrors[`${steps[$route.name].validation}.payment_method.wallet_type`]"
											:key="error">
											<span
												v-text="error" />
											<br>
										</div>
									</b-alert>
								</div>
							</div>
						</li>
						<li
							v-show="paymentMethodSelected === 'banktransfer'"
							class="list-group-item">
							<div
								v-if="paymentMethods.length === 1"
								class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('banktransfer') }}
									</div>
								</div>
							</div>
							<b-row class="row">
								<b-col>
									<b-alert
										show
										variant="info"
										v-html="translate('banktransfer_details')" />
								</b-col>
							</b-row>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										show
										variant="warning">
										{{ translate('banktransfer_disclaimer') }}
									</b-alert>
								</b-col>
							</b-row>
						</li>
						<li
							v-show="paymentMethodSelected === 'oxxopay'"
							class="list-group-item">
							<div
								v-if="paymentMethods.length === 1"
								class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('oxxopay') }}
									</div>
								</div>
							</div>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										show
										variant="info"
										v-html="translate('oxxopay_details')" />
									<b-alert
										show
										variant="warning"
										v-html="translate('oxxopay_details_place_holder', { days: limitTransferDays })" />
								</b-col>
							</b-row>
						</li>
						<li
							v-show="paymentMethodSelected === 'lptpaypal'"
							class="list-group-item">
							<div
								v-if="paymentMethods.length === 1"
								class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('lptpaypal') }}
									</div>
								</div>
							</div>
							<b-row class="row mb-3">
								<b-col>
									<b-alert
										show
										variant="info"
										v-html="translate('lptpaypal_details_purchase')" />
								</b-col>
							</b-row>
						</li>
					</form>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="validating || !!preparing || addAddress"
						variant="primary"
						size="lg"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue_to_confirm') }}
					</b-button>
				</div>
			</div>
		</div>
		<same-address-as-card-statement-modal
			v-model="showSameAddressAsCardStatementModal"
			:country-to-check="country"
			:card-type="selectedCardInfo.card_type"
			:last-digits="selectedCardInfo.last_digits"
			:address="selectedCardInfo.address"
			@confirm="savePaymentStep()" />
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import {
	CREDIT_CARD_FORM_METHODS, ALLOWEDCC_BY_COUNTRY,
} from '@/settings/CreditCard';
import { LIMIT_OXXO_TRANSFER_DAYS } from '@/settings/Purchase';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	// UNPROCESSABLE,
	SYSTEM_ALERT,
} from '@/settings/Errors';
// import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import CreditCardFormV2 from '@/mixins/CreditCardFormV2';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import InputText from '@/components/InputText';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import Wallets from '@/util/Wallets';
import CommonMix from './mixins/Common';
import { NO_DAY_LIMIT_CASH_PAYMENT } from '@/settings/Country';
import SameAddressAsCardStatementModal from '@/components/SameAddressAsCardStatementModal/index.vue';

export default {
	name: 'RenewalPaymentMethod',
	components: {
		SameAddressAsCardStatementModal,
		AddressRadioButton,
		AddressCountryForm,
		InputText,
	},
	mixins: [CommonMix, CreditCardFormV2, WindowSizes],
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			state: new Country(),
			countryIso: new Country(),
			paymentMethodData: new Cart(),
			paymentMethodSelected: 0,
			wallets: new Wallets(),
			wallet: {
				type: '',
				password: '',
			},
			walletTypes: WALLET_TYPES,
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				address_id: '',
			},
			paymentForm: {},
			allowAnotherCountry,
			alert: new this.$Alert(),
			WindowSizes,
			// companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			// addressVerificationCountries,
			creditCardErrors: {},
			paymentToken: null,
			showOverrideValidation: false,
			addressBook: new AddressBook(),
			createAddress: new AddressBook(),
			loadAddress: new AddressBook(),
			addAddress: false,
			limitTransferDays: LIMIT_OXXO_TRANSFER_DAYS,
			cashNoDayLimitcountries: NO_DAY_LIMIT_CASH_PAYMENT,
			validation: {},
			showSameAddressAsCardStatementModal: false,
		};
	},
	computed: {
		selectedCardInfo() {
			// eslint-disable-next-line camelcase
			return this.validation?.card_data ?? {};
		},
		isCardPaymentMethod() {
			return CREDIT_CARD_FORM_METHODS.includes(this.paymentMethodSelected);
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		paymentMethods() {
			try {
				const { data } = this.paymentMethodData.data.response.data;
				return data.filter((paymentMethod) => !['checkmo', 'sezzle'].includes(paymentMethod.attributes.method_code_name));
			} catch (error) {
				return [];
			}
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.total_balance;
			} catch (error) {
				return [];
			}
		},
		loadingWalletBalance() {
			return !!this.wallets.data.loading;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
	},
	watch: {
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId(value) {
			this.clearValidationError('shipping.shipping_address.address_id');
			if (value.length || !!value) {
				this.setAddressInfo(value);
			}
		},
	},
	mounted() {
		this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry(), flow: 'renewal' }).then(() => {
			if (this.paymentMethodSelected === 0) {
				this.paymentMethodSelected = this.paymentMethods[0].attributes.method_code_name;
			}
			this.wallet.type = this.walletTypes.commission;
			this.wallets.getBalance();
		}).finally(() => {
			this.lacoreV2FormSetup();

			// TODO: Set the VGSCollect.create callback to get rid of this timeout
			// https://www.verygoodsecurity.com/docs/vgs-collect/js/integration
			let timeout = 1000;
			if (this.isCardPaymentMethod) {
				timeout = 3000;
			}

			// Waiting for LP Form
			this.preparing = true;
			setTimeout(() => {
				this.preparing = false;
			}, timeout);
		});
	},
	created() {
		this.countryData.getCountries();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
		this.initializeAddress();
		this.getStoredInformation();
		return null;
	},
	methods: {
		handleContinue() {
			if (this.isCardPaymentMethod) {
				this.showSameAddressAsCardStatementModal = true;
			} else {
				this.savePaymentStep();
			}
		},
		savePaymentStep() {
			this.saveStep(this.validation);
		},
		initializeAddress() {
			this.addressBook.getAddresses(this.$user.details().id).then((response) => {
				if (this.form.address_id.length === 0 && response.length) this.form.address_id = response[0].id;
			});
		},
		setAddressInfo(addressId) {
			this.loadAddress.getAddress(this.$user.details().id, addressId).then((response) => {
				this.form.first_name = response.attributes.first_name;
				this.form.last_name = response.attributes.last_name;
				this.form.address = response.attributes.address;
				this.form.address2 = response.attributes.address2;
				this.form.city = response.attributes.city;
				this.form.postcode = response.attributes.postcode;
				this.form.country_code = response.attributes.country.code;
				this.form.region_id = response.attributes.region.id;
			}).catch(() => {
				this.form.address_id = '';
				this.initializeAddress();
			});
		},
		getStoredInformation() {
			const { payment } = { ...this.getStepInformation() };
			if (typeof payment !== 'undefined' && typeof payment.payment_method !== 'undefined') {
				this.paymentMethodSelected = payment.payment_method.name;
				if (this.paymentMethodSelected === 'cashondelivery') {
					this.wallet.type = payment.payment_method.wallet_type;
				}
			}
			try {
				const { billing: data } = payment;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				//
			}
			return null;
		},
		async prepareForStorage() {
			this.preparing = true;
			const billing = { address_id: this.form.address_id };
			this.validation = { step: this.steps[this.$route.name].validation };

			const paymentMethod = { name: this.paymentMethodSelected };

			if (this.isCardPaymentMethod) {
				await this.lacoreV2HandleResponse();
				paymentMethod.token = this.lacoreV2PaymentToken;
			}

			if (this.paymentMethodSelected === 'cashondelivery') {
				const wallet = { ...this.wallet };
				paymentMethod.wallet_type = wallet.type;
				paymentMethod.wallet_password = wallet.password;
			}

			if (['oxxopay', 'sezzle', 'lptpaypal'].includes(this.paymentMethodSelected)) {
				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;
			}

			const payment = { billing, payment_method: paymentMethod };
			this.validation = { ...this.validation, payment };
			this.validateStep(this.validation).then((response) => {
				const { cart_id: cartId } = response.response;
				this.saveCart(cartId).then(() => {
					this.validation = { ...this.validation, card_data: { ...response.response }, cart_id: cartId };
					if (!this.lacoreV2Failed) {
						this.handleContinue();
					}
				});
			}).catch(() => {
				if (typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`] !== 'undefined' && !this.lacoreV2Failed) {
					this.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`];
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			} else {
				this.setAddressInfo(this.form.address_id);
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.showOverrideValidation = false;
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			payload.address_type = 'billing';
			// if (!this.showOverrideValidation) {
			// 	payload.override_validation = undefined;
			// }

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				});

				this.addAddress = !this.addAddress;
			}).catch(() => {
				// if (UNPROCESSABLE.includes(this.addressErrors.status)) {
				// 	Object.keys(this.addressErrors.errors).forEach((errorKey) => {
				// 		if (errorKey === 'suggestion') {
				// 			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				// 				let address = '';
				// 				Object.keys(suggestionResponse.response.address).forEach((item, index) => {
				// 					if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
				// 						address += `${suggestionResponse.response.address[item]}`;
				// 						if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
				// 							address += ', ';
				// 						}
				// 					}
				// 				});
				// 				const trans = {
				// 					title: this.translate('invalid_address'),
				// 					text: this.translate('suggestion_address', { address }),
				// 				};
				// 				const options = {
				// 					config: {
				// 						icon: 'error',
				// 						showCancelButton: true,
				// 						confirmButtonText: this.translate('accept_sugestion'),
				// 						cancelButtonText: this.translate('manual_change'),
				// 						reverseButtons: true,
				// 						allowOutsideClick: false,
				// 						allowEscapeKey: false,
				// 					},
				// 				};
				// 				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				// 					Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
				// 						Object.keys(this.form).forEach((formItem) => {
				// 							if (formItem === suggestionItem) {
				// 								this.form[formItem] = suggestionResponse.response.address[suggestionItem];
				// 							}
				// 						});
				// 					});
				// 					this.newAddress(this.form);
				// 				}).catch(() => {});
				// 			}).catch(() => {});
				// 		} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
				// 			this.showOverrideValidation = true;
				// 		}
				// 	});
				// }
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
	},
};
</script>

<style>
	.address-list .card-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
</style>
