<!-- eslint-disable vue/no-v-html -->
<template>
	<b-row>
		<b-col class="col-12">
			<div v-if="!autoshipInformationLoading && autoshipInformationHasData">
				<b-row>
					<!-- Alert to show that carts products are not available for autoship -->
					<b-col>
						<b-alert
							variant="danger"
							class="pb-0 m-0 mb-2"
							:show="autoshipNotAvailableInCart.length > 0">
							<p>
								{{ translate('not_available_header') }}
							</p>
							<ul>
								<li
									v-for="(product, index) in autoshipNotAvailableInCart"
									:key="index">
									{{ translate(product.code_name) }}
								</li>
							</ul>
							<p>
								{{ translate('not_available_footer') }}
							</p>
						</b-alert>
					</b-col>
				</b-row>
				<b-row>
					<b-col
						cols="12"
						md="6"
						class="mb-4">
						<is-loading
							:loading="autoshipProductsLoading && !(!!productsData.length)"
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							size="bg" />
						<b-card
							v-if="!autoshipProductsLoading && !!productsData.length"
							class="h-100">
							<b-card-text>
								<b><h5>{{ translate('select_products') }}</h5></b>
								<autoship-product
									:loading="autoshipLoading || autoshipTotalsLoading"
									:products="productsData"
									:qtys="qtys"
									@save-update="$emit('qtyChange', qtys)" />
							</b-card-text>
						</b-card>
					</b-col>
					<b-col
						class="col-12 col-md-6 mb-4">
						<b-card class="h-100">
							<is-loading
								:loading="autoshipLoading || autoshipTotalsLoading"
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								class="mx-auto"
								size="bg" />
							<template v-if="!autoshipLoading && !autoshipTotalsLoading">
								<b-card-text class="h-100">
									<b-row v-if="hasChosenProducts">
										<b-col
											cols="12">
											<b><h5 class="text-center">{{ translate('order_details') }}</h5></b>
											<div
												v-if="!!autoshipCartProducts.length"
												class="p-2">
												<template v-for="(product, key) in autoshipCartProducts">
													<b-row
														:key="key"
														class="bg-primary py-1 mb-1 rounded">
														<b-col class="col-6 col-md-8">
															<div class="d-flex h-100 align-items-center">
																<p class="text-left py-1 mb-0">
																	<b>
																		{{ product.qty }}
																	</b>
																	x
																	<b>
																		{{ translate(product.code_name) }}
																		<template v-if="product.backorder_info && product.backorder_info.will_backorder">
																			<i
																				v-b-tooltip
																				:title="translate('will_be_backordered')"
																				class="fas fa-exclamation-triangle text-warning text-small" />
																		</template>
																	</b>
																</p>
															</div>
														</b-col>
														<b-col class="col-6 col-md-4">
															<!-- <p class="text-right mb-0"> -->
															<div class="d-flex h-100 align-items-center justify-content-end">
																<p class="text-right py-2 mb-0">
																	<b>{{ product.sub_total }}</b>
																</p>
															</div>
															<!-- <p class="text-right">
																<small class="font-weight-bold">
																	{{ translate('bvs',{qty: product.total_volume}) }}
																</small>
															</p> -->
														</b-col>
													</b-row>
												</template>
											</div>
											<div
												v-if="typeof autoshipTotalsData.attributes !== 'undefined'"
												class="p-2">
												<template v-for="(value, key) in autoshipTotalsData.attributes.totals">
													<b-row
														:key="key"
														:class="positiveValues.includes(key) ? 'font-weight-bold' : ''"
														:style="{ color: positiveValues.includes(key) ? positiveColor : 'default' }">
														<b-col class="col-5">
															<p
																:style="smallTextTotals.includes(key) ? '' : 'font-size:1.1em;'"
																:class="{
																	'text-medium': smallTextTotals.includes(key),
																}"
																class="mb-2 text-left">
																{{
																	key === 'shipping' ? translate('shipping_and_handling') : translate(key)
																}}
															</p>
														</b-col>
														<b-col class="col-7">
															<p
																:style="smallTextTotals.includes(key) ? '' : 'font-size:1.1em;'"
																:class="{
																	'text-medium': smallTextTotals.includes(key),
																}"
																class="mb-2 text-right">
																{{ value }}
															</p>
														</b-col>
													</b-row>
												</template>
											</div>
											<div
												v-if="typeof autoshipTotalsData.attributes !== 'undefined'"
												class="p-2">
												<b-row>
													<b-col class="col-4 text-nowrap">
														<h4 class="text-left">
															<b>{{ translate('total') }}</b>
														</h4>
													</b-col>
													<b-col class="col-8">
														<h4 class="text-right">
															<b>{{ autoshipTotalsData.attributes.total }}</b>
														</h4>
													</b-col>
													<b-col
														v-if="thereIsExchange"
														class="col-12">
														<h6 class="text-right exchange-text">
															{{ autoshipTotalsData.attributes.exchange.total }}*
														</h6>
													</b-col>
												</b-row>
											</div>
										</b-col>
										<b-col
											v-if="thereIsExchange"
											cols="12"
											class="mb-2 d-flex">
											<div class="mx-auto exchange-text text-center small d-flex align-items-end">
												{{ translate('exchange_disclaimer') }}
											</div>
										</b-col>
										<b-col
											v-if="freeShippingInfo"
											cols="12"
											class="mb-2">
											<free-shipping-progress
												:status="freeShippingInfo.status"
												:current-amount="freeShippingInfo.current_amount"
												:remaining-amount="freeShippingInfo.remaining_amount"
												:free-shipping-at="freeShippingInfo.free_shipping_at" />
										</b-col>
									</b-row>
									<b-row v-else>
										<b-col cols="12">
											<b><h5 class="text-center">{{ translate('order_details') }}</h5></b>
											<b-card>
												<b-card-text>
													<p class="text-center text-success pt-3">
														<i class="fa fa-info-circle" />
														{{ translate('no_products_selected') }}
													</p>
												</b-card-text>
											</b-card>
										</b-col>
									</b-row>
								</b-card-text>
							</template>
						</b-card>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</b-row>
</template>
<script>

import Countdown from '@/mixins/Countdown';
import WindowSizes from '@/mixins/WindowSizes';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import {
	AutoshipInformation as Messages, Countries, Products as ProductsMessages, Validations, Profile, Purchase, Dashboard,
} from '@/translations';
import {
	V_CALENDAR_CONFIG as calendarConfig,
} from '@/settings/Dates';
import { POSITIVE_COLOR, POSITIVE_VALUES } from '@/settings/Cart';
import AutoshipProduct from './components/AutoshipProduct.vue';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import FreeShippingProgress from '@/components/FreeShippingProgress/index.vue';

export default {
	name: 'ProductConfiguration',
	messages: [Messages, Countries, Dashboard, ProductsMessages, Validations, Profile, Purchase],
	components: {
		FreeShippingProgress,
		AutoshipProduct,
	},
	mixins: [ConfigurableProducts, EarlyLaunch, Countdown, WindowSizes],
	props: {
		autoshipProductsData: { // Autoship available to choose for autoship
			type: Array,
			default() {
				return [];
			},
		},
		autoshipTotalsData: { // From autoship cart
			type: Object,
			default() {
				return {};
			},
		},
		autoshipInformationData: {
			type: Object,
			default() {
				return {};
			},
		},
		autoshipNotAvailableInCart: {
			type: Array,
			default() {
				return [];
			},
		},
		qtys: {
			type: Object,
			default() {
				return {};
			},
		},
		productsData: { // Products available to choose for autoship formatted
			type: Array,
			default() {
				return [];
			},
		},
		autoshipProductsLoading: {
			type: Boolean,
			default: false,
		},
		autoshipInformationLoading: {
			type: Boolean,
			default: false,
		},
		autoshipTotalsLoading: {
			type: Boolean,
			default: false,
		},
		autoshipLoading: {
			type: Boolean,
			default: false,
		},
		autoshipInformationHasData: {
			type: Boolean,
			default: false,
		},
		freeShippingInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			positiveColor: POSITIVE_COLOR,
			positiveValues: POSITIVE_VALUES,
			calendarConfig,
		};
	},
	computed: {
		smallTextTotals() {
			try {
				return this.autoshipTotalsData.attributes.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		thereIsExchange() {
			const theresExchange = this.autoshipProductsData.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		autoshipChosenProducts() {
			try {
				return this.autoshipInformationData.attributes.products;
			} catch (error) {
				return {};
			}
		},
		hasChosenProducts() {
			return Object.keys(this.autoshipChosenProducts).length > 0;
		},
		autoshipCartProducts() {
			try {
				return this.autoshipTotalsData.attributes.products;
			} catch (error) {
				return {};
			}
		},
	},
};
</script>
