/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import Vue from 'vue';
import Router from 'vue-router';

// Modules
import Users from '@/router/Modules/Users';
import Corporates from '@/router/Modules/Corporates';
import Customers from '@/router/Modules/Customers';
import MediaLibrary from '@/router/Modules/MediaLibrary';
import AutoshipInformation from '@/router/Modules/AutoshipInformation';
import Profile from '@/router/Modules/Profile';
import { Reports, CommissionsReport } from '@/router/Modules/Report';
import Order from '@/router/Modules/Order';
import EventTickets from '@/router/Modules/EventTickets';
import CashPayments from '@/router/Modules/CashPayments';
import OrderVerifications from '@/router/Modules/OrderVerifications';
import Register from '@/router/Modules/Register';
import RegisterOld from '@/router/Modules/RegisterOld';
import BecomeDistributor from '@/router/Modules/BecomeDistributor';
import BecomeAffiliate from '@/router/Modules/BecomeAffiliate';
// import Preregister from '@/router/Modules/Preregister';
// import SoftRegister from '@/router/Modules/SoftRegister';
import Support from '@/router/Modules/Support';
import Checkout from '@/router/Modules/Checkout';
import Purchase from '@/router/Modules/Purchase';
import CheckoutOld from '@/router/Modules/CheckoutOld';
import { RenewalSteps, RenewalMain } from '@/router/Modules/Renewal';
import CanceledAccount from '@/router/Modules/CanceledAccount';
import ForceUsernameChange from '@/router/Modules/ForceUsernameChange';
import Onboarding from '@/router/Modules/Onboarding';
// Home, Login, Forbidden, NotFound, ForgotPassword, CommandPanel, CommonMessages, RegisterAsCustomer,
import {
	Home, Login, Forbidden, NotFound, ForgotPassword, CommandPanel, CommonMessages, ReplicatedSite,
	RegisterAsCustomer, RedirectedRegister, RedirectedPurchase,
} from '@/router/Modules/Pages';
import { Network, SponsoredCustomers } from '@/router/Modules/Network';
import CompleteAffiliateAutoship from '@/router/Modules/CompleteAffiliateAutoship';
import Store from '@/router/Modules/Store';
import Cart from '@/router/Modules/Cart';
import Faq from '@/router/Modules/Faq';
import Announcements from '@/router/Modules/Announcements';
import Banners from '@/router/Modules/Banners';
import HighlightVideos from '@/router/Modules/HighlightVideos';
import Sms from '@/router/Modules/Sms';
import PushNotifications from '@/router/Modules/PushNotifications';
import Coupons from '@/router/Modules/Coupons';
import ProductReviews from '@/router/Modules/ProductReviews';
import Wallets from '@/router/Modules/Wallets';
import Settings from '@/router/Modules/Settings';
import ProductDetails from '@/router/Modules/ProductDetails';
import FreeEventsDetails from '@/router/Modules/FreeEventsDetails';
import Credits from '@/router/Modules/Credits';
import DownloadProduct from '@/router/Modules/DownloadProduct';
import { CryptoPaymentDetails } from '@/router/Modules/CryptoPayments';
import Notifications from '@/router/Modules/Notifications';
import ChangePaymentMethod from '@/router/Modules/ChangePaymentMethod';
import Inventory from '@/router/Modules/Inventory';
import WarehouseOrders from '@/router/Modules/WarehouseOrders';
import UserAnnouncements from '@/router/Modules/UserAnnouncements';
import CustomCarts from '@/router/Modules/CustomCarts';
import CustomPrices from '@/router/Modules/CustomPrices';
import AgencyPromotions from '@/router/Modules/AgencyPromotions';
import GiftOrders from '@/router/Modules/GiftOrders';
import DistributorBusinessAgreements from '@/router/Modules/DistributorBusinessAgreements';
import PrivacyPolicy from '@/router/Modules/PrivacyPolicy';
import AdminDashboard from '@/router/Modules/AdminDashboard';
import MemberRewards from '@/router/Modules/MemberRewards';

// Physical Store
import PSStore from '@/router/Modules/PhysicalStore/Store';
import PSCart from '@/router/Modules/PhysicalStore/Cart';
import PSProductDetails from '@/router/Modules/PhysicalStore/ProductDetails';
import PSPurchase from '@/router/Modules/PhysicalStore/Purchase';
import PSPChangePaymentMethod from '@/router/Modules/PhysicalStore/ChangePaymentMethod';
import { AccountFeeMain, AccountTransferFeeSteps } from '@/router/Modules/AccountTransferFee';

// Agency Site
import AgencyMetaStore from '@/router/Modules/Agency/Meta/Store';
import AgencyMetaProductDetails from '@/router/Modules/Agency/Meta/ProductDetails';
import AgencyMetaCheckout from '@/router/Modules/Agency/Meta/Checkout';
import AgencyMetaCart from '@/router/Modules/Agency/Meta/Cart';
import AgencyMetaCryptoPaymentDetails from '@/router/Modules/Agency/Meta/CryptoPayments';
import AgencyGoogleStore from '@/router/Modules/Agency/Google/Store';
import AgencyGoogleProductDetails from '@/router/Modules/Agency/Google/ProductDetails';
import AgencyGoogleCheckout from '@/router/Modules/Agency/Google/Checkout';
import AgencyGoogleCart from '@/router/Modules/Agency/Google/Cart';
import AgencyGoogleCryptoPaymentDetails from '@/router/Modules/Agency/Google/CryptoPayments';
import GiftOrdersJns from '@/router/Modules/GiftOrdersJns';

// Containers
const DefaultContainer = () => import('@/views/Layouts/Main/DefaultContainer');
Vue.use(Router);

const router = new Router({
	mode: 'history', // https://stackoverflow.com/questions/34623833/vue-js-how-to-remove-hashbang-from-url
	linkActiveClass: 'open active',
	scrollBehavior: () => ({ y: 0 }),
	routes: [
		{
			path: '/',
			redirect: '/store',
			name: 'Main',
			component: DefaultContainer,
			children: [
				Home,
				Users,
				Corporates,
				Customers,
				Profile,
				Reports,
				CommissionsReport,
				Order,
				EventTickets,
				Network,
				SponsoredCustomers,
				Sms,
				PushNotifications,
				MediaLibrary,
				Support,
				AutoshipInformation,
				CashPayments,
				OrderVerifications,
				Faq,
				CommandPanel,
				Announcements,
				Coupons,
				ProductReviews,
				Wallets,
				Settings,
				Credits,
				Banners,
				HighlightVideos,
				Notifications,
				WarehouseOrders,
				Inventory,
				UserAnnouncements,
				CustomCarts,
				CustomPrices,
				AgencyPromotions,
				GiftOrders,
				GiftOrdersJns,
				AdminDashboard,
			],
		},
		Cart,
		Store,
		Login,
		ForgotPassword,
		RegisterAsCustomer,
		// OnboardingProcess,
		CommonMessages,
		// Unavailable,
		Forbidden,
		NotFound,
		ReplicatedSite,
		Register,
		RegisterOld,
		BecomeDistributor,
		BecomeAffiliate,
		Checkout,
		Purchase,
		ChangePaymentMethod,
		CheckoutOld,
		RenewalSteps,
		RenewalMain,
		AccountTransferFeeSteps,
		AccountFeeMain,
		CanceledAccount,
		ForceUsernameChange,
		Onboarding,
		ProductDetails,
		FreeEventsDetails,
		// Preregister,
		// SoftRegister,
		RedirectedRegister,
		RedirectedPurchase,
		DownloadProduct,
		CryptoPaymentDetails,
		DistributorBusinessAgreements,
		MemberRewards,
		PrivacyPolicy,
		// Physical Store Routes,
		PSStore,
		PSProductDetails,
		PSCart,
		PSPurchase,
		PSPChangePaymentMethod,
		// End of Physical Store Routes,
		// Affiliates fill Autoship info
		CompleteAffiliateAutoship,

		// Agency Routes

		// Meta
		AgencyMetaStore,
		AgencyMetaProductDetails,
		AgencyMetaCheckout,
		AgencyMetaCart,
		AgencyMetaCryptoPaymentDetails,

		// Google
		AgencyGoogleStore,
		AgencyGoogleProductDetails,
		AgencyGoogleCheckout,
		AgencyGoogleCart,
		AgencyGoogleCryptoPaymentDetails,

		// End of Agency Routes
	],
});

export default router;
