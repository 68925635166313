import moment from 'moment';
import { QUERY_VARS as noFilterable, PAGINATION } from '@/settings/RequestReply';
import {
	YMD_FORMAT,
	DATE_RANGE_FILTER,
	DATE_START_FILTER,
	DATE_END_FILTER,
	custom,
} from '@/settings/Dates';
import sort from '@/components/SortArrow';

export default {
	components: {
		sort,
	},
	data() {
		return {
			showFilters: false,
			filters: {},
			dateRange: '',
			startDate: null,
			endDate: null,
			noFilterable,
			dontOpenFiltersFor: [],
		};
	},
	created() {
		this.getFiltersfromUrl();
	},
	watch: {
		startDate(newVal) {
			try {
				this.filters[DATE_START_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_START_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_START_FILTER] = null;
			}
		},
		endDate(newVal) {
			try {
				this.filters[DATE_END_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_END_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_END_FILTER] = null;
			}
		},
		dateRange(newVal) {
			this.filters[DATE_RANGE_FILTER] = newVal;
			if (newVal !== custom) {
				this.filters[DATE_START_FILTER] = null;
				this.filters[DATE_END_FILTER] = null;
			}
		},
	},
	methods: {
		getFiltersfromUrl() {
			const { query } = this.$route;
			Object.keys(query).forEach((item) => {
				if (!this.noFilterable.includes(item)) {
					if (!this.dontOpenFiltersFor.includes(item)) {
						this.showFilters = true;
					}
					if (item !== DATE_START_FILTER && item !== DATE_END_FILTER && item !== DATE_RANGE_FILTER) {
						this.filters[item] = query[item];
					} else if (item === DATE_START_FILTER || item === DATE_END_FILTER) {
						this.dateRange = custom;
						this[item] = moment(query[item]).toDate();
					} else {
						this[item] = query[item];
					}
				}
			});
		},
		clearFilters() {
			const { query, name, params } = this.$route;
			if (typeof query[PAGINATION] === 'undefined') {
				query[PAGINATION] = 1;
			}
			Object.keys(this.filters).forEach((item) => {
				if (typeof query[item] !== 'undefined' && !this.noFilterable.includes(item) && !this.dontOpenFiltersFor.includes(item)) {
					delete query[item];
				}
			});
			this.$router.replace('?');
			this.$router.replace({ name, query, params });
		},
	},
};
