<template>
	<div
		v-if="show"
		:class="cardClass"
		class="w-100 h-100 p-3 rounded-bottom">
		<b-row class="d-flex align-items-center h-100">
			<!-- Show autoship info and countdown -->
			<b-col
				v-if="nextAutoshipDate && autoshipActive"
				class="col-12 col-md-6 text-center">
				<div class="d-flex justify-content-center">
					<div
						:class="countdownClass"
						class="mx-1 p-1"
						style="border-radius:10px; width:60px; height: 55px;">
						<h3> {{ days }} </h3>
						<h6
							class="text-uppercase"
							style="margin-top: -7px; font-size:smaller">
							{{ translate('countdown_days') }}
						</h6>
					</div>
					<div
						:class="countdownClass"
						class="mx-1 p-1"
						style="border-radius:10px; width:60px; height: 55px;">
						<h3> {{ hours }} </h3>
						<h6
							class="text-uppercase"
							style="margin-top: -7px; font-size:smaller">
							{{ translate('countdown_hours') }}
						</h6>
					</div>
					<div
						:class="countdownClass"
						class="mx-1 p-1"
						style="border-radius:10px; width:60px; height: 55px;">
						<h3> {{ minutes }} </h3>
						<h6
							class="text-uppercase"
							style="margin-top: -7px; font-size:smaller">
							{{ translate('countdown_minutes') }}
						</h6>
					</div>
				</div>
				<div
					:class="darkTheme ? 'text-light' : 'text-black-50'"
					style="margin-top: 8px">
					{{ translate('autoship_countdown_description') }}
				</div>
				<hr
					v-if="['xs', 'sm'].includes(windowWidth)"
					class="m-1">
			</b-col>

			<!-- Show autoship no active message -->
			<b-col
				v-else
				:class="(['xs', 'sm'].includes(windowWidth) ? 'col-12 text-center ' : 'd-flex align-items-center text-center ').concat('text-uppercase font-weight-bold ').concat(darkTheme ? 'text-light' : 'text-black-50')">
				{{ translate('autoship_disabled') }}
			</b-col>

			<!-- Go to autoship button -->
			<b-col
				:class="(['xs', 'sm'].includes(windowWidth) ? 'col-12 ' : '').concat(!nextAutoshipDate ? 'justify-content-center col-6' : 'justify-content-between col-12')"
				class="col-md-6 d-flex flex-column text-center">
				<!-- Button with autoship activated -->
				<div
					v-if="nextAutoshipDate && autoshipActive"
					class="d-flex justify-content-center align-items-center">
					<div class="text-center mr-3">
						<span
							class="h5"
							:class="darkTheme ? 'text-success' : 'text-info'">
							{{ $moment(nextAutoshipDate.date).format('LL') }}
						</span>
						<div
							class="text-uppercase font-weight-bold"
							:class="darkTheme ? 'text-light' : 'text-black-50'">
							{{ translate('next_autoship_date') }}
						</div>
					</div>
					<button
						v-if="showConfigButton && ($user.details().type === distributor || customerRoles.includes($user.details().type)) && nextAutoshipDate"
						type="button"
						class="btn bg-success"
						@click="$router.push({ name: 'AutoshipInformation' })">
						<i class="fas fa-cog" />
					</button>
				</div>

				<button
					v-if="showConfigButton && ($user.details().type === distributor || customerRoles.includes($user.details().type)) && !nextAutoshipDate"
					type="button"
					class="btn bg-success"
					@click="$router.push({ name: 'AutoshipInformation' })">
					<i class="fas fa-truck" />
					{{ translate('autoship_settings') }}
				</button>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import { customerRoles, distributor } from '@/settings/Roles';
import { Dashboard as DashboardTranslations } from '@/translations';
import Countdown from '@/mixins/Countdown';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'Autoship',
	messages: [DashboardTranslations],
	mixins: [Countdown, WindowSizes],
	props: {
		autoshipActive: {
			type: Boolean,
			default: true,
		},
		nextAutoshipDate: {
			type: Object || null,
			default: null,
		},
		show: {
			type: Boolean,
			default: true,
		},
		darkTheme: {
			type: Boolean,
			default: false,
		},
		showConfigButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			customerRoles,
			distributor,
		};
	},
	computed: {
		cardClass() {
			if (this.darkTheme) {
				return 'bg-dark';
			}
			return 'border border-muted';
		},
		countdownClass() {
			if (this.darkTheme) {
				return 'bg-primary';
			}
			return 'border border-info';
		},
	},
	created() {
		this.countdownToProcessAutoship();
	},
	methods: {
		countdownToProcessAutoship() {
			if (!this.nextAutoshipDate) {
				return;
			}
			this.countdown(this.nextAutoshipDate.date);
		},
	},
};
</script>
