<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		v-if="orderId"
		v-model="modalVisible"
		no-close-on-esc
		no-close-on-backdrop
		:hide-footer="verificationStatus === 'valid'"
		size="lg"
		:ok-title="translate('update')"
		:ok-disabled="orderUpdate.data.loading || loading"
		:title="translate('order_id', { order_id: orderId })"
		@close="$emit('close')"
		@cancel="$emit('close')"
		@ok="update">
		<div>
			<is-loading
				v-if="loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
			<template v-else>
				<b-alert
					show
					variant="warning">
					<div v-html="translate('personal_document_info_notice')" />
				</b-alert>
				<b-row>
					<b-col
						cols="3"
						class="my-2">
						<label for="first_name">
							{{ translate('first_name') }}
						</label>
						<b-input
							id="first_name"
							v-model="form.first_name"
							:class="hasError('first_name') ? 'is-invalid' : ''"
							@input="clearError('first_name')" />
						<template v-if="hasError('first_name')">
							<span
								v-for="error in errors['first_name']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-col>
					<b-col
						cols="3"
						class="my-2">
						<label for="last_name">
							{{ translate('last_name') }}
						</label>
						<b-input
							id="last_name"
							v-model="form.last_name"
							:class="hasError('last_name') ? 'is-invalid' : ''"
							@input="clearError('last_name')" />
						<template v-if="hasError('last_name')">
							<span
								v-for="error in errors['last_name']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-col>
					<b-col
						cols="6"
						class="my-2">
						<label for="personal_document_number">
							{{ translate('id_number') }}
						</label>
						<b-input
							id="personal_document_number"
							v-model="form.personal_document_number"
							:class="hasError('personal_document_number') ? 'is-invalid' : ''"
							@input="clearError('personal_document_number')" />
						<template v-if="hasError('personal_document_number')">
							<span
								v-for="error in errors['personal_document_number']"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</b-col>
					<b-col
						cols="6"
						class="my-2">
						<label for="personal_document_front_file">
							{{ translate(`nin_${countryCode.toLowerCase()}_front`) }}
						</label>
						<template v-if="verificationStatus !== 'valid'">
							<b-form-file
								id="personal_document_front_file"
								v-model="newFrontFile"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
								class="text-truncate"
								:class="hasError('personal_document_front_file') ? 'is-invalid' : ''"
								type="file"
								@change="clearError('personal_document_front_file')" />
							<b-form-text id="personal_document_front_file">
								{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
							</b-form-text>
							<template v-if="hasError('personal_document_front_file')">
								<span
									v-for="error in errors['personal_document_front_file']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</template>
						<br>
						<img
							:src="userImageFront || require('@/assets/images/common/notFoundAvatar.png')"
							width="100%"
							alt="Front side"
							@click="currentImage = userImageFront; showImageViewer = true;">
					</b-col>
					<b-col
						cols="6"
						class="my-2">
						<label for="personal_document_back_file">
							{{ translate(`nin_${countryCode.toLowerCase()}_back`) }}
						</label>
						<template v-if="verificationStatus !== 'valid'">
							<b-form-file
								id="personal_document_back_file"
								v-model="newBackFile"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
								class="text-truncate"
								:class="hasError('personal_document_back_file') ? 'is-invalid' : ''"
								type="file"
								@change="clearError('personal_document_back_file')" />
							<b-form-text id="personal_document_back_file">
								{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
							</b-form-text>
							<template v-if="hasError('personal_document_back_file')">
								<span
									v-for="error in errors['personal_document_back_file']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</template>
						<br>
						<img
							width="100%"
							:src="userImageBack || require('@/assets/images/common/notFoundAvatar.png')"
							alt="Back side"
							@click="currentImage = userImageBack; showImageViewer = true;">
						<image-viewer
							v-model="showImageViewer"
							:image-src="currentImage || require('@/assets/images/common/notFoundAvatar.png')" />
					</b-col>
				</b-row>
			</template>
		</div>
	</b-modal>
</template>

<script>
import Order from '@/util/Order';
import OrderVerification from '@/mixins/OrderVerification';
import {
	OrderVerifications, Users,
	Grids, Tooltip,
	Addresses, NIN,
} from '@/translations';
import ImageViewer from '@/components/ImageViewer/index.vue';
import { IMAGES_EXT } from '@/settings/Media';
import { UNPROCESSABLE } from '@/settings/Errors';

const base64Encode = (data) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(data);
	reader.onload = () => resolve(reader.result);
	reader.onerror = (error) => reject(error);
});

export default {
	name: 'UpdateOrderDocumentModal',
	components: { ImageViewer },
	messages: [
		OrderVerifications, Users, Grids, Tooltip,
		Addresses, NIN,
	],
	mixins: [OrderVerification],
	props: {
		userData: {
			type: Object,
			default: null,
		},
		orderId: {
			type: String,
			default: null,
		},
		firstName: {
			type: String,
			default: null,
		},
		lastName: {
			type: String,
			default: null,
		},
		idNumber: {
			type: String,
			default: '',
		},
		countryCode: {
			type: String,
			default: '',
		},
		verificationStatus: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			modalVisible: true,
			userImageFront: '',
			userImageBack: '',
			currentImage: '',
			orderData: new Order(),
			orderUpdate: new Order(),
			showImageViewer: false,
			form: {
				personal_document_front_file: null,
				personal_document_back_file: null,
				personal_document_number: this.idNumber,
				first_name: this.firstName,
				last_name: this.lastName,
			},
			IMAGES_EXT,
			newFrontFile: null,
			newBackFile: null,
			loading: false,
			errors: {},
		};
	},
	watch: {
		newFrontFile(newImg, oldImg) {
			if (oldImg !== newImg && newImg) {
				base64Encode(newImg)
					.then((value) => {
						this.userImageFront = value;
					})
					.catch(() => {
						this.userImageFront = null;
					});
			}
		},
		newBackFile(newImg, oldImg) {
			if (oldImg !== newImg && newImg) {
				base64Encode(newImg)
					.then((value) => {
						this.userImageBack = value;
					})
					.catch(() => {
						this.userImageBack = null;
					});
			}
		},
	},
	mounted() {
		this.fetchUserImage('front');
		this.fetchUserImage('back');
	},
	methods: {
		update(event) {
			event.preventDefault();
			if (this.newFrontFile) {
				this.form.personal_document_front_file = this.newFrontFile;
			}
			if (this.newBackFile) {
				this.form.personal_document_back_file = this.newBackFile;
			}
			const payload = this.objectToFormData(this.form);

			this.orderUpdate.updateOrderDocument(this.orderId, payload).then((response) => {
				const { message } = response.response;
				this.alert.toast('success', message, { timer: 6000 });
				this.$emit('close');
				this.$emit('reload');
			}).catch((errors) => {
				if (!UNPROCESSABLE.includes(this.orderUpdate.data.errors.status)) {
					const { messageError } = errors;
					this.alert.toast('error', messageError, { timer: 6000 });
				} else {
					this.errors = this.orderUpdate.data.errors.errors;
				}
			}).finally(() => {
			});
		},
		fetchUserImage(side) {
			this.loading = true;
			this.orderData.getDocument(this.orderId, side).then(() => {
				const { response } = this.orderData.data;
				const url = window.URL.createObjectURL(response.data);

				if (side === 'back') {
					this.newBackFile = null; // response.data;
					this.userImageBack = url;
				} else {
					this.newFrontFile = null; // response.data;
					this.userImageFront = url;
				}
			}).finally(() => {
				this.loading = false;
			});
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>

<style scoped>
.custom-file.is-invalid {
	border: 1px solid #f86c6b !important;
}
</style>
