<template>
	<div class="card mb-0 h-100">
		<div
			class="card-body p-3 h-100">
			<b-row class="h-100">
				<b-col class="mb-0 col-6">
					<div
						class="h4 mb-0 font-weight-bold d-inline">
						{{ translate('product_promotions') }}
					</div>
				</b-col>
				<b-col
					class="mr-1 text-right"
					v-if="selectedPromo === 'jns_points_widget_promotion'">
					<span class="fa fa-redo-alt text-success" />
					{{ translate('total_cycles_achieved') }}:
					<span class="text-success"> {{ promotions[selectedPromo].cycles }} </span>
				</b-col>
				<b-col cols="12">
					<hr>
				</b-col>
				<b-col class="col-12 p-0">
					<template
						v-for="(promo, index) in promotions">
						<product-loyalty-widget
							v-if="index === 'product_loyalty_program' && selectedPromo === index"
							:key="index"
							:data="promo" />
						<free-products-promo-widget-type-2
							v-else-if="promo.type === 'free_products_promotion_type_2' && selectedPromo === index"
							:key="index"
							:promo-code-name="index"
							:data="promo" />
						<free-products-promo-widget-type-3
							v-else-if="promo.type === 'free_products_promotion_type_3' && selectedPromo === index"
							:key="index"
							:promo-code-name="index"
							:data="promo" />
						<free-products-promo-widget
							v-else-if="promo.type === 'free_products_promotion' && selectedPromo === index"
							:key="index"
							:promo-code-name="index"
							:data="promo" />
						<membership
							v-else-if="promo.type === 'v_club_membership' && selectedPromo === index"
							:key="index"
							:promo-code-name="index"
							:data="promo" />
						<trip-points
							v-else-if="promo.type === 'current_trip_points' && selectedPromo === index"
							:key="index"
							:promo-code-name="index"
							:data="promo" />
					</template>
				</b-col>
				<b-col
					v-if="promoKeys.length > 1"
					cols="12">
					<hr>
				</b-col>
				<b-col
					v-if="showFreeProductsButton"
					class="align-content-center text-center">
					<b-button
						v-if="true"
						variant="primary"
						class="mt-2 px-4"
						@click="showProductsAlert = true">
						{{ translate('choose_free_box') }}
						<em
							v-if="loadingSync"
							class="fas fa-sync"
							:class="loadingSync ? 'fa-spin' : ''" />
					</b-button>
				</b-col>
				<b-col
					v-if="promoKeys.length > 1"
					class="col-12 my-2 align-self-end">
					<b-row class="align-items-center justify-content-center px-1">
						<b-col
							class="d-none d-sm-block col-3 text-left px-0 pointer text-muted"
							@click="changeIndex(-1)">
							<template v-if="selectedPromoIndex != 0">
								<i class="icon-lg icon-arrow-left" />
							</template>
						</b-col>
						<b-col class="col-12 col-md">
							<item-slided
								:total="promoKeys.length"
								:current="selectedPromoIndex"
								class="text-muted"
								@selectItem="selectItem" />
						</b-col>
						<b-col
							class="d-none d-sm-block col-3 text-right px-0 pointer text-muted"
							@click="changeIndex(1)">
							<template v-if="selectedPromoIndex != promoKeys.length - 1">
								<i class="icon-lg icon-arrow-right" />
							</template>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
		<promotions-alert
			v-if="showProductsAlert"
			from-widget
			:promos-info="promotions"
			@done="hideAlert" />
	</div>
</template>

<script>
import {
	Promotions as PromoMessages, Tooltip,
} from '@/translations';
import ItemSlided from '@/components/ItemSlided';
import ProductLoyaltyWidget from '@/views/Dashboard/components/ProductsPromotionWidgets/ProductLoyaltyWidget';
import FreeProductsPromoWidget from '@/views/Dashboard/components/ProductsPromotionWidgets/FreeProductsPromoWidget';
import FreeProductsPromoWidgetType2 from '@/views/Dashboard/components/ProductsPromotionWidgets/FreeProductsPromoWidgetType2';
import FreeProductsPromoWidgetType3 from '@/views/Dashboard/components/ProductsPromotionWidgets/FreeProductsPromoWidgetType3';
import Membership from '@/views/Dashboard/components/ProductsPromotionWidgets/Membership';
import PromotionsAlert from '@/components/PromotionsAlert';
import TripPoints from '@/views/Dashboard/components/ProductsPromotionWidgets/TripPoints';
import { HIDE_FREE_PRODUCTS_BUTTON_PROMOTIONS } from '@/settings/Promotions';

export default {
	name: 'FreeProductsPromotions',
	messages: [PromoMessages, Tooltip],
	components: {
		FreeProductsPromoWidget,
		ProductLoyaltyWidget,
		FreeProductsPromoWidgetType2,
		FreeProductsPromoWidgetType3,
		ItemSlided,
		PromotionsAlert,
		Membership,
		TripPoints,
	},
	props: {
		userId: {
			type: Number,
			default: null,
		},
		promotions: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selectedPromoIndex: 0,
			showProductsAlert: false,
			loadingSync: false,
			hiddenButtonPromos: HIDE_FREE_PRODUCTS_BUTTON_PROMOTIONS,
		};
	},
	computed: {
		promoKeys() {
			return Object.keys(this.promotions);
		},
		selectedPromo() {
			return this.promoKeys[this.selectedPromoIndex];
		},
		showFreeProductsButton() {
			const showButtonPromo = !this.hiddenButtonPromos.includes(this.selectedPromo);
			const filteredPromos = Object.keys(this.promotions).filter((key) => this.promotions[key].has_free_products);
			return filteredPromos.length > 0 && showButtonPromo;
		},
	},
	methods: {
		changeIndex(value) {
			let upcomingIndex = this.selectedPromoIndex;
			upcomingIndex += value;
			if (upcomingIndex > this.promoKeys.length - 1) this.selectedPromoIndex = 0;
			else if (upcomingIndex < 0) this.selectedPromoIndex = this.promoKeys.length - 1;
			else this.selectedPromoIndex += value;
		},
		selectItem(item) {
			const index = Number(item);
			if (!Number.isNaN(index)) {
				this.selectedPromoIndex = index;
			}
		},
		hideAlert() {
			this.showProductsAlert = false;
		},
	},
};
</script>
