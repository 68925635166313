<template>
	<div>
		<div
			v-if="!autoshipIsEnabled"
			class="w-100 h-100 p-3 rounded-bottom card border border-muted text-uppercase font-weight-bold text-danger"
			:class="['xs', 'sm'].includes(windowWidth) ? 'col-12 text-center ' : 'd-flex align-items-center text-center'">
			<b-row class="w-100">
				<b-col>
					<div>
						<span
							id="popover-disabled-info"
							tabindex="0">
							<span class="fa fa-info-circle text-danger pointer" />
						</span>
						{{ translate('autoship_disabled') }}
						<b-popover
							target="popover-disabled-info"
							triggers="hover"
							:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'left'">
							<ul class="m-0 pl-3 py-1">
								<li
									v-for="reason in alertReasons"
									:key="reason"
									class="mb-0">
									{{ reason }}
								</li>
							</ul>
						</b-popover>
					</div>
				</b-col>
			</b-row>
		</div>
		<autoship
			v-else
			show
			:dark-theme="false"
			:next-autoship-date="autoshipInformationData.attributes.next_autoship_date"
			:show-config-button="false"
			:autoship-active="autoshipIsEnabled" />
	</div>
</template>

<script>

import Autoship from '@/views/Dashboard/components/Autoship';
import WindowSizes from '@/mixins/WindowSizes';
import {
	AutoshipInformation as Messages,
} from '@/translations';
import { MDY_FORMAT } from '@/settings/Dates';

export default {
	name: 'AutoshipStatusIndicator',
	messages: [Messages],
	components: {
		Autoship,
	},
	mixins: [WindowSizes],
	props: {
		autoshipInformationData: {
			type: Object,
			default: () => ({}),
		},
		autoshipIsEnabled: {
			type: Boolean,
			default: false,
		},
		alertReasons: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			dateFormat: MDY_FORMAT,
		};
	},
};
</script>
