<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="container">
		<b-row class="mt-4">
			<b-col cols="12">
				<h2>
					{{ translate('accept_mba_terms_step') }}
				</h2>
			</b-col>
		</b-row>
		<b-row>
			<b-col
				class="px-4"
				cols="12">
				<div class="form-check">
					<input
						id="policyNProcedures"
						v-model="disclaimer"
						name="policyNProcedures"
						class="form-check-input"
						type="checkbox"
						:value="disclaimer">
					<label
						v-if="[customer, affiliate, agency].includes($user.details().type)"
						for="policyNProcedures"
						v-html="translate('terms_and_conditions_agreement', {terms_of_services: `<a target='_blank' href='${termsAndConditionsLink}'>${ translate('terms_of_services') }</a>`})" />
					<label
						v-else-if="$user.details().type === distributor"
						for="policyNProcedures"
						v-html="translate('policies_and_procedures_agreement', {policies_and_procedures: `<a target='_blank' href='${policiesAndProceduresLink}'>${ translate('policies_and_procedures') }</a>`})" />
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Onboarding from '@/util/Onboarding';
import { Onboarding as OnboardingMessage, Purchase } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import {
	affiliate, agency, customer, distributor,
} from '@/settings/Roles';
import { TERMS_AND_CONDITIONS_REDIRECTION } from '@/settings/General';

export default {
	name: 'WelcomeMessageStep',
	messages: [OnboardingMessage, Purchase],
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		stepInfo: {
			type: Object,
			default: () => ({}),
		},
		flowName: {
			type: String,
			default: 'migrated_user_onboarding_distributors_affiliates',
		},
	},
	data() {
		return {
			onboarding: new Onboarding(),
			stepCode: 'welcome_message',
			affiliate,
			agency,
			customer,
			distributor,
			disclaimer: false,
			form: {
				terms: false,
			},
		};
	},
	computed: {
		termsAndConditionsLink() {
			try {
				return this.stepInfo.terms.terms;
			} catch (error) {
				return TERMS_AND_CONDITIONS_REDIRECTION;
			}
		},
		policiesAndProceduresLink() {
			try {
				return this.stepInfo.terms.mba;
			} catch (error) {
				return TERMS_AND_CONDITIONS_REDIRECTION;
			}
		},
	},
	watch: {
		disclaimer(value) {
			this.form.terms = value;
			this.$emit('update-form', this.form);
		},
	},
};
</script>
