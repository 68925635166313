<template>
	<div
		:class="stackedLayout ? 'mx-3' : ''"
		class="row no-gutters mb-2"
		style="height: 2.5em;">
		<div class="col-6 d-flex p-0 h-100 align-items-center">
			<div class="h-100 mr-3">
				<img
					:src="thumbnail"
					class="float-right h-100">
			</div>
			<div
				:class="stackedLayout ? 'mb-2' : ''">
				<span
					:class="stackedLayout ? 'text-medium' : ''"
					class="font-weight-bolder">
					{{ translate(configuration) }}
					<template v-if="backorderInfo && backorderInfo.will_backorder">
						<i
							v-b-tooltip
							:title="translate('will_be_backordered')"
							class="fas fa-exclamation-triangle text-warning text-small" />
					</template>
				</span>
				<br>
			</div>
		</div>
		<div class="col-6 justify-content-end d-flex w-100 align-items-center">
			<quantity-select
				:init-qty="initQty"
				:select-limit="10"
				:max="99"
				include-zero
				:container-class="`text-right w-100 ${stackedLayout ? '' : 'pr-3'}`"
				sub-container-class="justify-content-end w-100"
				@change="$emit('change', $event)" />
		</div>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { Products, Purchase } from '@/translations';

export default {
	name: 'ProductConfiguration',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	props: {
		configuration: {
			type: String,
			required: true,
		},
		thumbnail: {
			type: String,
			required: true,
		},
		initQty: {
			type: Number,
			required: true,
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		backorderInfo: {
			type: Object,
			default: null,
		},
	},
};
</script>
