export const STEP_ROUTE = {
	welcome_message: 'OnboardingWelcomeMessage',
	update_account_information: 'OnboardingUpdateAccountInfo',
	update_profile_information: 'OnboardingUpdateProfileInfo',
	fill_autoship_info: 'OnboardingAutoshipInfo',
	replicated_site_info: 'OnboardingReplicatedSiteInfo',
	mobile_app_info: 'OnboardingMobileAppInfo',
	accept_mba_terms: 'OnboardingAcceptMBATerms',
};

export const FLOW_ROUTE = [
	{
		flow_code: 'migrated_user_onboarding_distributors_affiliates',
		route_name: 'Onboarding',
	},
	{
		flow_code: 'migrated_user_onboarding_customers',
		route_name: 'Onboarding',
	},
	{
		flow_code: 'autoship_onboarding',
		route_name: 'Onboarding',
	},
	{
		flow_code: 'accept_mba_terms_flow',
		route_name: 'Onboarding',
	},
];

export const COMPLETE_BUTTON_STEP_TEXT = {
	welcome_message: 'continue',
	mobile_app_info: 'finish',
};

export const FIELD_OPTIONS = {
	username: {
		icon: 'icon-user',
		info_message: 'username_msg',
	},
	email: {
		icon: 'icon-envelope',
	},
	mobile_number: {
		icon: 'icon-phone',
	},
	national_identification_number: {
		icon: 'icon-screen-tablet',
	},
	password: {
		icon: 'icon-lock',
		info_message: 'password_msg',
		info_class: 'text-danger',
	},
	language_preference_id: {
		icon: 'icon-lock',
	},
};

export const FIELDS_SORT_ORDER = [
	'username',
	'password',
	'profile_photo',
	'email',
	'mobile_number',
	'national_identification_number',
	'language_preference_id',
];

export const SHOW_EDIT_BUTTON_FIELDS = [
	'email',
	'mobile_number',
];

export const DEFAULT_NON_EDITABLES_FIELDS = [
	'email',
	'mobile_number',
];

export const EMAIL_KEYS = [
	'email',
];

export const PASSWORD_KEYS = [
	'password',
];

export const DROPDOWN_KEYS = [
	'language_preference_id',
];

export default {};
