<template>
	<div class="card h-90">
		<div
			:class="{'show-overlay': checkedRows.includes(mediaId)}"
			class="overflow-hidden media-card relative">
			<img
				:src="thumbnail"
				class="card-img-top">
			<a
				v-if="$can('media_library', 'update') || $can('media_library', 'delete')"
				:id="`popover-${identifier}`"
				tabindex="0"
				role="button"
				variant="media-popover"
				class="card-ellipsis position-absolute text-center">
				<img
					:src="require(`@/assets/images/common/library/ellipsis.svg`)">
			</a>
			<b-popover
				ref="popover"
				:target="`popover-${identifier}`"
				triggers="focus"
				placement="auto"
				class="z-0">
				<div class="popover-content">
					<div
						v-if="$can('media_library', 'update')"
						class="popover-row d-flex text-left mb-2">
						<b-button
							:to="`/media-library/update/${mediaId}`"
							variant="popover-option"
							class="p-0 d-flex align-items-center w-100">
							<img
								:src="require(`@/assets/images/common/library/edit.svg`)"
								class="dark-filter mr-2 w-20">
							<span>{{ translate('edit') }}</span>
						</b-button>
					</div>
					<div
						v-if="$can('media_library', 'delete')"
						class="popover-row d-flex text-left">
						<b-button
							variant="popover-option"
							class="p-0 d-flex align-items-center w-100"
							@click="deleteMedia(mediaId, fileTitle)">
							<img
								:src="require(`@/assets/images/common/library/trashcan.svg`)"
								class="dark-filter mr-2 w-17">
							<span>{{ translate('delete') }}</span>
						</b-button>
					</div>
				</div>
			</b-popover>
			<div>
				<div
					:class="{'checked-overlay': checkedRows.includes(mediaId)}"
					class="overlay d-flex align-items-center justify-content-center text-white fancy">
					<input
						v-if="$can('media_library', 'delete')"
						v-model="checkedRows"
						:value="mediaId"
						type="checkbox"
						name="selection">
					<div class="text-selected text-center">
						<h6 class="text-white px-3 mb-0">
							{{ translate('file_selected', {file: fileTitle }) }}
						</h6>
					</div>
					<a
						v-if="hoverOption === `open`"
						:href="shownUrl"
						target="_blank"
						class="btn btn-media-card px-3">
						<img
							:src="require(`@/assets/images/common/library/open.svg`)"
							class="mr-2 small-icon">
						{{ translate('open') }}
					</a>
					<b-button
						v-else-if="hoverOption === `view`"
						v-b-modal="`modal-${identifier}`"
						type="button"
						class="btn px-3 btn-media-card"
						variant="">
						<img
							:src="require(`@/assets/images/common/library/view.svg`)"
							class="mr-2">
						{{ translate('view') }}
					</b-button>
				</div>
			</div>
		</div>
		<div class="card-body py-3">
			<p class="card-text text-center mb-4">
				{{ fileTitle }}
				<template
					v-if="$can('media_library', 'update')">
					<br>
					<small>{{ fileTitleShort }}</small>
					<br>
					<small class="text-muted">{{ companies }}</small>
				</template>
			</p>
			<div
				v-if="isAdmin"
				:class="['sm', 'md'].includes(windowWidth) ? 'd-none' : ''"
				class="small text-center mb-1 schedule-container">
				<div v-if="endDate">
					<i
						class="fa fa-clock align-icon" /> {{ translate('from') }} <span class="font-weight-normal font-italic">{{ $moment(startDate.date).format('LLL') }}</span>
					{{ translate('to') }} <span class="font-weight-normal font-italic">{{ $moment(endDate.date).format('LLL') }}</span>
				</div>
				<div v-if="!endDate && $moment.tz(startDate.date, startDate.timezone) > $moment().tz(startDate.timezone)">
					<i
						class="fa fa-clock align-icon" /> {{ translate('starts_at') }}: <span class="font-weight-normal font-italic">{{ $moment(startDate.date).format('LLL') }}</span>
				</div>
			</div>
		</div>
		<b-modal
			v-if="hoverOption === `view`"
			:id="`modal-${identifier}`"
			centered
			modal-class="media-modal"
			hide-footer
			size="xl">
			<div class="text-center text-white">
				<img
					:src="mediaPath"
					class="mb-2 h-auto mw-100">
				<h5 class="mt-2 font-weight-bold">
					{{ fileTitle }}
				</h5>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { IMAGES_EXT } from '@/settings/Media';
import { MediaLibrary as mediaTranslations, Time } from '@/translations';
import MediaLibrary from '@/util/MediaLibrary';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'MediaCard',
	mixins: [WindowSizes],
	messages: [mediaTranslations, Time],
	props: {
		fileTitle: {
			type: String,
			default: '',
		},
		fileTitleShort: {
			type: String,
			default: '',
		},
		mediaPath: {
			type: String,
			default: '',
		},
		extraUrls: {
			type: Object,
			default: () => {},
		},
		thumbnail: {
			type: String,
			default: '',
		},
		companies: {
			type: String,
			default: null,
		},
		mediaId: {
			type: String,
			default: '',
		},
		identifier: {
			type: String,
			default: '',
		},
		value: {
			type: Array,
			default: () => '',
		},
		startDate: {
			type: Object,
			default: () => '',
		},
		endDate: {
			type: Object,
			default: () => '',
		},
		isAdmin: {
			type: Boolean,
			default: false,
		},
		ipCountry: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			mediaLibrary: new MediaLibrary(),
			deleteMediaLibrary: new MediaLibrary(),
			fileName: this.mediaPath.split('/').pop(),
			hoverOption: '',
		};
	},
	computed: {
		checkedRows: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		shownUrl() {
			try {
				if (Object.keys(this.extraUrls).includes(this.ipCountry)) {
					return this.extraUrls[this.ipCountry];
				}
			} catch (error) {
				return this.mediaPath;
			}

			return this.mediaPath;
		},
	},
	mounted() {
		const extension = this.fileName.split('.').pop();
		this.hoverOption = 'open';
		if (IMAGES_EXT.includes(extension)) this.hoverOption = 'view';
	},
	methods: {
		deleteMedia(id, text) {
			this.$emit('event', id, text);
		},
	},
};
</script>
