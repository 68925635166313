<template>
	<div>
		<template v-for="item in products">
			<configurable-product
				v-if="item.has_configurations"
				:key="item.sku"
				:code-name="item.code_name"
				:sku="item.sku"
				:show-bvs="false"
				:init-bvs="Number(item.bvs)"
				:init-price="getDisplayPrice(item)"
				:init-exchange="item.exchange"
				:init-exchange-price="item.exchangePrice"
				:thumbnail="item.thumbnail"
				:config-name="'flavor'"
				:stacked-layout="['xs', 'sm'].includes(windowWidth)"
				class="border-bottom pb-1 mb-2"
				:is-expanded="isExpanded(item)"
				@toggleExpanded="toggleExpanded(item)">
				<product-configuration
					v-for="(child, sku) in item.children"
					:backorder-info="child.backorder_info"
					:key="child.code_name"
					:configuration="child.code_name"
					:init-qty="productQuantities[sku] || 0"
					:thumbnail="item.thumbnail"
					:stacked-layout="['xs', 'sm'].includes(windowWidth)"
					@change="$set(productQuantities, sku, $event)" />
			</configurable-product>
			<div
				v-else
				:key="item.sku"
				class="border-bottom pb-1 mb-2">
				<simple-product
					:backorder-info="item.backorder_info"
					:code-name="item.code_name"
					:sku="item.sku"
					:thumbnail="item.thumbnail"
					:show-bvs="false"
					:bvs="Number(item.bvs)"
					:init-qty="productQuantities[item.sku] || 0"
					:price="item.price"
					:exchange="item.exchange"
					:exchange-price="item.exchange_price"
					:stacked-layout="['xs', 'sm'].includes(windowWidth)"
					@change="$set(productQuantities, item.sku, $event)" />
			</div>
		</template>
		<b-row class="mb-4">
			<b-col class="text-center">
				<b-button
					:disabled="loading"
					variant="primary"
					@click="quantityChange()">
					<template v-if="!loading">
						{{ translate('save_update') }}
					</template>
					<template v-else>
						<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
					</template>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import ConfigurableProduct from '@/components/Product/ConfigurableProduct';
import ProductConfiguration from '@/components/Product/ProductConfiguration';
import SimpleProduct from '@/components/Product/SimpleProduct';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, AutoshipInformation, Purchase } from '@/translations';

export default {
	name: 'AutoshipProduct',
	messages: [Products, AutoshipInformation, Purchase],
	components: {
		ConfigurableProduct,
		ProductConfiguration,
		SimpleProduct,
	},
	mixins: [WindowSizes],
	props: {
		products: {
			type: Array,
			required: true,
		},
		qtys: {
			type: Object,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			productQuantities: {},
			expanded: this.products
				.map((product) => ({ sku: product.sku, children: Object.keys(product.children) }))
				.filter((product) => product.children.some((sku) => this.qtys[sku]))
				.reduce((acc, curr) => {
					acc[curr.sku] = true;
					return acc;
				}, {}),
		};
	},
	watch: {
		qtys: {
			deep: true,
			handle: (newVal) => {
				this.productQuantities = newVal;
			},
		},
	},
	mounted() {
		this.productQuantities = this.qtys;
	},
	methods: {
		toggleExpanded(item) {
			this.$set(this.expanded, item.sku, !this.expanded[item.sku]);
		},
		isExpanded(item) {
			return this.expanded[item.sku];
		},
		quantityChange() {
			return this.$emit('save-update', this.productQuantities);
		},
		getProductConfigKey(parentProduct) {
			return Object.keys(parentProduct.configurations)[0];
		},
		getProductConfigs(parentProduct) {
			return parentProduct.configurations[this.getProductConfigKey(parentProduct)];
		},
		getConfiguredProduct(parentProduct, configValue) {
			const data = {
				sku: this.getConfiguredProductSku(parentProduct, configValue),
				thumbnail: this.getConfiguredProductThumbnail(parentProduct, configValue),
				code_name: this.getConfiguredProductCodeName(parentProduct, configValue),
			};
			return data;
		},
		getConfiguredProductSku(parentProduct, configValue) {
			const selectedConfigs = { [this.getProductConfigKey(parentProduct)]: configValue };
			const sku = [parentProduct.sku];
			if (selectedConfigs.color) {
				const colorInfo = parentProduct.configurations.color;
				const selectedColor = Object.keys(colorInfo).find((color) => colorInfo[color].code_name === selectedConfigs.color);
				sku.push(selectedColor);
			}
			if (selectedConfigs.size) {
				sku.push(selectedConfigs.size);
			}
			if (selectedConfigs.format) {
				sku.push(selectedConfigs.format);
			}
			if (selectedConfigs.event_type) {
				sku.push(selectedConfigs.event_type);
			}
			if (selectedConfigs.duration) {
				sku.push(selectedConfigs.duration);
			}
			if (selectedConfigs.presentations) {
				sku.push(selectedConfigs.presentations);
			}
			if (selectedConfigs.flavor) {
				sku.push(selectedConfigs.flavor);
			}
			return sku.join('-');
		},
		getConfiguredProductThumbnail(parentProduct, configValue) {
			const configuredProductSku = this.getConfiguredProductSku(parentProduct, configValue);
			const { children } = parentProduct;

			const [, configuredProduct] = Object.entries(children).filter(([childSku]) => childSku === configuredProductSku)[0];

			return configuredProduct.thumbnail;
		},
		getConfiguredProductCodeName(parentProduct, configValue) {
			return `${configValue}_${this.getProductConfigKey(parentProduct)}`;
		},
		getDisplayPrice(product) {
			const { children, price, price_amount: priceAmount } = product;
			// Display a range for parent products that have children with different values.
			if (Object.keys(children).length > 0 && priceAmount === 0) {
				const prices = Object.values(children)
					.map((variant) => variant.price_amount)
					.sort((a, b) => a - b);
				return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')} USD` : price;
			}
			return price;
		},
	},
};
</script>
