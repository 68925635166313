const Header = () => import('@/views/Layouts/Main/Header');

export const Reports = {
	path: '/reports',
	name: 'MainReports',
	redirect: '/reports/bv',
	component: Header,
	children: [
		{
			path: '/reports/activation-history',
			name: 'ActivationHistory',
			component: () => import('@/views/Reports/ActivationHistory'),
			meta: {
				auth: true,
				section: 'activation_history',
				permissions: 'view',
			},
		},
		{
			path: '/reports/leaderboard',
			name: 'Leaderboard',
			component: () => import('@/views/Reports/Leaderboard'),
			meta: {
				auth: true,
			},
		},
		{
			path: '/reports/bv',
			name: 'BV',
			component: () => import('@/views/Reports/Bv'),
			meta: {
				auth: true,
				section: 'bv',
				permissions: 'view',
			},
		},
		{
			path: '/reports/pending-bv/:distributorId?',
			name: 'PendingBV',
			component: () => import('@/views/Reports/PendingBv'),
			meta: {
				auth: true,
				section: 'bv',
				permissions: 'view',
			},
		},
		{
			path: '/reports/commissions',
			name: 'Commissions',
			component: () => import('@/views/Reports/Commissions'),
			meta: {
				auth: true,
				section: 'commissions',
				permissions: 'view',
			},
		},
		{
			path: '/reports/total-earnings',
			name: 'TotalEarnings',
			redirect: '/reports/total-earnings/summary',
			component: () => import('@/views/Reports/TotalEarnings'),
			meta: {
				auth: true,
				section: 'total_earnings',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/total-earnings/summary',
					name: 'TotalEarningsSummary',
					component: () => import('@/views/Reports/TotalEarningsSummary'),
					meta: {
						auth: true,
						section: 'total_earnings',
						permissions: 'view',
					},
				},
				{
					path: '/reports/total-earnings/detail',
					name: 'TotalEarningsDetail',
					component: () => import('@/views/Reports/TotalEarningsDetail'),
					meta: {
						auth: true,
						section: 'total_earnings',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/fraud-orders',
			name: 'FraudOrders',
			component: () => import('@/views/Reports/FraudOrders'),
			meta: {
				auth: true,
				section: 'fraud_orders',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/fraud-orders/all',
					name: 'FraudOrdersAll',
					component: () => import('@/views/Reports/FraudOrdersAll'),
					meta: {
						auth: true,
						section: 'fraud_orders',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/next-purchase-date-history/:distributorId?',
			name: 'NextPurchaseDateHistory',
			component: () => import('@/views/Reports/NextPurchaseHistory.vue'),
			meta: {
				auth: true,
				section: 'next_purchase_history',
				permissions: 'view',
			},
		},
		{
			path: '/reports/autoship-summary/:distributorId?',
			name: 'AutoshipSummary',
			component: () => import('@/views/Reports/AutoshipSummary'),
			meta: {
				auth: true,
				section: 'autoship_summary',
				permissions: 'view',
			},
		},
		{
			path: '/reports/personal-business-volume',
			name: 'Pbv',
			redirect: '/reports/personal-business-volume/grid',
			component: () => import('@/views/Reports/Pbv'),
			meta: {
				auth: true,
				section: 'pbv',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/personal-business-volume/grid',
					name: 'PersonalBusinessVolume',
					component: () => import('@/views/Reports/PersonalBusinessVolume'),
					meta: {
						auth: true,
						section: 'pbv',
						permissions: 'view',
					},
				},
				{
					path: '/reports/personal-business-volume/top',
					name: 'PersonalBusinessVolumeTop',
					component: () => import('@/views/Reports/PersonalBusinessVolumeTop'),
					meta: {
						auth: true,
						section: 'pbv',
						permissions: 'view',
					},
				},
				{
					path: '/reports/personal-business-volume/new-enrollment',
					name: 'NewEnrollment',
					component: () => import('@/views/Reports/NewEnrollment'),
					meta: {
						auth: true,
						section: 'pbv',
						permissions: 'view',
					},
				},
				{
					path: '/reports/personal-business-volume/top-new-enrollment',
					name: 'NewEnrollmentTop',
					component: () => import('@/views/Reports/NewEnrollmentTop'),
					meta: {
						auth: true,
						section: 'pbv',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/trip-points',
			name: 'TripPoints',
			component: () => import('@/views/Reports/TripPoints'),
			meta: {
				auth: true,
				section: 'trip_points_report',
				permissions: 'view',
			},
		},
		{
			path: '/reports/trip-points-history/:distributorId?',
			name: 'TripPointsHistory',
			component: () => import('@/views/Reports/TripPointsHistory.vue'),
			meta: {
				auth: true,
				section: 'user_trip_points_history',
				permissions: 'view',
			},
		},
		{
			path: '/reports/sales',
			name: 'Sales',
			redirect: '/reports/sales/country',
			component: () => import('@/views/Reports/Sales'),
			meta: {
				auth: true,
				section: 'sales',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/sales/all',
					name: 'SalesAll',
					component: () => import('@/views/Reports/SalesAll'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/products',
					name: 'SalesProducts',
					component: () => import('@/views/Reports/SalesProducts'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/country',
					name: 'SalesCountry',
					component: () => import('@/views/Reports/SalesCountries'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/continents',
					name: 'SalesContinent',
					component: () => import('@/views/Reports/SalesContinents'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/state',
					name: 'SalesState',
					component: () => import('@/views/Reports/SalesStates'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/periods',
					name: 'SalesPeriods',
					component: () => import('@/views/Reports/SalesPeriod'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/payment-method',
					name: 'SalesPaymentMethod',
					component: () => import('@/views/Reports/SalesPaymentMethod'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales/comparison',
					name: 'SalesComparison',
					component: () => import('@/views/Reports/SalesComparison'),
					meta: {
						auth: true,
						section: 'sales',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/sales-details',
			name: 'SalesDetails',
			redirect: '/reports/sales-details/orders-information',
			component: () => import('@/views/Reports/SalesDetails'),
			meta: {
				auth: true,
				section: 'sales_details',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/sales-details/orders-information',
					name: 'OrdersInformation',
					component: () => import('@/views/Reports/OrdersInformation'),
					meta: {
						auth: true,
						section: 'sales_details',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales-details/orders-product-information',
					name: 'OrdersProductsInformation',
					component: () => import('@/views/Reports/OrdersProductsInformation'),
					meta: {
						auth: true,
						section: 'sales_details',
						permissions: 'view',
					},
				},
				{
					path: '/reports/sales-details/settlement-expenses',
					name: 'SettlementsExpenses',
					component: () => import('@/views/Reports/SettlementsExpenses'),
					meta: {
						auth: true,
						section: 'settlements',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/taxes',
			name: 'Taxes',
			component: () => import('@/views/Reports/Taxes'),
			meta: {
				auth: true,
				section: 'taxes',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/taxes/all',
					name: 'TaxesAll',
					component: () => import('@/views/Reports/TaxesAll'),
					meta: {
						auth: true,
						section: 'taxes',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/refunds',
			name: 'Refunds',
			component: () => import('@/views/Reports/Refunds'),
			meta: {
				auth: true,
				section: 'refunds',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/refunds/all',
					name: 'RefundsAll',
					component: () => import('@/views/Reports/RefundsAll'),
					meta: {
						auth: true,
						section: 'refunds',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/commissions/:commission?/week/:week_id',
			name: 'WeeklyCommissions',
			component: () => import('@/views/Reports/WeeklyCommissions'),
		},
		{
			path: '/reports/payout/week/:week_id?',
			name: 'WeeklyPayouts',
			component: () => import('@/views/Reports/WeeklyPayouts'),
			meta: {
				auth: true,
				section: 'commissions',
				permissions: 'payout',
			},
		},
		{
			path: '/reports/user-notes',
			name: 'UserNotes',
			component: () => import('@/views/Reports/UserNotes'),
			meta: {
				auth: true,
				section: 'user_notes',
				permissions: 'view',
			},
		},
		{
			path: '/reports/unilevel-ranks/:distributorId?',
			name: 'UnilevelRanks',
			component: () => import('@/views/Reports/UnilevelRanks'),
			meta: {
				auth: true,
				section: 'unilevel_ranks',
				permissions: 'view',
			},
		},
		{
			path: '/reports/rising-stars',
			name: 'RisingStars',
			component: () => import('@/views/Reports/RisingStars'),
			meta: {
				auth: true,
				section: 'rising_stars',
				permissions: 'view',
			},
		},
		{
			path: '/reports/rank-histories',
			name: 'RankHistories',
			component: () => import('@/views/Reports/RankHistories'),
			meta: {
				auth: true,
				section: 'rank_histories',
				permissions: 'view',
			},
		},
		// {
		// 	path: '/reports/replicated-site-analytics/:distributorId?',
		// 	name: 'ReplicatedSiteAnalytics',
		// 	component: () => import('@/views/Reports/ReplicatedSiteAnalytics'),
		// 	meta: {
		// 		auth: true,
		// 		section: 'replicated_site_analytics',
		// 		permissions: 'view',
		// 	},
		// },
		{
			path: '/reports/lifetime-value',
			name: 'LifetimeValue',
			redirect: '/reports/lifetime-value/customers',
			component: () => import('@/views/Reports/LifetimeValue'),
			meta: {
				auth: true,
				section: 'customer_lifetime',
				permissions: 'view',
			},
			children: [
				{
					path: '/reports/lifetime-value/customers',
					name: 'LifetimeValueCustomers',
					component: () => import('@/views/Reports/LifetimeValue'),
					meta: {
						auth: true,
						section: 'customer_lifetime',
						permissions: 'view',
					},
				},
				{
					path: '/reports/lifetime-value/guests',
					name: 'LifetimeValueGuests',
					component: () => import('@/views/Reports/LifetimeValue'),
					meta: {
						auth: true,
						section: 'customer_lifetime',
						permissions: 'view',
					},
				},
			],
		},
		{
			path: '/reports/sales-report',
			name: 'AgencySalesDetails',
			component: () => import('@/views/Reports/AgencySalesDetails'),
			meta: {
				auth: true,
				section: 'agency_sales_report',
				permissions: 'view',
				role: ['agency'],
			},
		},
		{
			path: '/reports/sales-agency/sales',
			name: 'AgencySalesDetailsCorporate',
			component: () => import('@/views/Reports/AgencySalesDetails'),
			meta: {
				auth: true,
				section: 'agency_sales_report',
				permissions: 'view',
				role: ['admin', 'corporate'],
			},
		},
	],
};

export const CommissionsReport = {
	path: '/commissions',
	name: 'CommissionsReport',
	component: Header,
	children: [
		{
			path: '/commissions',
			name: 'CommissionsCustomers',
			component: () => import('@/views/Reports/Commissions'),
			meta: {
				auth: true,
				role: ['affiliate'],
			},
		},
	],
};

export default {};
