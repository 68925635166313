<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="app-container d-flex">
		<template v-if="!loading">
			<aside
				v-if="!isSmallScreen"
				class="steps-list">
				<step-list
					:steps="steps"
					:active-step="currentStep"
					:loading="loading || stepInfoLoading" />
			</aside>
			<main
				class="card ml-2 h-100"
				:class="!isSmallScreen ? 'step-body' : ''"
				:width="isSmallScreen ? '100%' : '80%'">
				<b-card-header
					v-if="isSmallScreen"
					class="text-center">
					<div class="dots">
						<span
							v-for="step in meta.total_steps"
							:key="step"
							:class="['dot', getStepClass(step)]"
							:style="getDotSize(step)" />
					</div>
				</b-card-header>
				<router-view
					:step-info="currentStepInfo"
					:import-errors="errors"
					@update-form="updateForm" />
				<b-row class="row no-gutters justify-content-center p-2">
					<b-col
						cols="12"
						class="m-2">
						<hr>
						<!--<b-button-->
						<!--	v-if="this.meta.step_number > 1"-->
						<!--	variant="secondary"-->
						<!--	class="px-4 py-2 mt-2 float-left">-->
						<!--	{{ translate('go_back') }}-->
						<!--</b-button>-->
						<b-button
							variant="primary"
							:disabled="loading || loadingSave || loadingProcess || incompleteAutoship || incompleteTerms"
							class="px-4 py-2 mt-2 float-right ml-2"
							@click="completeStep(false)">
							<i
								v-if="loading || loadingSave || loadingProcess"
								class="fas fa-spinner fa-spin mr-2" />
							{{ translate(saveBtnText) }}
						</b-button>
						<b-button
							v-if="allowSkipStep"
							:disabled="loading || loadingSave || loadingProcess"
							class="px-4 py-2 mt-2 float-right btn btn-ghost-primary mr-2"
							@click="completeStep(true)">
							<i
								v-if="loading || loadingSave || loadingProcess"
								class="fas fa-spinner fa-spin mr-2" />
							{{ translate('skip') }}
						</b-button>
					</b-col>
				</b-row>
			</main>
		</template>
		<is-loading
			v-else
			:loading="loading" />
	</div>
</template>

<script>

import ProfileInfo from '@/util/Profile';
import AutoshipInformation from '@/util/AutoshipInformation';
import Onboarding from '@/util/Onboarding';
import { Onboarding as OnboardingMsg, Profile } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import {
	SHOW_EDIT_BUTTON_FIELDS, FIELDS_SORT_ORDER, STEP_ROUTE,
	COMPLETE_BUTTON_STEP_TEXT,
} from '@/settings/Onboarding';
import StepList from '@/views/Onboarding/components/StepList';

export default {
	name: 'Onboarding',
	components: {
		StepList,
		// ForceUpdateField
	},
	messages: [Profile, OnboardingMsg],
	mixins: [WindowSizes],
	data() {
		return {
			form: {},
			profile: new ProfileInfo(),
			saveAutoship: new AutoshipInformation(),
			alert: new this.$Alert(),
			onboarding: new Onboarding(),
			onboardingStep: new Onboarding(),
			onboardingCompleteStep: new Onboarding(),
			showButtonFields: SHOW_EDIT_BUTTON_FIELDS,
			fieldsSortOrder: FIELDS_SORT_ORDER,
			stepRoute: STEP_ROUTE,
			saveStepText: COMPLETE_BUTTON_STEP_TEXT,
			loadingProcess: false,
		};
	},
	computed: {
		steps() {
			try {
				return this.onboarding.data.response.data.data;
			} catch (e) {
				return [];
			}
		},
		loading() {
			// return true;
			return !!this.onboarding.loading;
		},
		loadingSave() {
			return !!this.onboardingCompleteStep.loading || !!this.profile.loading || !!this.saveAutoship.loading;
		},
		meta() {
			try {
				return this.onboarding.data.response.data.meta;
			} catch (e) {
				return [];
			}
		},
		currentStepNumber() {
			try {
				return this.meta.step_number;
			} catch (e) {
				return 0;
			}
		},
		currentStep() {
			try {
				return this.meta.current_step;
			} catch (e) {
				return '';
			}
		},
		currentStepInfo() {
			try {
				return this.onboardingStep.data.response.data;
			} catch (e) {
				return {};
			}
		},
		stepInfoLoading() {
			try {
				return !!this.onboardingStep.loading;
			} catch (e) {
				return false;
			}
		},
		allowSkipStep() {
			try {
				return !!this.currentStepInfo.allow_skip;
			} catch (e) {
				return false;
			}
		},
		saveBtnText() {
			let key = 'confirm';
			if (this.meta.step_number === this.meta.total_steps) {
				key = 'finish';
			} else if (this.saveStepText[this.currentStep]) {
				key = this.saveStepText[this.currentStep];
			}
			return this.translate(key);
		},
		isSmallScreen() {
			return ['xs', 'sm'].includes(this.windowWidth);
		},
		errors: {
			get() {
				try {
					return this.onboardingCompleteStep.data.errors.errors ?? {};
				} catch (error) {
					return [];
				}
			},
			set(newErrors) {
				this.onboardingCompleteStep.data.errors.errors = newErrors;
			},
		},
		incompleteTerms() {
			if (this.currentStep === 'accept_mba_terms') {
				return typeof this.form.terms === 'undefined' || this.form.terms === null || !this.form.terms;
			}
			return false;
		},
		incompleteAutoship() {
			if (this.currentStep === 'fill_autoship_info') {
				const noCard = typeof this.form.card_id === 'undefined' || this.form.card_id === '' || this.form.card_id === null;
				const noAddress = typeof this.form.address_id === 'undefined' || this.form.address_id === '' || this.form.card_id === null;
				const noProducts = typeof this.form.products === 'undefined' || this.form.products === '' || this.form.card_id === null;
				const emptyProducts = typeof this.form.products === 'object' && Object.keys(this.form.products).length === 0;
				return noProducts || noAddress || noCard || emptyProducts;
			}

			return false;
		},
	},
	watch: {
		currentStep(value) {
			if (value !== '' && value !== null && typeof value !== 'undefined') {
				const routeName = this.stepRoute[value];
				const currentRoute = this.$route.name;

				if (currentRoute !== routeName) {
					this.$router.push({ name: routeName });
				}
				this.onboardingStep.getStep(this.flowCode, value);
			}
		},
	},
	mounted() {
		this.flowCode = this.$user.details().force_redirect_to;
		this.onboarding.getFlow(this.flowCode).catch(() => {
			this.$router.push({ name: 'Home' }, () => this.$router.go(0));
		});
	},
	methods: {
		updateValue(fieldCode, value) {
			if (!this.showButtonFields.includes(fieldCode)) {
				this.form[fieldCode] = value;
			}
		},
		updateForm(newForm) {
			this.form = newForm;
		},
		completeStep(skip) {
			if (!skip) {
				if (this.currentStepInfo.dynamic_fields_required) {
					this.loadingProcess = true;
					if (this.form.username !== undefined && this.form.username !== '') {
						// show alert to confirm the new username
						this.loadingProcess = false;
						const message = this.translate('confirm_username_text', { username: this.form.username });
						this.alert.confirmation(this.translate('important'), message, {}, true).then(() => {
							this.loadingProcess = true;
							this.onboardingCompleteStep.updateOnboardingFields(this.form, this.currentStep).then(() => {
								this.markAsCompleted(false);
							}).catch(() => {
								this.loadingProcess = false;
							});
						});
					} else {
						this.onboardingCompleteStep.updateOnboardingFields(this.form, this.currentStep).then(() => {
							this.markAsCompleted(false);
						}).catch(() => {
							this.loadingProcess = false;
						});
					}
				} else if (this.currentStep === 'fill_autoship_info') {
					this.loadingProcess = true;
					this.saveAutoship.updateAutoshipInformation(this.form).then(() => {
						this.markAsCompleted(false);
					}).catch(() => {
						this.loadingProcess = false;
					});
				} else {
					this.markAsCompleted(false);
				}
			} else {
				this.markAsCompleted(true);
			}
		},
		markAsCompleted(skip = false) {
			const payload = {
				skip,
			};
			this.loadingProcess = true;
			this.onboardingCompleteStep.completeStep(this.flowCode, this.currentStep, payload).then(() => {
				this.alert.toast('success', this.translate('step_completed'));
				this.form = [];
				this.onboarding.getFlow(this.flowCode).catch(() => {
					this.$router.push({ name: 'Home' }, () => this.$router.go(0));
				});
			}).catch(() => {}).finally(() => {
				this.loadingProcess = false;
			});
		},
		// Mobile steps indicator
		getStepClass(step) {
			if (step < this.currentStepNumber) return 'completed';
			if (step === this.currentStepNumber) return 'current';
			return 'remaining';
		},
		getDotSize(step) {
			return step === this.currentStepNumber
				? { width: '12px', height: '12px' }
				: { width: '8px', height: '8px' };
		},
	},
};
</script>

<style scoped>
.steps-list {
	width: 30%;
}

.step-body {
	width: 70%;
}

.dots {
	display: flex;
	gap: 8px;
	justify-content: center; /* Centers the dots horizontally */
	align-items: center;     /* Aligns dots vertically if needed */
}

.dot {
	border-radius: 50%;
	background-color: gray;
}

.completed {
	background-color: green;
}

.current {
	background-color: orange;
}

.remaining {
	background-color: gray;
}
</style>
