const Header = () => import('@/views/Layouts/Main/Header');
export default {
	path: '/international-shopping',
	name: 'MainInternationalShopping',
	redirect: '/international-shopping',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/international-shopping',
			name: 'InternationalShopping',
			component: () => import('@/views/GiftOrders'),
			meta: {
				auth: true,
				section: 'gift_orders',
				permissions: 'view',
			},
		},
		{
			path: '/international-shopping/new',
			name: 'NewInternationalShopping',
			component: () => import('@/views/GiftOrders/CreateGiftOrder'),
			meta: {
				auth: true,
				section: 'gift_orders',
				permissions: 'create',
			},
		},
	],
};
