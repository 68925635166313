<template>
	<!-- :valid-device-types="validDeviceTypes" -->
	<!-- :valid-user-types="validUserTypes" -->
	<highlight-video-data
		:form-import="form"
		:valid-countries="validCountries"
		:categories="categories"
		:loading="loading"
		:errors-import="errors"
		@requestData="requestCreateHighlightVideo" />
</template>
<script>
// import {
// USER_TYPES as validUserTypes,
// DEVICE_TYPES as validDeviceTypes,
// } from '@/settings/HighlightVideos';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import { HighlightVideos as messages } from '@/translations';
import HighlightVideo from '@/util/HighlightVideo';
import HighlightVideoData from './components/HighlightVideoData';

export default {
	name: 'NewHighlightVideo',
	messages,
	components: { HighlightVideoData },
	data() {
		return {
			highlightVideo: new HighlightVideo(),
			alert: new this.$Alert(),
			loading: false,
			form: {
				title: '',
				video_id: '',
				mobile_url: '',
				category_id: '',
				url: '',
				image: '',
				countries: '',
				start_date: '',
				end_date: '',
				shareable: true,
				extra_video_ids: {},
				extra_mobile_urls: {},
				// user_types: 'distributor',
				// language: 'en',
				// device_type: 'mobile',
			},
			// validDeviceTypes,
			// validUserTypes,
			validCountries,
			categories: [],
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.highlightVideo.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.highlightVideo.data.errors.errors = newErrors;
			},
		},
	},
	mounted() {
		this.getCategories();
	},
	methods: {
		getCategories() {
			this.loading = true;
			this.highlightVideo.getCategories().then((data) => {
				this.categories = data;
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
		requestCreateHighlightVideo(payload) {
			this.loading = true;
			this.highlightVideo.createHighlightVideo(payload).then(() => {
				this.alert.toast('success', this.translate('highlight_video_created'));
				const query = { categoryId: payload.get('category_id'), language: payload.get('language_code') };
				this.$router.push({ name: 'HighlightVideos', query });
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
	},
};
</script>
