import { admin } from '@/settings/Roles';

const Header = () => import('@/views/Layouts/Main/Header');

export default {
	path: '/orders',
	name: 'MainOrders',
	redirect: '/orders/all',
	component: Header,
	meta: {
		auth: true,
	},
	children: [
		{
			path: '/orders/all',
			name: 'OrdersAll',
			component: () => import('@/views/Orders'),
			meta: {
				auth: true,
				section: 'orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/referred/:sponsorId?',
			name: 'OrdersReferred',
			component: () => import('@/views/Orders/ReferralOrders'),
			meta: {
				auth: true,
				section: 'referred_orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/backorders',
			name: 'Backorders',
			component: () => import('@/views/Orders/Backorders'),
			meta: {
				auth: true,
				section: 'backorders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/settlements',
			name: 'OrdersSettlements',
			component: () => import('@/views/Orders/Settlements'),
			meta: {
				auth: true,
				section: 'settlements',
				permissions: 'view',
			},
		},
		{
			path: '/orders/settlements/:settlement_id/details',
			name: 'OrdersSettlementDetails',
			component: () => import('@/views/Orders/components/SettlementDetail'),
			meta: {
				auth: true,
				section: 'settlements',
				permissions: 'view',
			},
		},
		{
			path: '/orders/settlements/upload',
			name: 'UploadOrderFile',
			component: () => import('@/views/Orders/components/UploadFileForm'),
			meta: {
				auth: true,
				section: 'settlements',
				permissions: 'upload',
			},
		},
		{
			path: '/orders/multi-payment-plan',
			name: 'OrdersMultiPayment',
			component: () => import('@/views/Orders/MultiPayments'),
			meta: {
				auth: true,
				section: 'orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/pickup',
			name: 'OrdersPickup',
			component: () => import('@/views/Orders'),
			meta: {
				auth: true,
				section: 'pickup_orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/downloads',
			name: 'Downloads',
			component: () => import('@/views/Orders/Downloads'),
			meta: {
				auth: true,
				section: 'downloads',
				permissions: 'view',
			},
		},
		{
			path: '/orders/videos',
			name: 'Videos',
			component: () => import('@/views/Orders/Videos'),
			meta: {
				auth: true,
				section: 'videos',
				permissions: 'view',
			},
		},
		{
			path: '/orders/:order_id/details',
			name: 'OrdersDetails',
			component: () => import('@/views/Orders/OrdersDetails'),
			meta: {
				auth: true,
				section: 'orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/:order_id/details/refund',
			name: 'NewRefund',
			component: () => import('@/views/Orders/NewRefund'),
			meta: {
				auth: true,
				section: 'orders',
				permissions: 'refund',
			},
		},
		{
			path: '/manual_order',
			name: 'ManualOrder',
			component: () => import('@/views/ManualOrder'),
			meta: {
				auth: true,
				section: 'orders',
				permissions: 'create',
				role: admin,
			},
			props: true,
		},
		{
			path: '/orders/unshipped',
			name: 'UnshippedOrders',
			component: () => import('@/views/Orders/UnshippedOrders'),
			meta: {
				auth: true,
				section: 'unshipped_orders',
				permissions: 'view',
			},
		},
		{
			path: '/orders/gui-information',
			name: 'GUIInformation',
			component: () => import('@/views/Orders/GuiInformation'),
			meta: {
				auth: true,
				section: 'gui_information',
				permissions: 'view',
			},
		},
	],
};
