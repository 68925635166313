<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="text-muted text-uppercase font-weight-bold text-left">
				{{ translate(promoCodeName) }}
				<a
					v-if="link"
					v-b-tooltip.hover
					:href="link"
					target="_blank"
					:title="translate('view_details')"
					class="text-decoration-none text-muted link-primary">
					<i class="fas fa-info-circle" />
				</a>
			</div>
		</div>
		<b-row>
			<b-col class="mx-3 mb-3 text-left">
				<p
					class="mb-0 text-muted"
					v-html="translate('jns_points_description')" />
			</b-col>
		</b-row>
		<div class="d-flex justify-content-center align-items-center mb-2">
			<div
				class="d-flex flex-column align-items-center flex-shrink-0"
				:class="{
					'mx-3': size === 'md',
					'mx-1': size === 'sm',
				}">
				<div
					class="circle-icon rounded-circle border-blue"
					:class="{
						'normal-circle': size === 'md',
						'small-circle': size === 'sm',
					}"
					:style="{
						'font-size': getFontSize(data.last_month),
					}">
					{{ data.last_month }}
				</div>
				{{ translate('last_month') }}
			</div>
			<div
				class="d-flex flex-column align-items-center flex-shrink-0"
				:class="{
					'mx-3': size === 'md',
					'mx-1': size === 'sm',
				}">
				<div
					class="circle-icon rounded-circle border-blue"
					:class="{
						'normal-circle': size === 'md',
						'small-circle': size === 'sm',
					}"
					:style="{
						'font-size': getFontSize(data.current_month),
					}">
					{{ data.current_month }}
				</div>
				{{ translate('current_month') }}
			</div>
			<div
				class="d-flex flex-column align-items-center flex-shrink-0"
				:class="{
					'mx-3': size === 'md',
					'mx-1': size === 'sm',
				}">
				<div
					class="circle-icon rounded-circle border-blue alert-info"
					:class="{
						'normal-circle': size === 'md',
						'small-circle': size === 'sm',
					}"
					:style="{
						'font-size': getFontSize(data.total_points),
					}">
					{{ data.total_points }}
				</div>
				{{ translate('total_points') }}
			</div>
		</div>
		<b-row>
			<b-col class="text-center mt-1">
				<button
					:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
					class="btn btn-link b-4"
					@click="goToReport">
					{{ translate('see_points_activity') }} >
				</button>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import { Promotions as messages, Tooltip } from '@/translations';

export default {
	name: 'TripPoints',
	messages: [messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			loadingSync: false,
		};
	},
	computed: {
		link() {
			try {
				return this.data.link;
			} catch (e) {
				return false;
			}
		},
		size() {
			return ['xs', 'sm'].includes(this.windowWidth) ? 'sm' : 'md';
		},
	},
	methods: {
		goToReport() {
			const params = {};
			const { distributorId } = this.$route.params;
			if (distributorId) {
				params.distributorId = distributorId;
			}

			this.$router.push({
				name: 'TripPointsHistory',
				params,
			});
		},
		getFontSize(number) {
			if (number.toString().length > 3 || this.size === 'sm') {
				return '1.5rem';
			}
			return '2rem';
		},
	},
};
</script>

<style scoped>
.text-blue {
	color: #336573 !important;
}

.border-blue {
	border: 3px solid #63C2DE;
}

.border-orange {
	border: 3px solid #e4875c;
}

.circle-icon {
	border-radius: 50%;       /* Makes it a circle */
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.normal-circle {
	width: 5.2rem;
	height: 5.2rem;
}

.small-circle {
	width: 4rem;
	height: 4rem;
}
</style>
