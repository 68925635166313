var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-100 px-3"},[_c('div',{staticClass:"text-muted text-uppercase font-weight-bold text-left"},[_vm._v(" "+_vm._s(_vm.translate(_vm.promoCodeName))+" "),(_vm.link)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-decoration-none text-muted link-primary",attrs:{"href":_vm.link,"target":"_blank","title":_vm.translate('view_details')}},[_c('i',{staticClass:"fas fa-info-circle"})]):_vm._e()])]),_c('b-row',[_c('b-col',{staticClass:"mx-3 mb-3 text-left"},[_c('p',{staticClass:"mb-0 text-muted",domProps:{"innerHTML":_vm._s(_vm.translate('jns_points_description'))}})])],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-2"},[_c('div',{staticClass:"d-flex flex-column align-items-center flex-shrink-0",class:{
				'mx-3': _vm.size === 'md',
				'mx-1': _vm.size === 'sm',
			}},[_c('div',{staticClass:"circle-icon rounded-circle border-blue",class:{
					'normal-circle': _vm.size === 'md',
					'small-circle': _vm.size === 'sm',
				},style:({
					'font-size': _vm.getFontSize(_vm.data.last_month),
				})},[_vm._v(" "+_vm._s(_vm.data.last_month)+" ")]),_vm._v(" "+_vm._s(_vm.translate('last_month'))+" ")]),_c('div',{staticClass:"d-flex flex-column align-items-center flex-shrink-0",class:{
				'mx-3': _vm.size === 'md',
				'mx-1': _vm.size === 'sm',
			}},[_c('div',{staticClass:"circle-icon rounded-circle border-blue",class:{
					'normal-circle': _vm.size === 'md',
					'small-circle': _vm.size === 'sm',
				},style:({
					'font-size': _vm.getFontSize(_vm.data.current_month),
				})},[_vm._v(" "+_vm._s(_vm.data.current_month)+" ")]),_vm._v(" "+_vm._s(_vm.translate('current_month'))+" ")]),_c('div',{staticClass:"d-flex flex-column align-items-center flex-shrink-0",class:{
				'mx-3': _vm.size === 'md',
				'mx-1': _vm.size === 'sm',
			}},[_c('div',{staticClass:"circle-icon rounded-circle border-blue alert-info",class:{
					'normal-circle': _vm.size === 'md',
					'small-circle': _vm.size === 'sm',
				},style:({
					'font-size': _vm.getFontSize(_vm.data.total_points),
				})},[_vm._v(" "+_vm._s(_vm.data.total_points)+" ")]),_vm._v(" "+_vm._s(_vm.translate('total_points'))+" ")])]),_c('b-row',[_c('b-col',{staticClass:"text-center mt-1"},[_c('button',{staticClass:"btn btn-link b-4",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'w-100' : '',on:{"click":_vm.goToReport}},[_vm._v(" "+_vm._s(_vm.translate('see_points_activity'))+" > ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }